import gql from 'graphql-tag';
import { IImage, ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { fetchStories, parseStories } from '../stories';

export interface IStories extends IBaseSlice {
  items: Array<{
    title: string;
    date: string;
    location: string;
    image: IImage;
    caption: string;
    link?: ILink;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
    }
  }
`;

type NestedProps = {
  lang: string;
  limit?: number;
  previewData: boolean;
  redirects: Array<{ from: string; to: string }>;
};

export const fetchStoriesAndParse = async ({
  lang,
  limit,
  previewData,
  redirects = [],
}: NestedProps) => {
  const items = await fetchStories(lang, '', previewData, limit);
  return parseStories(items, redirects, lang);
};

type Props = {};

export const parse = (slice: Props): IStories => ({
  type: SliceTypes.Stories,
  items: [],
});
