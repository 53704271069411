import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { PrismicImage } from '../utils/PrismicTypes';
import { parseImage, parseRichText, parseString } from '../utils/parsers';

export interface IQuote extends IBaseSlice {
  quote: RichTextBlock[];
  attribution: string;
  position: string;
  image: IImage;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_quote
        slice_attribution
        slice_position
        slice_image
      }
    }
  }
`;

type Props = {
  primary: {
    slice_quote: RichTextBlock[];
    slice_attribution: RichTextBlock[];
    slice_position: RichTextBlock[];
    slice_image: PrismicImage;
  };
};

export const parse = (slice: Props, redirects): IQuote => ({
  type: SliceTypes.Quote,
  quote: parseRichText(slice.primary.slice_quote, redirects),
  attribution: parseString(slice.primary.slice_attribution),
  position: parseString(slice.primary.slice_position),
  image: parseImage(slice.primary.slice_image),
});
