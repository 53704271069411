import gql from 'graphql-tag';
import { RichTextBlock } from 'prismic-reactjs';
import { IImage, ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { fetchProducts, parseProducts } from '../product';
import { parseString, parseRichText } from '../utils/parsers';

export interface ICatalogBranded extends IBaseSlice {
  tagline: string;
  title: string;
  text: RichTextBlock[];
  items: Array<{
    type?: string;
    title?: string;
    brandImage?: IImage;
    link?: ILink;
    warning?: boolean;
    infoLink?: ILink;
    hasRems?: boolean;
    remsLink?: ILink;
    pdfLink: ILink;
    variants?: Array<{
      type?: string;
      ndc?: string;
      gcn?: string;
      form?: string;
      image?: IImage;
      gcnSeq?: string;
      strength?: string;
      size?: string;
    }>;
    detailsLink?: ILink;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
      }
    }
  }
`;

type NestedProps = {
  lang: string;
  limit?: number;
  previewData: boolean;
  redirects: Array<{ from: string; to: string }>;
};

export const fetchProductsAndParse = async ({
  lang,
  limit,
  previewData,
  redirects = [],
}: NestedProps) => {
  const items = await fetchProducts(lang, '', previewData, (limit = 500));
  return parseProducts(items, redirects);
};

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
  };
};

export const parse = (slice: Props, redirects): ICatalogBranded => ({
  type: SliceTypes.CatalogBranded,
  tagline: parseString(slice.primary.slice_tagline),
  text: parseRichText(slice.primary.slice_text, redirects),
  title: parseString(slice.primary.slice_title),
  items: [],
});
