import React from 'react';
import Image from 'next/image';
import { setQueryParameters } from 'utils/setQueryParameters';
import { IImage, ILink } from 'utils/types';
import { Link } from 'components/link/Link';
import { ChevronRight } from 'components/assets';

import s from './IndexGrid.module.scss';

type Props = {
  link?: ILink;
  image?: IImage;
  title?: React.ReactNode;
  text?: React.ReactNode;
  label?: React.ReactNode;
};

export const ListItem = ({ link, image, title, text, label }: Props) => {
  if (!link || !link.href) {
    return null;
  }

  return (
    <li className={s.listItem}>
      <Link {...link} className={s.listItem__link}>
        <div className={s.listItem__layout}>
          <div className={s.listItem__image}>
            {image && image.url && (
              // query parameter removes extraneous transparent area around some product images
              <Image
                src={setQueryParameters(image.url as string, 'trim=color&trim-color=transparent')}
                layout="fill"
                objectFit="contain"
                sizes="640px"
              />
            )}
          </div>
          <div className={s.listItem__content}>
            <h3 className={s.listItem__title}>{title}</h3>
            <div className={s.listItem__text}>{text}</div>
          </div>
        </div>
      </Link>
    </li>
  );
};
