import gql from 'graphql-tag';
import { RichTextBlock } from 'prismic-reactjs';
import { IImage, ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { parseImage, parseLink, parseRichText, parseString } from '../utils/parsers';
import { PrismicImage, PrismicLink } from '../utils/PrismicTypes';

export interface IText extends IBaseSlice {
  type: SliceTypes;
  tagline?: string;
  title?: string;
  heading?: RichTextBlock[];
  text: RichTextBlock[];
  columns?: number;
  color?: string;
  image?: IImage;
  link?: ILink;
  label?: string;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_text
      }
    }
  }
`;

export const customPageFragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
        slice_columns
        slice_color
        slice_image
        slice_link { ...Link }
        slice_buttonlabel
      }
    }
  }
`;

export const customSplitPageFragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline?: RichTextBlock[];
    slice_title?: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_columns?: string;
    slice_color?: string;
    slice_image?: PrismicImage;
    slice_link?: PrismicLink;
    slice_buttonlabel?: RichTextBlock[];
  };
};

const COLUMNS = {
  'Single column': 1,
  'Two column': 2,
  'Three column': 3,
};

export const parse = (slice: Props, redirects): IText => ({
  type: SliceTypes.Text,
  tagline: parseString(slice.primary.slice_tagline),
  title: parseString(slice.primary.slice_title),
  heading: parseRichText(slice.primary.slice_title, redirects),
  text: parseRichText(slice.primary.slice_text, redirects),
  columns: COLUMNS[slice.primary.slice_columns] ?? null,
  color: parseString(slice.primary.slice_color),
  image: parseImage(slice.primary.slice_image),
  link: parseLink(slice.primary.slice_link, redirects),
  label: parseString(slice.primary.slice_buttonlabel),
});
