import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage, ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { PrismicImage, PrismicLink } from '../utils/PrismicTypes';
import { parseEmbed, parseImage, parseLink, parseRichText, parseString } from '../utils/parsers';

export interface IBanner extends IBaseSlice {
  tagline: string;
  title: string;
  text: RichTextBlock[];
  image: IImage;
  link: ILink;
  label: string;
  side: boolean;
  // buttonText: string;
  // buttonNumber: string;
  // buttonSubline: string;
  // buttonCaption: string;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
        slice_image
        slice_link { ...Link }
        slice_label
        slice_side
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_image: PrismicImage;
    slice_link: PrismicLink;
    slice_label: RichTextBlock[];
    slice_side: boolean;
  };
};

export const parse = (slice: Props, redirects): IBanner => ({
  type: SliceTypes.Banner,
  tagline: parseString(slice.primary.slice_tagline),
  title: parseString(slice.primary.slice_title),
  text: parseRichText(slice.primary.slice_text, redirects),
  image: parseImage(slice.primary.slice_image),
  link: parseLink(slice.primary.slice_link, redirects),
  label: parseString(slice.primary.slice_label),
  side: slice.primary.slice_side || false,
});
