import React, { ChangeEvent, FocusEvent } from 'react';

import { Select } from 'components/form/Select';
import { Textbox } from 'components/form/Textbox';
import { Checkbox } from 'components/form/Checkbox';

import { ICheckboxField, IField, ISelectField } from 'providers/wufoo/types';

type Props = {
  field: IField;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
  handleBlur: (e: FocusEvent<any>) => void;
  values: any;
  required?: boolean;
  errors?: any;
  touched?: any;
};

export const FieldContainer = ({
  field,
  handleChange,
  handleBlur,
  values,
  required,
  errors = {},
  touched = {},
}: Props) => {
  switch (field.type) {
    case 'select':
      const [placeholder, ...labels] = (field as ISelectField).choices.map((c) => c.label);
      return (
        <Select
          label={field.title}
          name={field.id}
          id={field.id}
          placeholder={placeholder}
          options={labels}
          onChange={handleChange}
          onBlur={handleBlur}
          required={required}
          error={errors[field.id]}
          touched={touched[field.id]}
        />
      );
    case 'textarea':
      return (
        <Textbox
          type="textarea"
          label={field.title}
          name={field.id}
          id={field.id}
          placeholder={field.instructions}
          onChange={handleChange}
          required={required}
          error={errors[field.id]}
          touched={touched[field.id]}
        />
      );
    case 'email':
      return (
        <Textbox
          type="email"
          label={field.title}
          name={field.id}
          id={field.id}
          placeholder={field.instructions}
          onChange={handleChange}
          value={values[field.id]}
          required={required}
          error={errors[field.id]}
          touched={touched[field.id]}
        />
      );
    case 'number':
      return (
        <Textbox
          type="number"
          label={field.title}
          name={field.id}
          id={field.id}
          placeholder={field.instructions}
          onChange={handleChange}
          value={values[field.id]}
          required={required}
          error={errors[field.id]}
          touched={touched[field.id]}
        />
      );
    case 'checkbox': {
      const f = field as ICheckboxField;

      return (
        <>
          {f.choices.map((c) => (
            <Checkbox
              key={c.id}
              id={c.id}
              name={c.id}
              label={c.label}
              onChange={handleChange}
              checked={values[c.id]}
              required={required}
              error={errors[field.id]}
              touched={touched[field.id]}
            />
          ))}
        </>
      );
    }
    case 'radio': {
      const f = field as ISelectField;

      return (
        <>
          {f.choices.map((c, index) => (
            <Checkbox
              key={index}
              id={f.id}
              name={f.id}
              type="radio"
              label={c.label}
              onChange={handleChange}
              required={required}
              error={errors[field.id]}
              touched={touched[field.id]}
            />
          ))}
        </>
      );
    }
    default:
      return (
        <Textbox
          label={field.title}
          name={field.id}
          id={field.id}
          placeholder={field.instructions}
          onChange={handleChange}
          value={values[field.id]}
          required={required}
          error={errors[field.id]}
          touched={touched[field.id]}
        />
      );
  }
};
