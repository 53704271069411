import React from 'react';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import { Tagline } from 'components/tagline/Tagline';
import { Year } from './Year';
import { Report } from './Report';
import { Document } from './Document';

import s from './Shareholder.module.scss';

type Props = {
  tagline?: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  listItems?: React.ReactNode;
  noContainer?: boolean;
  reports?: React.ReactNode;
  children?: React.ReactNode;
};

interface IShareholder extends React.FC<Props> {
  Year: typeof Year;
  Report: typeof Report;
  Document: typeof Document;
}

const Shareholder: IShareholder = function Shareholder({
  tagline,
  title,
  text,
  noContainer,
  reports,
  children,
}) {
  const [tab, setTab] = React.useState('');

  const content = (
    <div className={s.shareholder__layout}>
      {(title || tagline) && (
        <header className={s.shareholder__header}>
          <div className={s.shareholder__headlines}>
            <Tagline text={tagline} />
            <h1 className={s.shareholder__title}>{title}</h1>
          </div>
          <div className={s.shareholder__actions}>
            <button
              className={classnames(s.shareholder__button, {
                [s.active]: tab === '' || tab === 'documents',
              })}
              onClick={() => setTab('documents')}
            >
              <Trans>Meeting documents</Trans>
            </button>
            <button
              className={classnames(s.shareholder__button, {
                [s.active]: tab === 'reports',
              })}
              onClick={() => setTab('reports')}
            >
              <Trans>Annual reports</Trans>
            </button>
          </div>
          {text && <div className={s.shareholder__text}>{text}</div>}
        </header>
      )}
      {(tab === '' || tab === 'documents') && <div className={s.shareholder__grid}>{children}</div>}
      {tab === 'reports' && <div className={s.shareholder__reports}>{reports}</div>}
    </div>
  );

  const container = (
    <div className={s.shareholder__segment}>
      <div className={s.shareholder__container}>{content}</div>
    </div>
  );

  return <section className={s.shareholder}>{noContainer ? content : container}</section>;
};

Shareholder.Year = Year;
Shareholder.Report = Report;
Shareholder.Document = Document;

export { Shareholder };
