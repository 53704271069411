import React from 'react';

import { Segment } from 'components/segment/Segment';

import { Text } from 'components/text/Text';
import { Content } from 'components/content/Content';
import { ContentItem } from 'components/content/ContentItem';
import { Overlap } from 'components/overlap/Overlap';
import { OverlapItem } from 'components/overlap/OverlapItem';
import { Faq } from 'components/faq/Faq';
import { FaqItem } from 'components/faq/FaqItem';
import { Gallery } from 'components/gallery/Gallery';
import { GalleryItem } from 'components/gallery/GalleryItem';
import { Quote } from 'components/quote/Quote';
import { Documents } from 'components/documents/Documents';
import { DocumentsItem } from 'components/documents/DocumentsItem';
import { Documents as NewDocuments } from '@alvogen/shared/components/documents/Documents';
import { FeaturedArticles } from 'components/featured-articles/FeaturedArticles';
import { FeaturedArticlesItem } from 'components/featured-articles/FeaturedArticlesItem';
import { Buttons } from 'components/buttons/Buttons';
import { Button } from 'components/button/Button';
import { TextButton } from 'components/button/TextButton';
import { MegaButton } from 'components/button/MegaButton';
import { Author } from 'components/author/Author';
import { People } from 'components/people/People';
import { PeopleItem } from 'components/people/PeopleItem';
import { Pipeline } from 'components/pipeline/Pipeline';
import { PipelineItem } from 'components/pipeline/PipelineItem';
// import { Form } from 'components/form';
import { CTA } from 'components/cta/CTA';
import { Table } from 'components/table/Table';
import { TableRow } from 'components/table/TableRow';
import { Divider } from 'components/divider/Divider';
import { Timeline } from 'components/timeline/Timeline';
import { TimelineItem } from 'components/timeline/TimelineItem';
import { ImageBlock } from 'components/image-block/ImageBlock';
import { GalleryBlock } from 'components/gallery-block/GalleryBlock';
import { GalleryBlockItem } from 'components/gallery-block/GalleryBlockItem';
import { ColumnSection } from 'components/column-section/ColumnSection';
import { ColumnSectionItem } from 'components/column-section/ColumnSectionItem';
import { ItemList } from 'components/item-list/ItemList';
import { ItemListItem } from 'components/item-list/ItemListItem';
import { MediaList } from 'components/media-list/MediaList';
import { MediaListItem } from 'components/media-list/MediaListItem';
import { Contact as ContactBlock } from 'components/contact/Contact';
import { Infographic } from 'components/infographic/Infographic';
import { Infographics } from 'components/infographic/Infographics';
import { GalleryGrid } from 'components/gallery-grid/GalleryGrid';
import { GalleryGridItem } from 'components/gallery-grid/GalleryGridItem';
import { Offices } from 'components/offices/Offices';
import { OfficesItem } from 'components/offices/OfficesItem';
import { Newsletter } from 'components/newsletter/Newsletter';
import { Variant } from 'components/variant/Variant';
import { TextBlock } from 'components/text-block/TextBlock';
import { Stats } from '@alvogen/shared/components/stats/Stats';
// import { Data } from '@alvogen/shared/components/data/Data';
import { Events } from 'components/events/Events';
import { IndexGridContainer } from 'containers/index-grid/IndexGridContainer';
import { IndexGrid } from 'components/index-grid/IndexGrid';
import { Tabs } from '@alvogen/shared/components/tabs/Tabs';
import { Banner } from 'components/banner/Banner';

import { FormContainer } from 'containers/forms/Form';
import { Hero } from 'components/hero/Hero';
import { Video } from 'components/video/Video';
import { VideoItem } from 'components/video/VideoItem';
import { VideoList } from 'components/video/VideoList';
import { JobListingContainer } from 'containers/job-listing/JobListingContainer';
import { CatalogContainer } from 'containers/product-catalog/Catalog';
import { CatalogTableContainer } from 'containers/product-catalog/CatalogTable';
import { CatalogSimpleContainer } from 'containers/product-catalog/CatalogSimple';
import { Stories } from 'containers/stories/Stories';
import { Packages } from 'components/packages/Packages';
import { Retailers } from 'components/retailers/Retailers';
import { CatalogBasicContainer } from 'containers/product-catalog/CatalogBasic';
import { SalesContainer } from 'containers/sales/Sales';
import { Quarters } from 'components/quarters/Quarters';
import { Shareholder } from 'components/shareholder/Shareholder';
import { ArticlesRelated } from 'components/articles/ArticlesRelated';
import { Accordion } from 'components/esg/Accordion';
import { Portal } from 'components/esg/Portal';
import { QuoteBlock } from 'components/esg/QuoteBlock';
import { Hero as HeroButtons } from 'components/esg/Hero';
import { SmallPrint } from 'components/esg/SmallPrint';

import { IBaseSlice, SliceTypes } from 'providers/prismic/slices';
import { RichText } from 'components/rich-text/RichText';

import { IStaggeredBlocks } from 'providers/prismic/slices/staggeredBlocks';
import { ILatestArticles } from 'providers/prismic/slices/articles';
import { ICta } from 'providers/prismic/slices/cta';
import { IHero } from 'providers/prismic/slices/hero';
import { IGalleryBlock } from 'providers/prismic/slices/galleryBlock';
import { IImageBlock } from 'providers/prismic/slices/imageBlock';
import { IItemList } from 'providers/prismic/slices/itemList';
import { ITextBlock } from 'providers/prismic/slices/textBlock';
import { IPeople } from 'providers/prismic/slices/people';
import { IPipeline } from 'providers/prismic/slices/pipeline';
import { IText } from 'providers/prismic/slices/text';
import { ITimeline } from 'providers/prismic/slices/timeline';
import { IDocuments } from 'providers/prismic/slices/documents';
import { IColumnSection } from 'providers/prismic/slices/columnSection';
import { IInfographic } from 'providers/prismic/slices/infographic';
import { IInfographics } from 'providers/prismic/slices/infographics';
import { IQuote } from 'providers/prismic/slices/quote';
import { IButtons } from 'providers/prismic/slices/buttons';
import { IOffices } from 'providers/prismic/slices/offices';
import { IForm } from 'providers/prismic/slices/form';
import { IContactBlock } from 'providers/prismic/slices/contactBlock';
import { INewsletterSignup } from 'providers/prismic/slices/newsletterSignup';
import { IMediaList } from 'providers/prismic/slices/mediaList';
import { IVariant } from 'providers/prismic/slices/variant';
import { IStories } from 'providers/prismic/slices/stories';
import { IFaq } from 'providers/prismic/slices/faq';
import { IVideo } from 'providers/prismic/slices/video';
import { IVideoList } from 'providers/prismic/slices/videoList';
import { IJobListing } from 'providers/prismic/slices/jobListing';
import { IGalleryGrid } from 'providers/prismic/slices/galleryGrid';
import { IGallery } from 'providers/prismic/slices/gallery';
import { IStats } from 'providers/prismic/slices/stats';
import { ITable } from 'providers/prismic/slices/table';
// import { IData } from 'providers/prismic/slices/data';
import { IEvents } from 'providers/prismic/slices/events';
import { IIndexGrid } from 'providers/prismic/slices/indexGrid';
import { ICatalog } from 'providers/prismic/slices/catalog';
import { ICatalogAlmaject } from 'providers/prismic/slices/catalogAlmaject';
import { ICatalogBranded } from 'providers/prismic/slices/catalogBranded';
import { ICatalogAlmatica } from 'providers/prismic/slices/catalogAlmatica';
import { ICatalogIceland } from 'providers/prismic/slices/catalogIceland';
import { ICatalogBasic } from 'providers/prismic/slices/catalogBasic';
import { ICatalogSimple } from 'providers/prismic/slices/catalogSimple';
import { IPackages } from 'providers/prismic/slices/packages';
import { IBanner } from 'providers/prismic/slices/banner';
import { IRetailers } from 'providers/prismic/slices/retailers';
import { ISales } from 'providers/prismic/slices/sales';
import { IQuarters } from 'providers/prismic/slices/quarters';
import { IShareholder } from 'providers/prismic/slices/shareholder';
import { IArticleIndex } from 'providers/prismic/slices/articleIndex';
import { IRelated } from 'providers/prismic/slices/related';
import { IAccordion } from 'providers/prismic/slices/accordion';
import { IPortal } from 'providers/prismic/slices/portal';
import { IQuoteBlock } from 'providers/prismic/slices/quoteBlock';
import { IHeroButtons } from 'providers/prismic/slices/heroButtons';
import { ISmallPrint } from 'providers/prismic/slices/smallPrint';

// import { Pagination } from 'components/pagination/Pagination';
// import { useRouter } from 'next/router';
import { ArticleIndex } from './containers/ArticleIndex';

type Props = {
  slices: IBaseSlice[];
  isArticle?: boolean;
  isEvent?: boolean;
  isProduct?: boolean;
  isSplitPage?: boolean;
};

interface ISlice {
  slice: IBaseSlice;
  extras?: any[];
  trailing?: any[];
  allowBackground?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

const mapComponent = ({ slice, trailing = [], allowBackground }: ISlice, index: number) => {
  const { type } = slice;

  switch (type) {
    case SliceTypes.Hero: {
      const { tagline, title, text, image, linkText, link, video, embed } = slice as IHero;

      return (
        <Hero
          tagline={tagline}
          title={title}
          text={RichText.render(text)}
          image={image}
          linkText={linkText}
          link={link}
          video={video}
          embed={embed}
        />
      );
    }

    case SliceTypes.Text: {
      const { tagline, title, heading, text, columns, color, image, link, label } = slice as IText;

      return (
        <Text
          tagline={tagline}
          title={RichText.render(heading)}
          colCount={columns}
          text={RichText.render(text)}
          color={color}
          image={image}
          link={link}
          label={label}
        />
      );
    }

    case SliceTypes.StaggeredBlocks: {
      const { style, direction, items } = slice as IStaggeredBlocks;

      if (style === 'Overlapping') {
        return (
          <Overlap>
            {items?.map((item, index) => (
              <OverlapItem
                key={index}
                image={item.image}
                caption={item.caption}
                tagline={item.tagline}
                title={item.title}
                text={RichText.render(item.text)}
                link={item.link}
                label={item.label}
                secondLink={item.secondLink}
                secondLabel={item.secondLabel}
                direction={direction}
                video={item.video}
                embed={item.embed}
              />
            ))}
          </Overlap>
        );
      } else {
        return (
          <Content>
            {items?.map((item, index) => (
              <ContentItem
                key={index}
                image={item.image}
                caption={item.caption}
                tagline={item.tagline}
                title={item.title}
                text={RichText.render(item.text)}
                link={item.link}
                label={item.label}
                secondLink={item.secondLink}
                secondLabel={item.secondLabel}
                direction={direction}
                video={item.video}
                embed={item.embed}
              />
            ))}
          </Content>
        );
      }
    }

    case SliceTypes.Faq: {
      const { title, items } = slice as IFaq;

      return (
        <Faq title={title}>
          {items?.map((item, key) => (
            <FaqItem
              key={key}
              question={RichText.render(item.question)}
              answer={RichText.render(item.answer)}
            />
          ))}
        </Faq>
      );
    }

    case SliceTypes.Gallery: {
      const { title, items } = slice as IGallery;

      return (
        <Gallery title={title}>
          {items?.map((item, index) => (
            <GalleryItem
              key={index}
              image={item.image}
              title={item.title}
              caption={item.description}
            />
          ))}
        </Gallery>
      );
    }

    case SliceTypes.GalleryGrid: {
      const { tagline, title, items } = slice as IGalleryGrid;

      return (
        <GalleryGrid title={title} tagline={tagline}>
          {items?.map((item, index) => (
            <GalleryGridItem key={index} size={item.size} image={item.image} />
          ))}
        </GalleryGrid>
      );
    }

    case SliceTypes.Video: {
      const { tagline, title, embed, placeholder } = slice as IVideo;

      return <Video title={title} tagline={tagline} embed={embed} placeholder={placeholder} />;
    }

    case SliceTypes.Quote: {
      const { quote, attribution, position, image } = slice as IQuote;

      return (
        <Quote
          text={RichText.render(quote)}
          background={allowBackground}
          attribution={<Author name={attribution} position={position} image={image} />}
        />
      );
    }

    case SliceTypes.Articles: {
      const {
        title,
        tagline,
        text,
        buttonText,
        link,
        items: { items },
        simple,
        tags,
        matchAll,
      } = slice as ILatestArticles;

      let filteredItems = items;

      return (
        <FeaturedArticles
          title={title}
          tagline={tagline}
          text={RichText.render(text)}
          buttonText={buttonText}
          design={simple ? 'simple' : 'default'}
          link={link}
        >
          {filteredItems?.map((item, index) => {
            return (
              <FeaturedArticlesItem
                key={index}
                tagline={item.tagline}
                image={!simple && item.image}
                title={item.title}
                date={item.date}
                link={item.link}
              />
            );
          })}
        </FeaturedArticles>
      );
    }

    case SliceTypes.ArticleIndex: {
      const { lang } = slice as IArticleIndex;

      return <ArticleIndex {...(slice as any)} lang={lang} />;
    }

    case SliceTypes.Documents: {
      const { appearance, title, items } = slice as IDocuments;

      if (appearance) {
        return (
          <Tabs
            tabs={[slice, ...trailing].map(({ title, items }, i) => {
              return {
                title,
                content: (
                  <NewDocuments tab key={i}>
                    {items?.map((item, index) => (
                      <NewDocuments.Item key={index} link={item.link} title={item.title} />
                    ))}
                  </NewDocuments>
                ),
              };
            })}
          />
        );
      }

      return (
        <Documents>
          {items?.map((item, key) => (
            <DocumentsItem
              key={key}
              single={items.length === 1}
              title={item.title}
              link={item.link}
              filename={item.filename}
              filetype={item.filetype}
              filesize={item.filesize}
            />
          ))}
        </Documents>
      );
    }

    case SliceTypes.Buttons: {
      const { title, heading, layout, items } = slice as IButtons;

      const colors = (color) => {
        switch (color) {
          case 'Brand':
            return 'brand';
          case 'White':
            return 'white';
          case 'Dark':
            return 'dark';
          default:
            return 'brand';
        }
      };

      return (
        <Buttons
          title={RichText.render(heading)}
          alignment={layout?.toLowerCase().replace(' ', '-')}
          isSlice
        >
          {items?.map((item, index) => {
            if (item.style === 'Basic' || item.style === null) {
              return (
                <Button key={index} link={item.link} color={colors(item.color)}>
                  {item.label}
                </Button>
              );
            }
            if (item.style === 'Text') {
              return (
                <TextButton key={index} link={item.link}>
                  {item.label}
                </TextButton>
              );
            }
            if (item.style === 'Extended') {
              return (
                <MegaButton
                  key={index}
                  link={item.link}
                  // label={item.label}
                  color={colors(item.color)}
                  caption={item.label}
                  subline={item.subline}
                >
                  {/* {item.label} */}
                </MegaButton>
              );
            }
          })}
        </Buttons>
      );
    }

    case SliceTypes.People: {
      const { title, tagline, text, items } = slice as IPeople;

      return (
        <People title={title} tagline={tagline} text={RichText.render(text)}>
          {items?.map((item, index) => (
            <PeopleItem
              key={index}
              image={item.image}
              name={item.name}
              position={item.position}
              bio={RichText.render(item.bio)}
              tel={item.phonenumber}
              email={item.email}
              link={item.link}
              altLink={item.altLink}
            />
          ))}
        </People>
      );
    }

    case SliceTypes.Pipeline: {
      const { title, sliceTitle, text, footnotes, items, steps } = slice as IPipeline;

      return (
        <Pipeline
          title={title && RichText.render(sliceTitle)}
          text={RichText.asText(text) !== '' ? RichText.render(text) : null}
          footnotes={RichText.render(footnotes)}
          steps={
            steps
              ? steps
                  .split(',')
                  .map((item) => item.trim())
                  .filter((item) => item !== '')
              : null
          }
        >
          {items?.map((item, index) => (
            <PipelineItem
              key={index}
              title={item.title}
              biosimilarCandidate={item.biosimilarCandidate}
              referenceBiologic={item.referenceBiologic}
              therapeuticArea={item.therapeuticArea}
              approvals={item.approvals}
              currentStage={item.currentStage}
            />
          ))}
        </Pipeline>
      );
    }

    case SliceTypes.Form: {
      const { title, text, formId, messageTitle, message, items, ageCheck } = slice as IForm;

      return (
        <FormContainer
          // tagline={tagline}
          title={title}
          text={RichText.render(text)}
          messageTitle={messageTitle}
          message={RichText.render(message)}
          formId={formId}
          formData={items}
          ageCheck={ageCheck}
        />
      );
    }

    // case 'news': {
    //   const sliceData: INews = slice;

    //   // TODO: Move to container
    //   return (
    //     <ArticlesComponent title={sliceData.title} isSlice>
    //       {sliceData.selectedPage.map((article) => (
    //         <Item
    //           key={article.id}
    //           title={article.title}
    //           tagline={article.tagline}
    //           date={article.date}
    //           image={article.image}
    //           link={article.link}
    //         />
    //       ))}
    //     </ArticlesComponent>
    //   );
    // }

    case SliceTypes.Cta: {
      const { title, tagline, text, label, link, graphic } = slice as ICta;

      return (
        <CTA
          tagline={tagline}
          title={title}
          text={RichText.render(text)}
          label={label}
          link={link}
          globe={graphic === '3D Globe'}
          graphic={graphic}
        />
      );
    }

    case SliceTypes.Table: {
      const { title, text, items } = slice as ITable;

      return (
        <Table title={title} text={RichText.render(text)}>
          {items?.map((row, index) => (
            <TableRow key={index} data={[row.column1, row.column2]} />
          ))}
        </Table>
      );
    }

    case SliceTypes.Timeline: {
      const { tagline, title, text, items } = slice as ITimeline;

      return (
        <Timeline index={index} title={title} tagline={tagline} text={RichText.render(text)}>
          {items?.map((item, index) => (
            <TimelineItem
              key={index}
              category={item.category}
              title={item.title}
              text={RichText.render(item.text)}
              image={item.image}
            />
          ))}
        </Timeline>
      );
    }

    case SliceTypes.ImageBlock: {
      const {
        title,
        text,
        image,
        caption,
        link,
        buttonLabel,
        buttonText,
        buttonCaption,
        buttonSubline,
        buttonNumber,
        direction,
        globe,
        video,
        embed,
        items,
      } = slice as IImageBlock;

      return (
        <ImageBlock
          index={index}
          title={title}
          text={RichText.render(text)}
          image={image}
          caption={caption}
          link={link}
          label={buttonLabel}
          buttonText={buttonText}
          buttonCaption={buttonCaption}
          buttonSubline={buttonSubline}
          buttonNumber={buttonNumber}
          direction={direction}
          globe={globe}
          video={video}
          embed={embed}
        >
          {items?.map((item, index) => (
            <TextButton key={index} {...item.link}>
              {item.label || 'Read more'}
            </TextButton>
          ))}
        </ImageBlock>
      );
    }

    case SliceTypes.GalleryBlock: {
      const {
        title,
        text,
        link,
        buttonText,
        buttonLabel,
        buttonCaption,
        buttonSubline,
        buttonNumber,
        items,
      } = slice as IGalleryBlock;

      return (
        <GalleryBlock
          index={index}
          title={title}
          text={RichText.render(text)}
          link={link}
          label={buttonLabel}
          buttonText={buttonText}
          buttonCaption={buttonCaption}
          buttonSubline={buttonSubline}
          buttonNumber={buttonNumber}
        >
          {items?.map((item, index) => (
            <GalleryBlockItem key={index} image={item.image} />
          ))}
        </GalleryBlock>
      );
    }

    case SliceTypes.ColumnSection: {
      const { tagline, title, image, numbered, color, items } = slice as IColumnSection;

      return (
        <ColumnSection
          tagline={tagline}
          title={title}
          image={image}
          numbered={numbered}
          color={color}
        >
          {items?.map((item, index) => (
            <ColumnSectionItem
              key={index}
              title={item.title}
              text={RichText.render(item.text)}
              link={item.link}
              label={item.label}
              icon={item.icon}
            />
          ))}
        </ColumnSection>
      );
    }

    case SliceTypes.ItemList: {
      const { title, text, numbered, items } = slice as IItemList;

      return (
        <ItemList title={title} text={RichText.render(text)}>
          {items?.map((item, index) => (
            <ItemListItem
              key={index}
              index={index + 1}
              title={item.title}
              text={RichText.render(item.text)}
              numbered={numbered}
            />
          ))}
        </ItemList>
      );
    }

    case SliceTypes.MediaList: {
      const { title, archive, aspect, items } = slice as IMediaList;

      return (
        <MediaList title={title} aspect={aspect} archive={archive}>
          {items?.map((item, index) => (
            <MediaListItem
              key={index}
              title={item.title}
              subline={item.subline}
              image={item.image}
              aspect={aspect}
            />
          ))}
        </MediaList>
      );
    }

    case SliceTypes.ContactBlock: {
      const {
        title,
        name,
        text,
        phone,
        email,
        linkedin,
        link,
        linkTagline,
        linkTitle,
      } = slice as IContactBlock;

      return (
        <ContactBlock
          title={title}
          name={name}
          text={RichText.render(text)}
          phone={phone}
          email={email}
          linkedin={linkedin}
          link={link}
          linkTitle={linkTitle}
          linkTagline={linkTagline}
        />
      );
    }

    case 'divider': {
      return <Divider />;
    }

    case SliceTypes.JobListing: {
      const { tagline, title, items } = slice as IJobListing;

      return <JobListingContainer tagline={tagline} title={title} jobs={items} />;
    }

    case SliceTypes.Infographic: {
      const { image, number, text } = slice as IInfographic;

      return <Infographic text={RichText.render(text)} number={number} image={image} />;
    }

    case SliceTypes.Infographics: {
      const { title, text, items } = slice as IInfographics;

      return (
        <Infographics title={title} text={RichText.render(text)}>
          {items?.map((item, index) => (
            <Infographic
              key={index}
              text={RichText.render(item.text)}
              number={item.number}
              image={item.image}
            />
          ))}
        </Infographics>
      );
    }

    case SliceTypes.Stats: {
      const { tagline, items } = slice as IStats;

      return (
        <Stats kicker={tagline}>
          {items?.map((item, key) => (
            <Stats.Item key={key} label={item.label}>
              {item.number}
            </Stats.Item>
          ))}
        </Stats>
      );
    }

    case SliceTypes.Offices: {
      const { title, image, display, items } = slice as IOffices;

      return (
        <Offices title={title} image={image} display={display} isSlice>
          {items?.map((item, index) => (
            <OfficesItem
              key={index}
              title={item.title}
              text={RichText.render(item.text)}
              hasAddress={item.hasAddress}
              address={RichText.render(item.address)}
              phone={item.phone}
              fax={item.fax}
              email={item.email}
            />
          ))}
        </Offices>
      );
    }

    case SliceTypes.NewsletterSignup: {
      const { tagline, title } = slice as INewsletterSignup;

      return <Newsletter tagline={tagline} title={title} />;
    }

    case SliceTypes.CookiePolicy: {
      return (
        <Segment>
          <div id="cookie-policy-text" style={{ marginTop: '-5rem' }} />
        </Segment>
      );
    }

    case SliceTypes.Catalog: {
      const { title, tagline, items } = slice as ICatalog;

      return <CatalogContainer title={title} tagline={tagline} catalog={items} />;
    }

    case SliceTypes.CatalogBranded: {
      const { title, tagline, text, items } = slice as ICatalogBranded;

      return (
        <IndexGrid title={title} tagline={tagline} text={RichText.render(text)} simple>
          {items?.map((item, index) => (
            <IndexGrid.Item
              key={index}
              title={item.title}
              image={item.brandImage}
              link={item.detailsLink}
              note={item.warning && <b>Product contains a BOXED WARNING.</b>}
              label="View details"
            />
          ))}
        </IndexGrid>
      );
    }

    case SliceTypes.CatalogAlmatica: {
      const { title, tagline, text, items } = slice as ICatalogAlmatica;

      return (
        <IndexGrid title={title} tagline={tagline} text={RichText.render(text)}>
          {items?.map((item, index) => {
            // console.log(item, item.product);

            return (
              <IndexGrid.Item
                key={index}
                title={item.title || item.product?.title}
                image={item.image || item.product?.image}
                link={item.link || item.product?.link}
                note={
                  (item.warning || item.product?.warning) && (
                    <b>Product contains a BOXED WARNING.</b>
                  )
                }
                label={item.label || 'Visit website'}
              />
            );
          })}
        </IndexGrid>
      );
    }

    case SliceTypes.CatalogAlmaject: {
      const { title, tagline, text, footnote, link, items } = slice as ICatalogAlmaject;

      return (
        <IndexGrid
          title={title}
          tagline={tagline}
          text={RichText.render(text)}
          footnote={RichText.render(footnote)}
          link={link}
          label="Download Catalog"
          simple
        >
          {items?.map((item, index) => {
            return (
              <IndexGrid.Item
                key={index}
                title={RichText.asText(item.title)}
                image={item.images[0].image}
                link={{ href: `/product/${item.uid}` }}
                note={item.boxedWarning && <b>Product contains a BOXED WARNING.</b>}
                label="View details"
              />
            );
          })}
        </IndexGrid>
      );
    }

    case SliceTypes.CatalogIceland: {
      const { title, tagline, categories, showAll, items } = slice as ICatalogIceland;

      const catgoryList = categories ? categories.split(',').map((s) => s.trim()) : [];

      return (
        <CatalogTableContainer
          title={title}
          tagline={tagline}
          catalog={items.products}
          verification={items.verification}
          options={catgoryList}
          showAll={showAll}
        />
      );
    }

    case SliceTypes.CatalogBasic: {
      const { title, tagline, text, tags, categories, showAll, items } = slice as ICatalogBasic;

      const tagList = tags ? tags.split(',').map((s) => s.trim()) : [];
      const catgoryList = categories ? categories.split(',').map((s) => s.trim()) : [];
      // console.log(
      //   tagList,
      //   items?.filter((item) => item.tags.some((t) => tagList.includes(t)))
      // );

      return (
        <CatalogBasicContainer
          title={title}
          tagline={tagline}
          catalog={items?.filter((item) => item.tags.some((t) => tagList.includes(t)))}
          options={catgoryList}
          showAll={showAll}
        />
      );
    }

    case SliceTypes.CatalogSimple: {
      const { title, tagline, tags, items, lang } = slice as ICatalogSimple;

      const tagList = tags ? tags.split(',').map((s) => s.trim()) : [];

      return (
        <CatalogSimpleContainer
          title={title}
          tagline={tagline}
          filters={tagList}
          catalog={items?.filter(
            (item) => tagList.length < 1 || item.tags.some((t) => tagList.includes(t))
          )}
          lang={lang}
        />
      );
    }

    case SliceTypes.Variant: {
      const { image, ndc, strength, form, size, gcn, gcnSeq } = slice as IVariant;

      return (
        <Variant image={image} ndc={ndc} strength={strength} size={size} cgn={gcn} seq={gcnSeq} />
      );
    }

    case SliceTypes.Stories: {
      const { items } = slice as IStories;

      return <Stories stories={items} />;
    }

    case SliceTypes.TextBlock: {
      const { title, text, color, aside } = slice as ITextBlock;

      return (
        <TextBlock
          title={title}
          text={RichText.render(text)}
          color={color}
          textLength={RichText.asText(text)?.trim()?.length}
        >
          {RichText.asText(aside) !== '' ? (
            <Text hasLayout={false} text={RichText.render(aside)} />
          ) : null}
        </TextBlock>
      );
    }

    case SliceTypes.VideoList: {
      const { tagline, title, text, items } = slice as IVideoList;

      return (
        <VideoList tagline={tagline} title={title} text={RichText.render(text)}>
          {items?.map((item, index) => (
            <VideoItem key={index} title={item.title} embed={item.embed} placeholder={item.image} />
          ))}
        </VideoList>
      );
    }

    // case SliceTypes.Data: {
    //   const { title, label, timestamp, items} = slice as IData;

    //   const hasTitle = label?.trim() !== '';

    //   return (
    //     <Data title={hasTitle && title}>
    //       {[sliceData, ...trailing].map((slice, i) => (
    //         <React.Fragment key={i}>
    //           <Data.Header title={slice.label} date={slice.date} />
    //           <Data.List>
    //             {slice.items.map((item, key) => (
    //               <Data.Item key={key} label={item.key}>
    //                 {item.value}
    //               </Data.Item>
    //             ))}
    //           </Data.List>
    //         </React.Fragment>
    //       ))}
    //     </Data>
    //   );
    // }

    case SliceTypes.Events: {
      const { items } = slice as IEvents;

      return <Events items={items} />;
    }

    case SliceTypes.IndexGrid: {
      const {
        tagline,
        title,
        text,
        image,
        link,
        label,
        tags,
        categories,
        showAll,
        gridItems,
        items,
      } = slice as IIndexGrid;

      // console.log(items, gridItems, tags, categories);

      return (
        <IndexGridContainer
          tagline={tagline}
          title={title}
          text={RichText.render(text)}
          image={image}
          link={link}
          label={label}
          options={categories}
          showAll={showAll}
          listItems={items?.filter((item) => item.tags.some((t) => tags.includes(t)))}
        >
          {gridItems?.map((item, index) => (
            <IndexGrid.Item
              key={item.link?.id || index}
              link={item.link}
              label={item.label}
              title={item.title}
              text={RichText.render(item.text)}
              image={item.image}
            />
          ))}
        </IndexGridContainer>
      );
    }

    case SliceTypes.Packages: {
      const { tagline, title, items } = slice as IPackages;

      return <Packages tagline={tagline} title={title} data={items} />;
    }

    case SliceTypes.Banner: {
      const { tagline, title, text, link, label, image, side } = slice as IBanner;

      return (
        <Banner
          tagline={tagline}
          title={title}
          text={RichText.render(text)}
          link={link}
          label={label}
          image={image}
          side={side}
        />
      );
    }

    case SliceTypes.Retailers: {
      const { tagline, title, text, hasText, items } = slice as IRetailers;

      return (
        <Retailers tagline={tagline} title={title} text={hasText && RichText.render(text)}>
          {items?.map((item, index) => (
            <Retailers.Item
              key={index}
              link={item.link}
              label={item.label}
              title={item.title}
              image={item.image}
            />
          ))}
        </Retailers>
      );
    }

    case SliceTypes.Sales: {
      const { tagline, title, caption, items } = slice as ISales;

      return (
        <SalesContainer
          tagline={tagline}
          title={title}
          caption={RichText.render(caption)}
          figures={items}
        />
      );
    }

    case SliceTypes.Quarters: {
      const { tagline, title, items } = slice as IQuarters;

      return (
        <Quarters tagline={tagline} title={title}>
          {items.map((year, i) => (
            <Quarters.Year key={year.title} title={year.title} open={i < 4}>
              {year.quarters.map((quarter) => (
                <Quarters.Quarter
                  key={`${year.title}${quarter.quarter}`}
                  title={quarter.quarter}
                  consolidated={quarter.consolidated}
                  press={quarter.press}
                  earnings={quarter.earnings}
                  webcast={quarter.webcast}
                />
              ))}
            </Quarters.Year>
          ))}
        </Quarters>
      );
    }

    case SliceTypes.Shareholder: {
      const { tagline, title, items } = slice as IShareholder;

      const reports = items
        .filter((year) => year.annualReport?.href)
        .map((year) => (
          <Shareholder.Report key={year.title} title={year.title} link={year.annualReport} />
        ));

      return (
        <Shareholder tagline={tagline} title={title} reports={reports}>
          {items
            .filter((year) => year.documents?.length > 0)
            .map((year, i) => (
              <Shareholder.Year key={year.title} title={year.title}>
                {year.documents?.map((document) => (
                  <Shareholder.Document
                    key={document.title}
                    title={document.title}
                    link={document.link}
                  />
                ))}
              </Shareholder.Year>
            ))}
        </Shareholder>
      );
    }

    case SliceTypes.Related: {
      const { mainLink, mainLinkText, items } = slice as IRelated;

      return (
        <ArticlesRelated
          mainLink={mainLink}
          mainLinkText={mainLinkText}
          sections={items.map((item) => ({
            title: item.title,
            text: RichText.render(item.text),
          }))}
        />
      );
    }

    // Alvotech ESG slices Start
    case SliceTypes.Accordion: {
      const { sliceTitle, text, image, caption, items } = slice as IAccordion;

      return (
        <Accordion
          title={RichText.render(sliceTitle)}
          image={image}
          caption={RichText.render(caption)}
          text={RichText.render(text)}
        >
          {items?.map((item, key) => (
            <Accordion.Item
              key={key}
              id={key}
              title={RichText.render(item.title)}
              text={RichText.render(item.text)}
              icon={item.icon}
            />
          ))}
        </Accordion>
      );
    }

    case SliceTypes.Portal: {
      const { sliceTitle, text, tagline, items } = slice as IPortal;

      return (
        <Portal title={RichText.render(sliceTitle)} text={RichText.render(text)} tagline={tagline}>
          {items?.map((item, key) => (
            <Portal.Item
              key={key}
              image={item.image}
              title={item.title}
              text={RichText.render(item.text)}
              link={item.link}
            />
          ))}
        </Portal>
      );
    }

    case SliceTypes.QuoteBlock: {
      const { sliceTitle, text, author, role, image, offset, side, items } = slice as IQuoteBlock;

      return (
        <QuoteBlock
          title={RichText.render(sliceTitle)}
          text={RichText.render(text)}
          author={author}
          role={role}
          image={image}
          offset={offset}
          side={side}
        >
          {items?.map((item, key) => (
            <QuoteBlock.Item key={key} label={item.label} link={item.link} />
          ))}
        </QuoteBlock>
      );
    }

    case SliceTypes.HeroButtons: {
      const { sliceTitle, text, tagline, image, caption, items } = slice as IHeroButtons;

      const infoLinks = items
        .filter((item) => item.link && item.link?.size === undefined)
        .map((item, key) => (
          <HeroButtons.Item key={key} label={item.label} link={item.link} button />
        ));

      const dataLinks = items
        .filter((item) => item.link && (item.link?.size || item.link?.size === 0))
        .map((item, key) => <HeroButtons.Item key={key} label={item.label} link={item.link} />);

      return (
        <HeroButtons
          title={RichText.render(sliceTitle)}
          text={RichText.render(text)}
          tagline={tagline}
          image={image}
          caption={RichText.render(caption)}
          infoLinks={infoLinks.length > 0 ? infoLinks : null}
          dataLinks={dataLinks.length > 0 ? dataLinks : null}
        />
      );
    }

    case SliceTypes.SmallPrint: {
      const { sliceTitle, text, items } = slice as ISmallPrint;

      return (
        <SmallPrint title={RichText.render(sliceTitle)} text={RichText.render(text)}>
          {items?.map((item, key) => (
            <SmallPrint.Footnote key={key} text={RichText.render(item.text)} symbol={item.symbol} />
          ))}
        </SmallPrint>
      );
    }
    // Alvotech ESG slices End

    default: {
      console.warn('Unhandled slice type:', type /* toJS(slice) */);

      return <></>;
    }
  }
};

const parse = (slices: any): ISlice[] => {
  const typeWithBackground = ['articles', 'services', 'quote'];

  return slices.reduce((prev: ISlice[], slice: any, index: number) => {
    const isFirst = index === 0;
    const isLast = index === slices.length - 1;
    const allowsBackground = typeWithBackground.indexOf(slice.type) !== -1;
    const backgroundBefore = index > 0 && prev[index - 1].allowBackground;

    const obj: ISlice = {
      slice,
      allowBackground: !isFirst && !isLast && !backgroundBefore && allowsBackground,
      isFirst,
      isLast,
    };

    return [...prev, obj];
  }, []);
};

const combineSlices = (slices: ISlice[]): ISlice[] => {
  const primaryTypes = [SliceTypes.Text, SliceTypes.Table, SliceTypes.Quote];
  const combineWithText = [
    SliceTypes.Buttons,
    SliceTypes.Documents,
    SliceTypes.Quote,
    SliceTypes.Text,
    SliceTypes.Infographic,
    SliceTypes.CookiePolicy,
  ];

  return slices.reduce((prev: ISlice[], current: ISlice) => {
    const prevSlice = prev.length > 0 && prev[prev.length - 1].slice;

    // Handle Documents
    if (
      prevSlice?.type === SliceTypes.Documents &&
      current.slice.type === prevSlice.type &&
      (current.slice as IDocuments).appearance === (prevSlice as IDocuments).appearance &&
      (current.slice as IDocuments).appearance === true &&
      (prevSlice as IDocuments).appearance === true
    ) {
      prev[prev.length - 1].trailing = [...(prev[prev.length - 1].trailing || []), current.slice];

      return prev;
    } else if (
      current.slice.type === SliceTypes.Documents &&
      (current.slice as IDocuments).appearance
    ) {
      return [...prev, current];
    }

    // Handle data
    // if (prevSlice && prevSlice.type === 'data' && current.slice.type === prevSlice.type) {
    //   prev[prev.length - 1].trailing = [...(prev[prev.length - 1].trailing || []), current.slice];

    //   return prev;
    // }

    const prevShouldCombine = prevSlice && primaryTypes.indexOf(prevSlice.type) !== -1;
    const isAcceptable = combineWithText.indexOf(current.slice.type) !== -1;
    const hasTitle = !!current.slice.title && current.slice.title !== '';

    if (prevShouldCombine && isAcceptable && !hasTitle) {
      prev[prev.length - 1].extras = [...(prev[prev.length - 1].extras || []), current];

      return prev;
    }

    return [...prev, current];
  }, []);
};

export const Slices = ({
  slices = [],
  isArticle = false,
  isEvent = false,
  isProduct = false,
  isSplitPage = false,
}: Props) => {
  const combined = combineSlices(parse(slices));

  return (
    <>
      {combined
        .map((slice, index) => {
          if (slice.extras) {
            return (
              <Segment isSplitPage isArticle>
                {mapComponent(slice, index)}
                {slice.extras.map((extra, key) =>
                  React.cloneElement(mapComponent(extra, index), { key })
                )}
              </Segment>
            );
          }

          return mapComponent(slice, index);
        })
        .filter((x) => !!x)
        .map((component, key) =>
          React.cloneElement(component, {
            key,
            isSlice: !isProduct,
            isSplitPage,
            isArticle,
            isProduct,
            isEvent,
          })
        )}
    </>
  );
};
