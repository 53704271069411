import React from 'react';

import s from './Table.module.scss';

type Props = {
  data: any[];
};

export const TableRow = ({ data = [] }: Props) => {
  return (
    <div className={s.row}>
      {data.map((col, key) => (
        <div key={key} className={s.row__col}>
          {col}
        </div>
      ))}
    </div>
  );
};
