import React from 'react';
import classnames from 'classnames';
import { ChevronRight } from 'components/assets';

import s from './Quarters.module.scss';

type Props = {
  title?: React.ReactNode;
  open?: boolean;
  children?: React.ReactNode;
};

export const Year = ({ title, open = false, children }: Props) => {
  const [collapsed, setCollapsed] = React.useState(!open);

  return (
    <div className={classnames(s.year, { [s.collapsed]: collapsed })}>
      <div className={s.year__layout}>
        <div className={s.year__header} onClick={() => setCollapsed(false)}>
          <h2 className={s.year__title}>{title}</h2>
          {!open && collapsed && (
            <button className={s.year__toggle} onClick={() => setCollapsed(!collapsed)}>
              <ChevronRight />
            </button>
          )}
        </div>
        <div className={s.year__grid}>{children}</div>
      </div>
    </div>
  );
};
