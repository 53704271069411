import React from 'react';
import { ILink } from 'utils/types';
import { MegaButton } from 'components/button/MegaButton';
import s from './ArticlesRelated.module.scss';

type Props = {
  sections: Array<{
    title: string;
    text?: React.ReactNode;
  }>;
  mainLink: ILink;
  mainLinkText: string;
};

export const ArticlesRelated = ({ sections, mainLink, mainLinkText }: Props) => {
  return (
    <li className={s.articlesRelated}>
      <div className={s.articlesRelated__sections}>
        {sections?.map((section, index) => (
          <div key={index} className={s.articlesRelated__section}>
            {section.title && <h2 className={s.articlesRelated__title}>{section.title}</h2>}
            {section.text && <div className={s.articlesRelated__text}>{section.text}</div>}
          </div>
        ))}
      </div>
      {mainLink && mainLinkText && (
        <div className={s.articlesRelated__button}>
          <MegaButton link={mainLink} caption={mainLinkText} color="dark" />
        </div>
      )}
    </li>
  );
};
