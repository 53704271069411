import React from 'react';
import classnames from 'classnames';

import s from './SmallPrint.module.scss';

type Props = {
  title?: React.ReactNode;
  text?: React.ReactNode;
  children?: React.ReactNode;
};

type FootnoteProps = {
  symbol?: React.ReactNode;
  text?: React.ReactNode;
};

interface ISmallPrint extends React.FC<Props> {
  Footnote: typeof Footnote;
}

const SmallPrint: ISmallPrint = function Card({ title, text, children }: Props) {
  const childCount = React.Children.count(children);

  return (
    <div className={classnames(s.small, { [s.hasText]: !!title || !!text })}>
      <div className={s.small__container}>
        <div className={s.small__row}>
          <div className={s.small__layout}>
            {(!!title || !!text) && (
              <div className={s.small__disclaimer}>
                {!!title && <div className={s.small__heading}>{title}</div>}
                {!!text && <div className={s.small__text}>{text}</div>}
              </div>
            )}
            {childCount > 0 && (
              <div className={s.small__footnotes}>
                <ol className={s.small__list}>{children}</ol>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Footnote = ({ symbol, text }: FootnoteProps) => {
  return (
    <li data-symbol={symbol} className={s.footnote}>
      {text}
    </li>
  );
};

SmallPrint.Footnote = Footnote;

export { SmallPrint };
