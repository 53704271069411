import { print } from 'graphql';
import gql from 'graphql-tag';
import { RichTextBlock } from 'prismic-reactjs';
import btoa from 'btoa';

import { fetchAPI, linkFragment } from '.';
import { IImage, ILink } from 'utils/types';
import { IBaseSlice, PageTypes, parseSlices } from './slices';
import { generateSliceFragments } from './slices';
import { PrismicImage } from './utils/PrismicTypes';
import { parseDateTime, parseImage, parseLinkFromMeta, parseString } from './utils/parsers';

export interface IAllArticles {
  total: number;
  hasNextPage?: boolean;
  endCursor?: string;

  items: IArticle[];
}

export interface IArticle {
  uid: string;
  date: string;
  title: string;
  tagline: string;
  image?: IImage;
  caption: string;
  summary: string;
  showImage?: boolean;
  link?: ILink;
  tags?: string[];
  seo?: {
    title: string;
    description: string;
    image: IImage;
  };
  extract?: any;
  slices?: IBaseSlice[];
}

export type PrismicArticles = {
  allArticles: {
    totalCount: number;
    pageInfo?: {
      hasNextPage: boolean;
      endCursor: string;
    };
    edges: Array<{
      node: {
        date: string;
        title: RichTextBlock[];
        tagline: string;
        image: PrismicImage;
        caption: RichTextBlock[];
        summary: RichTextBlock[];
        body?: any;
        _meta: {
          uid: string;
          type: string;
          lang: string;
          tags: string[];
        };
      };
    }>;
  };
};

export const fetchArticles = async (
  lang: string,
  endCursor: string = '',
  previewData: boolean,
  limit?: number,
  tags?: string[],
  tagsIn?: string[],
  tagline?: string,
  dateBefore?: string,
  dateAfter?: string,
  fulltext?: string
): Promise<PrismicArticles> => {
  return await fetchAPI(
    print(gql`
      query LatestArticles(
        $lang: String!
        $endCursor: String
        $limit: Int
        $tags: [String!]
        $tagsIn: [String!]
        $tagline: String
        $dateBefore: Date
        $dateAfter: Date
        $fulltext: String
      ) {
        allArticles(
          lang: $lang
          sortBy: date_DESC
          first: $limit
          after: $endCursor
          tags: $tags
          tags_in: $tagsIn
          where: { tagline_fulltext: $tagline, date_after: $dateAfter, date_before: $dateBefore }
          fulltext: $fulltext
        ) {
          totalCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              date
              title
              tagline
              image
              caption
              summary
              body {
                ... on ArticleBodyText {
                  type
                  primary {
                    slice_text
                  }
                }
              }
              _meta {
                uid
                type
                lang
                tags
              }
            }
          }
        }
      }
    `),
    {
      previewData,
      variables: {
        lang,
        endCursor,
        limit,
        tags,
        tagsIn,
        tagline,
        dateBefore,
        dateAfter,
        fulltext,
      },
    }
  );
};

// Recursivly fetch all items in a list since prismic graphql endpoint only returns max 20 items
const fetchAllArticles = async (
  lang: string,
  endCursor: string = '',
  previewData,
  limit?: number,
  tags?: string[]
): Promise<PrismicArticles> => {
  const articles = await fetchArticles(lang, endCursor, previewData, limit, tags);

  if (articles?.allArticles.pageInfo.hasNextPage && articles?.allArticles.edges.length < limit) {
    const nestedList = await fetchAllArticles(
      lang,
      articles.allArticles.pageInfo.endCursor,
      previewData,
      limit,
      tags
    );
    return {
      allArticles: {
        totalCount: articles.allArticles.totalCount,
        edges: [...articles.allArticles.edges, ...nestedList.allArticles.edges],
      },
    };
  }
  return articles;
};

export const getArticles = async ({
  previewData = false,
  lang = undefined,
  limit = 10,
  redirects = [],
  page = undefined,
  after = undefined,
  tagline = undefined,
  dateBefore = undefined,
  dateAfter = undefined,
  fulltext = undefined,
}) => {
  const endCursor =
    page && page !== 1 && !after
      ? btoa(`arrayconnection:${(page - 1) * limit - 3}`) // -3 because of first page shows only 19 instead of 21 items, and we want to target the last item on the first page
      : after;

  const data = await fetchArticles(
    lang,
    endCursor,
    previewData,
    limit,
    undefined,
    undefined,
    tagline,
    dateBefore,
    dateAfter,
    fulltext
  );
  return parseArticles(data, redirects, lang);
};

export const getAllArticles = async ({
  previewData = false,
  lang = undefined,
  limit = 3,
  tags = [],
  redirects = [],
} = {}): Promise<IAllArticles> => {
  const data = await fetchAllArticles(lang, '', previewData, limit, tags);

  return parseArticles(data, redirects, lang);
};

export const getArticleBySlug = async (
  slug: string,
  { previewData = false, lang = undefined, redirects = [] }
): Promise<IArticle> => {
  const articleFragments = await generateSliceFragments(PageTypes.Article, 'ArticleSlices');

  const query = gql`
    query ArticleBySlug($lang: String!, $slug: String!) {
      article(lang: $lang, uid: $slug) {
        _meta {
          id
          uid
        }

        date
        title
        tagline
        image
        caption
        summary
        showimage

        seo_title
        seo_description
        seo_image

        body {
          ...ArticleSlices
        }
      }
    }

    ${articleFragments}
    ${linkFragment}
  `;

  const res = await fetchAPI(print(query), {
    previewData,
    variables: {
      slug,
      lang,
    },
  });

  if (!res || !res.article) {
    return null;
  }

  const article = res.article;

  const slices = await parseSlices(article.body || [], lang, previewData, redirects);

  return {
    uid: article._meta.uid || null,
    date: parseDateTime(article.date, undefined, lang),
    title: parseString(article.title),
    tagline: parseString(article.tagline),
    image: parseImage(article.image),
    showImage: article.showimage === 'True',
    caption: parseString(article.caption),
    summary: parseString(article.summary),
    seo: {
      title: parseString(article.seo_title),
      description: parseString(article.seo_description),
      image: parseImage(article.seo_image),
    },
    extract: parseString(article.body?.find((slice) => slice.type === 'text')?.primary.slice_text),
    slices,
  };
};

export const parseArticles = (data: PrismicArticles, redirects, lang): IAllArticles => {
  return (
    data && {
      total: data.allArticles.totalCount,
      hasNextPage: data.allArticles.pageInfo.hasNextPage,
      endCursor: data.allArticles.pageInfo.endCursor,

      items:
        data.allArticles?.edges.map(({ node: item }) => ({
          uid: item._meta.uid || null,
          date: parseDateTime(item.date, undefined, lang),
          title: parseString(item.title),
          tagline: parseString(item.tagline),
          image: parseImage(item.image),
          caption: parseString(item.caption),
          summary: parseString(item.summary),
          link: parseLinkFromMeta(item._meta, redirects),
          tags: item._meta?.tags ?? [],
          extract: parseString(
            item.body?.find((slice) => slice.type === 'text')?.primary.slice_text
          ),
        })) ?? null,
    }
  );
};
