import React from 'react';
import Image from 'next/image';
import classnames from 'classnames';
import { t, Trans } from '@lingui/macro';
import { Link } from 'components/link/Link';
import { IImage, ILink } from 'utils/types';
import { Tagline } from 'components/tagline/Tagline';
import { Item as GridItem } from './GridItem';
import { ListItem } from './ListItem';
import { Button } from 'components/button/Button';

import s from './IndexGrid.module.scss';
import { Select } from 'components/form/Select';

type Props = {
  tagline?: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  footnote?: React.ReactNode;
  image?: IImage;
  link?: ILink;
  label?: React.ReactNode;
  listItems?: Array<{
    tagline: string;
    title: string;
    intro: string;
    tags: string[];
    images: Array<{
      image: IImage;
    }>;
    detailsLink?: ILink;
  }>;
  filters?: string[];
  noContainer?: boolean;
  simple?: boolean;
  onOptionChange?: (option: string) => void;
  children?: React.ReactNode;
};

interface IIndexGrid extends React.FC<Props> {
  Item: typeof GridItem;
  ListItem: typeof ListItem;
}

const IndexGrid: IIndexGrid = function IndexGrid({
  tagline,
  title,
  text,
  footnote,
  image,
  link,
  label,
  listItems,
  filters,
  noContainer,
  simple,
  onOptionChange = () => {},
  children,
}) {
  const options = filters;
  const [listView, setListView] = React.useState<boolean>(false);
  const [currentOption, setCurrentOption] = React.useState<string>(
    filters?.length > 0 ? filters[0] : '-'
  );
  const hasChildren = React.Children.toArray(children).length > 0;
  const hasListItems = listItems?.length > 0;
  const hasLink = link && !!link.href;
  const hasImage = image && !!image.url;

  const changeOption = (value) => {
    setListView(true);
    setCurrentOption(value);
    onOptionChange(value);
  };

  React.useEffect(() => {
    if (filters?.length > 0 && currentOption === filters[0]) {
      setListView(false);
    }
  }, [currentOption]);

  const optionsSelect = (
    <div className={s.dropdown}>
      <select
        className={s.dropdown__input}
        id="indexFilter"
        name="indexFilter"
        onChange={(e) => changeOption(e.target.value)}
      >
        {options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );

  const empty = (
    <div className={s.index}>
      <div className={s.index__empty}>
        <Trans>No matching products found</Trans>
      </div>
    </div>
  );

  const content = (
    <div className={s.index__layout}>
      {(title || tagline) && (
        <header className={classnames(s.index__header, { [s.hasImage]: hasImage })}>
          <div className={s.index__headlines}>
            <Tagline text={tagline} />
            <h1 className={s.index__title}>{title}</h1>
          </div>
          {(hasLink || hasListItems) && (
            <div className={s.index__actions}>
              {hasLink && (
                <Link className={s.index__button} href={link?.href}>
                  {label || t`label missing`}
                </Link>
              )}
              {hasListItems && options?.length < 1 && (
                <button className={s.index__button} onClick={() => setListView(!listView)}>
                  {!listView ? 'Sýna allar vörur' : 'Sýna vöruflokka'}
                </button>
              )}
              {options?.length > 0 && optionsSelect}
            </div>
          )}
          {(text || hasImage) && (
            <div className={s.index__content}>
              <div className={s.index__text}>{text}</div>
              {hasImage && (
                <figure className={s.index__image}>
                  <Image
                    src={image?.desktop?.url || image?.url}
                    width={image.desktop.width}
                    height={image.desktop.height}
                    layout="responsive"
                  />
                </figure>
              )}
            </div>
          )}
        </header>
      )}
      {!listView && <div className={s.index__grid}>{children}</div>}
      {listView && (
        <ul className={s.index__list}>
          {listItems.map((item, index) => (
            <IndexGrid.ListItem
              key={index}
              title={item.title}
              text={item.intro}
              image={item.images[0]?.image}
              link={item.detailsLink}
            />
          ))}
        </ul>
      )}
      {footnote && <div className={s.index__footnote}>{footnote}</div>}
    </div>
  );

  const index = hasChildren ? content : empty;

  const container = (
    <div className={s.index__segment}>
      <div className={s.index__container}>{index}</div>
    </div>
  );

  return (
    <section className={classnames(s.index, { [s.simple]: simple })}>
      {noContainer ? index : container}
    </section>
  );
};

IndexGrid.Item = GridItem;
IndexGrid.ListItem = ListItem;

export { IndexGrid };
