import React from 'react';
import Image from 'next/image';
import classnames from 'classnames';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { IImage, ILink } from 'utils/types';
import { Quote } from 'components/quote/Quote';
import { Author } from 'components/author/Author';
import { TextButton } from 'components/button/TextButton';
import { BackgroundSection } from 'components/background/BackgroundSection';

import s from './QuoteBlock.module.scss';

type Props = {
  children: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  author?: string;
  role?: string;
  image?: IImage;
  offset?: string;
  side?: 'left' | 'right';
  isSlice?: boolean;
};

type ItemProps = {
  link?: ILink;
  label?: string;
};

interface IQuoteBlock extends React.FC<Props> {
  Item: typeof QuoteBlockItem;
}

const QuoteBlock: IQuoteBlock = function Card({
  children,
  title,
  text,
  author,
  role,
  image,
  offset,
  side = 'right',
  isSlice,
}) {
  const content = (
    <div className={s.quote__layout}>
      <div className={s.quote__main}>
        <div className={s.quote__title}>{title}</div>
        <div className={s.quote__quote}>
          <Quote
            isBlock
            isSlice={false}
            text={text}
            attribution={<Author name={author} position={role} />}
          />
        </div>
        <div className={s.quote__links}>{children}</div>
      </div>
      {image?.url && (
        <div className={s.quote__aside}>
          <div className={s.quote__image}>
            <Image
              src={image.desktop?.url || image.url}
              layout="fill"
              objectFit="cover"
              alt={image.alt || ''}
              sizes="640px"
            />
          </div>
        </div>
      )}
    </div>
  );

  const container = (
    <div className={s.quote__container}>
      <div className={s.quote__segment}>
        <div className={s.quote__content}>{content}</div>
      </div>
    </div>
  );

  return (
    <section
      className={classnames(s.quote, !!image && s.hasImage, s[side], {
        [s.offsetTop]: offset === 'offset top',
        [s.offsetBottom]: offset === 'offset bottom',
      })}
    >
      <BackgroundSection color="white">{isSlice ? container : content}</BackgroundSection>
    </section>
  );
};

export const QuoteBlockItem = ({ link, label }: ItemProps) => {
  const { i18n } = useLingui();

  if (!link) {
    return null;
  }

  return <TextButton href={link.href}>{label || i18n._(t`Learn more`)}</TextButton>;
};

QuoteBlock.Item = QuoteBlockItem;

export { QuoteBlock };
