import React from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import { TimelineLite } from 'gsap';

import { Text } from 'components/text/Text';
import { TextWrap } from 'components/text/TextWrap';

import s from './Table.module.scss';

type Props = {
  title?: string;
  isSlice?: boolean;
  text: React.ReactNode;
  children: React.ReactNode;
};

export const Table = ({ title, text, children, isSlice }: Props) => {
  const listRef = React.useRef<HTMLDivElement | null>(null);
  const [hasRun, setHasRun] = React.useState(false);

  const revealChildren = () => {
    const timeline = new TimelineLite();

    timeline.staggerTo(
      Array.from((listRef.current as any).childNodes),
      1,
      { autoAlpha: 1, y: '0', ease: 'Expo.easeOut' },
      0.1
    );
  };

  const runCheck = (isVisible) => {
    if (!hasRun) {
      setHasRun(isVisible);
    }

    if (!hasRun && isVisible) {
      revealChildren();
    }
  };

  const content = (
    <VisibilitySensor partialVisibility={true} onChange={runCheck}>
      <div>
        <Text title={title} text={text} />
        <div className={classnames(s.table, s.spacing)} ref={listRef}>
          {children}
        </div>
      </div>
    </VisibilitySensor>
  );

  if (!isSlice) {
    return content;
  }

  return <TextWrap>{content}</TextWrap>;
};
