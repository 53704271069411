import gql from 'graphql-tag';

import { IBaseSlice, SliceTypes } from '.';

export interface ICookiePolicy extends IBaseSlice {}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
    }
  }
`;

type Props = {};

export const parse = (slice: Props): ICookiePolicy => ({
  type: SliceTypes.CookiePolicy,
});
