import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { PrismicLink } from '../utils/PrismicTypes';
import { parseLink, parseRichText, parseString } from '../utils/parsers';

export interface IButtons extends IBaseSlice {
  title: string;
  heading: RichTextBlock[];
  layout: string;
  items: Array<{
    label: string;
    link: ILink;
    color: string;
    style: string;
    subline: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_layout
      }
      fields {
        item_label
        item_link { ...Link }
        item_color
        item_style
        item_subline
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_layout: string;
  };
  fields: Array<{
    item_label: RichTextBlock[];
    item_link: PrismicLink;
    item_color: string;
    item_style: string;
    item_subline: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects?): IButtons => ({
  type: SliceTypes.Buttons,
  title: parseString(slice.primary.slice_title),
  heading: parseRichText(slice.primary.slice_title),
  layout: slice.primary.slice_layout || null,
  items: slice.fields
    .map((field) => ({
      label: parseString(field.item_label),
      link: parseLink(field.item_link, redirects),
      color: field.item_color || null,
      style: field.item_style || null,
      subline: parseString(field.item_subline),
    }))
    .filter((f) => f.link),
});
