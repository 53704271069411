import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { PrismicImage } from '../utils/PrismicTypes';
import { parseImage, parseRichText, parseString } from '../utils/parsers';

export interface IInfographic extends IBaseSlice {
  image: IImage;
  number: string;
  text: RichTextBlock[];
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_image
        slice_number
        slice_text          
      }
    }
  }
`;

type Props = {
  primary: {
    slice_image: PrismicImage;
    slice_number: RichTextBlock[];
    slice_text: RichTextBlock[];
  };
};

export const parse = (slice: Props, redirects): IInfographic => ({
  type: SliceTypes.Infographic,
  image: parseImage(slice.primary.slice_image),
  number: parseString(slice.primary.slice_number),
  text: parseRichText(slice.primary.slice_text, redirects),
});
