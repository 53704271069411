import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '.';
import { parseRichText, parseString } from '../utils/parsers';

export interface ISmallPrint extends IBaseSlice {
  sliceTitle: RichTextBlock[];
  text: RichTextBlock[];
  items: Array<{
    text?: RichTextBlock[];
    symbol?: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_text
      }

      fields {
        item_footnote
        item_symbol
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
  };
  fields: Array<{
    item_footnote?: RichTextBlock[];
    item_symbol?: string;
  }>;
};

export const parse = (slice: Props, redirects): ISmallPrint => ({
  type: SliceTypes.SmallPrint,
  sliceTitle: parseRichText(slice.primary.slice_title),
  text: parseRichText(slice.primary.slice_text, redirects),
  items: slice.fields.map((item) => ({
    text: parseRichText(item.item_footnote, redirects),
    symbol: item.item_symbol,
  })),
});
