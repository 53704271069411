import { RichText } from 'components/rich-text/RichText';
import { RichTextBlock } from 'prismic-reactjs';

export const parseString = (
  str: RichTextBlock[] | string,
  emptyIsNull: boolean = false
): string => {
  if (typeof str === 'string') return str ?? null;
  return RichText.asText(str, emptyIsNull);
};
