import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { PrismicImage } from '../utils/PrismicTypes';
import { parseImage, parseRichText, parseString } from '../utils/parsers';

export interface IPackages extends IBaseSlice {
  title: string;
  tagline: string;
  items: Array<{
    vnr: string;
    strength: string;
    size: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_tagline
      }
      fields {
        item_vnr
        item_strength
        item_size
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_tagline: RichTextBlock[];
  };
  fields: Array<{
    item_vnr: RichTextBlock[];
    item_strength: RichTextBlock[];
    item_size: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IPackages => ({
  type: SliceTypes.Packages,
  title: parseString(slice.primary.slice_title),
  tagline: parseString(slice.primary.slice_tagline),
  items: slice.fields.map((field) => ({
    vnr: parseString(field.item_vnr),
    strength: parseString(field.item_strength),
    size: parseString(field.item_size),
  })),
});
