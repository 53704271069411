import React from 'react';

import s from './Faq.module.scss';

type Props = {
  children: React.ReactNode;
  title?: string;
  isSlice?: boolean;
};

export const Faq = ({ children, title, isSlice }: Props) => {
  const content = <dl className={s.faq__list}>{children}</dl>;

  const container = (
    <div className={s.faq__segment}>
      <div className={s.faq__container}>
        <div className={s.faq__row}>
          <div className={s.faq__col}>
            {title && (
              <div className={s.faq__header}>
                <h2 className={s.faq__title}>{title}</h2>
              </div>
            )}
            {content}
          </div>
        </div>
      </div>
    </div>
  );

  return <div className={s.faq}>{isSlice ? container : content}</div>;
};
