import React from 'react';

import s from './Faq.module.scss';

type Props = {
  question?: React.ReactNode;
  answer?: React.ReactNode;
};

export const FaqItem = ({ question, answer }: Props) => {
  return (
    <>
      <dt className={s.faq__question}>{question}</dt>
      <dd className={s.faq__answer}>{answer}</dd>
    </>
  );
};
