import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import debounce from 'lodash/debounce';

import { ILink } from 'utils/types';
import { JobListing } from 'components/job-listing/JobListing';
import { JobListingItem } from 'components/job-listing/JobListingItem';
import { RichText } from 'components/rich-text/RichText';

type Props = {
  tagline: string;
  title: string;
  jobs: Array<{
    tagline: string;
    title: string;
    summary: RichTextBlock[];
    location: string;
    text: RichTextBlock[];
    link: ILink;
    detailsLink?: ILink;
  }>;
  [rest: string]: any;
};

export const JobListingContainer = ({ tagline, title, jobs, ...rest }: Props) => {
  const [filteredJobs, setFilteredJobs] = React.useState(jobs);
  const [selectedGroup, setSelectedGroup] = React.useState('All');
  const [searchQuery, setSearchQuery] = React.useState('');

  React.useEffect(() => {
    const updatedFilteredJobs = jobs
      .filter((job) => {
        // filter out locations
        if (selectedGroup === 'All') {
          return true;
        }
        return job.location === selectedGroup;
      })
      .filter((job) => {
        // search in jobs
        if (searchQuery === '') {
          return true;
        }

        const { title = '', tagline = '', summary = '', text } = job;
        const values = [
          title.toLowerCase(),
          tagline.toLowerCase(),
          RichText.asText(summary || []).toLowerCase(),
          RichText.asText(text || []).toLowerCase(),
        ];

        return values.some((val: string) => {
          return val.indexOf(searchQuery.trim()) !== -1;
        });
      });

    setFilteredJobs(updatedFilteredJobs);
  }, [jobs, selectedGroup, searchQuery]);

  const handleSearchChange = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  const handleGroupChange = (group: string) => {
    setSelectedGroup(group);
  };

  const groups = jobs.map((job) => job.location).filter((x, i, a) => a.indexOf(x) == i);

  return (
    <JobListing
      tagline={tagline}
      title={title}
      groups={groups}
      onChange={handleGroupChange}
      search={searchQuery}
      onSearch={debounce(handleSearchChange, 200)}
      selectedGroup={selectedGroup}
      {...rest}
    >
      {filteredJobs?.map((job, index) => {
        return (
          <JobListingItem
            key={index}
            tagline={job.tagline || 'tagline'}
            title={job.title || 'title'}
            summary={<RichText render={job.summary} />}
            link={job.detailsLink}
          />
        );
      })}
      {filteredJobs.length === 0 && (
        <p>
          No jobs found for "{searchQuery}"{' '}
          {selectedGroup !== 'All' && <> in group "{selectedGroup}"</>}
        </p>
      )}
    </JobListing>
  );
};
