import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage, ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { fetchAllBasicProducts, parseBasicProducts } from '../basicProduct';
import { parseRichText, parseString } from '../utils/parsers';

export interface ICatalogBasic extends IBaseSlice {
  tagline: string;
  title: string;
  text: RichTextBlock[];
  tags: string;
  categories: string;
  showAll: string;
  items: Array<{
    title: string;
    intro: string;
    tags: string[];
    images: Array<{
      image: IImage;
    }>;
    detailsLink?: ILink;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
        slice_tags
        slice_categories
        slice_all
      }
    }
  }
`;

type NestedProps = {
  lang: string;
  limit?: number;
  previewData: boolean;
  redirects: Array<{ from: string; to: string }>;
};

export const fetchBasicProductsAndParse = async ({
  lang,
  limit,
  previewData,
  redirects = [],
}: NestedProps) => {
  const items = await fetchAllBasicProducts(lang, '', previewData, (limit = 500));
  return parseBasicProducts(items, redirects);
};

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_description: RichTextBlock[];
    slice_tags: RichTextBlock[];
    slice_categories: RichTextBlock[];
    slice_all: RichTextBlock[];
  };
};

export const parse = (slice: Props, redirects?): ICatalogBasic => ({
  type: SliceTypes.CatalogBasic,
  tagline: parseString(slice.primary.slice_tagline),
  title: parseString(slice.primary.slice_title),
  text: parseRichText(slice.primary.slice_description, redirects),
  tags: parseString(slice.primary.slice_tags),
  categories: parseString(slice.primary.slice_categories),
  showAll: parseString(slice.primary.slice_all),
  items: [],
});
