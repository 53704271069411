import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '.';
import { RichTextBlock } from 'prismic-reactjs';
import { parseLink, parseRichText, parseString, parseImage } from '../utils/parsers';
import { PrismicLink, PrismicImage } from '../utils/PrismicTypes';
import { ILink, IImage } from 'utils/types';

export interface IHeroButtons extends IBaseSlice {
  tagline: string;
  sliceTitle: RichTextBlock[];
  text: RichTextBlock[];
  image: IImage;
  caption: RichTextBlock[];
  items: Array<{
    link?: ILink;
    label?: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
        slice_image
        slice_caption
      }
      fields {
        item_label
        item_link {
          ...Link
        }
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_image: PrismicImage;
    slice_caption: RichTextBlock[];
  };
  fields: Array<{
    item_link?: PrismicLink;
    item_label?: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IHeroButtons => ({
  type: SliceTypes.HeroButtons,
  tagline: parseString(slice.primary.slice_tagline),
  sliceTitle: parseRichText(slice.primary.slice_title),
  text: parseRichText(slice.primary.slice_text),
  image: parseImage(slice.primary.slice_image),
  caption: parseRichText(slice.primary.slice_caption),
  items: slice.fields.map((field) => ({
    link: parseLink(field.item_link),
    label: parseString(field.item_label),
  })),
});
