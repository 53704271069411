import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '.';
import { RichTextBlock } from 'prismic-reactjs';
import { parseImage, parseLink, parseRichText, parseString } from '../utils/parsers';
import { PrismicImage, PrismicLink } from '../utils/PrismicTypes';
import { IImage, ILink } from 'utils/types';

export interface IPortal extends IBaseSlice {
  sliceTitle: RichTextBlock[];
  tagline: string;
  text: RichTextBlock[];
  items: Array<{
    title: string;
    text: RichTextBlock[];
    image?: IImage;
    link?: ILink;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
      }
      fields {
        item_title
        item_text
        item_link {
          ...Link
        }
        item_image
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
  };
  fields: Array<{
    item_title: RichTextBlock[];
    item_text: RichTextBlock[];
    item_image?: PrismicImage;
    item_link?: PrismicLink;
    item_label?: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IPortal => ({
  type: SliceTypes.Portal,
  sliceTitle: parseRichText(slice.primary.slice_title),
  tagline: parseString(slice.primary.slice_tagline),
  text: parseRichText(slice.primary.slice_text),
  items: slice.fields.map((field) => ({
    title: parseString(field.item_title),
    text: parseRichText(field.item_text, redirects),
    link: parseLink(field.item_link),
    linkLabel: parseString(field.item_label),
    image: parseImage(field.item_image),
  })),
});
