import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '.';
import { parseString } from '../utils/parsers';

export interface IStats extends IBaseSlice {
  tagline: string;
  items: Array<{
    number: string;
    label: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
      }
      fields {
        item_number
        item_label
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
  };
  fields: Array<{
    item_number: RichTextBlock[];
    item_label: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IStats => ({
  type: SliceTypes.Stats,
  tagline: parseString(slice.primary.slice_tagline),
  items: slice.fields.map((field) => ({
    number: parseString(field.item_number),
    label: parseString(field.item_label),
  })),
});
