import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '.';
import { PrismicImage, PrismicLink } from '../utils/PrismicTypes';
import { parseString, parseImage, parseLink, parseRichText } from '../utils/parsers';
import { IImage, ILink } from 'utils/types';

export interface IRetailers extends IBaseSlice {
  title: string;
  tagline: string;
  text: RichTextBlock[];
  hasText: boolean;
  items: Array<{
    image: IImage;
    title: string;
    link: ILink;
    label: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_tagline
        slice_text
      }
      fields {
        item_image
        item_title
        item_link { ...Link }
        item_label
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_tagline: RichTextBlock[];
    slice_text: RichTextBlock[];
  };
  fields: Array<{
    item_image: PrismicImage;
    item_title: RichTextBlock[];
    item_link: PrismicLink;
    item_label: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IRetailers => ({
  type: SliceTypes.Retailers,
  title: parseString(slice.primary.slice_title),
  tagline: parseString(slice.primary.slice_tagline, redirects),
  text: parseRichText(slice.primary.slice_text, redirects),
  hasText: parseString(slice.primary.slice_text, redirects)?.length > 0 || false,
  items: slice.fields.map((field) => ({
    image: parseImage(field.item_image),
    title: parseString(field.item_title),
    link: parseLink(field.item_link, redirects),
    label: parseString(field.item_label),
  })),
});
