import React from 'react';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import xor from 'lodash/xor';
import { Catalog, FilterTypes } from 'components/catalog/Catalog';
import { Sales } from 'components/sales/Sales';

type Props = {
  title?: React.ReactNode;
  tagline?: React.ReactNode;
  caption?: React.ReactNode;
  figures: Array<{
    title: string;
    months: Array<{
      month: string;
      standalone: string;
      subsidiaries: string;
      consolidated: string;
      yoyChange: string;
      ytd: string;
      yoyTotal: string;
    }>;
  }>;
};

export const SalesContainer: React.FC<Props> = ({
  title,
  tagline,
  caption,
  figures,
  ...rest /* slice props */
}) => {
  const { i18n } = useLingui();
  const [currentOption, setOption] = React.useState<FilterTypes>(figures[0]?.title || '');
  const [symbols, setSymbol] = React.useState<string[]>([]);
  const [term, setSearchTerm] = React.useState<string>('');

  const onSymbolToggled = (s) => {
    setSymbol(xor(symbols, [s]) /* Symmetric difference */);
  };

  const months = {
    January: i18n._(t`January`),
    February: i18n._(t`February`),
    March: i18n._(t`March`),
    April: i18n._(t`April`),
    May: i18n._(t`May`),
    June: i18n._(t`June`),
    July: i18n._(t`July`),
    August: i18n._(t`August`),
    September: i18n._(t`September`),
    October: i18n._(t`October`),
    November: i18n._(t`November`),
    December: i18n._(t`December`),
  };

  const columns = React.useMemo(
    () => [
      {
        id: 'month',
        Header: i18n._(t`Month`),
        accessor: 'month',
        Cell: ({ row }) => {
          return row.original.month;
        },
      },
      {
        id: 'standalone',
        Header: i18n._(t`Lotus Standalone`),
        accessor: 'standalone',
        Cell: ({ row }) => {
          return row.original.standalone;
        },
      },
      {
        id: 'subsidiaries',
        Header: i18n._(t`Subsidiaries`),
        accessor: 'subsidiaries',
        Cell: ({ row }) => {
          return row.original.subsidiaries;
        },
      },
      {
        id: 'consolidated',
        Header: i18n._(t`Consolidated`),
        accessor: 'consolidated',
        Cell: ({ row }) => {
          return row.original.consolidated;
        },
      },
      {
        id: 'yoyChange',
        Header: i18n._(t`YOY Change (%)`),
        accessor: 'yoyChange',
        Cell: ({ row }) => {
          return row.original.yoyChange;
        },
      },
      {
        id: 'ytd',
        Header: i18n._(t`YTD`),
        accessor: 'ytd',
        Cell: ({ row }) => {
          return row.original.ytd;
        },
      },
      {
        id: 'yoyTotal',
        Header: i18n._(t`YOY Change (%)`),
        accessor: 'yoyTotal',
        Cell: ({ row }) => {
          return row.original.yoyTotal;
        },
      },
    ],
    []
  );

  // Return selected year
  const filteredCatalog = figures
    .filter((year) => year.title === currentOption || year[0])
    .map((year) => year.months);

  const data = React.useMemo(
    () =>
      filteredCatalog[0]?.map((month) => ({
        month: months[month.month],
        standalone: month.standalone,
        subsidiaries: month.subsidiaries,
        consolidated: month.consolidated,
        yoyChange: month.yoyChange,
        ytd: month.ytd,
        yoyTotal: month.yoyTotal,
      })) || [],
    [filteredCatalog, columns]
  );

  // return all years with months
  const years = figures.filter((year) => year.months.length > 0).map((data) => data.title);

  return (
    <Catalog
      tagline={tagline}
      title={title}
      option={currentOption}
      symbols={symbols}
      term={term}
      onOptionChange={setOption}
      onSymbolToggled={onSymbolToggled}
      onTermChanged={setSearchTerm}
      filters={years}
      alphabetFilter={false}
      searchFilter={false}
      {...rest}
    >
      <Sales columns={columns} data={data} caption={caption} />
    </Catalog>
  );
};
