import React from 'react';
import { Tagline } from 'components/tagline/Tagline';
import { Item } from './Item';

import s from './Retailers.module.scss';

type Props = {
  tagline?: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  listItems?: React.ReactNode;
  children?: React.ReactNode;
};

interface IRetailers extends React.FC<Props> {
  Item: typeof Item;
}

const Retailers: IRetailers = function Retailers({ tagline, title, text, listItems, children }) {
  const hasChildren = React.Children.toArray(children)?.length > 0;

  return (
    <section className={s.retailers}>
      <div className={s.retailers__container}>
        <div className={s.retailers__layout}>
          <header className={s.retailers__header}>
            <div className={s.retailers__headlines}>
              <Tagline text={tagline} />
              <h2 className={s.retailers__title}>{title}</h2>
            </div>
            {text && <div className={s.retailers__text}>{text}</div>}
          </header>
          {hasChildren && <div className={s.retailers__grid}>{children}</div>}
        </div>
      </div>
    </section>
  );
};

Retailers.Item = Item;

export { Retailers };
