import React from 'react';
import classnames from 'classnames';
import Image from 'next/image';

import { IImage, ILink } from 'utils/types';
import { Transition } from 'components/transition/Transition';
import { Link } from 'components/link/Link';
import { TextButton } from 'components/button/TextButton';

import s from './Articles.module.scss';

type Props = {
  title?: string;
  text?: string;
  date?: string;
  tagline?: string;
  link: ILink;
  image?: IImage;
  featured?: boolean;
  display?: string;
};

export const ArticlesItem = ({
  title,
  text,
  date,
  tagline,
  link,
  image,
  featured,
  display,
}: Props) => {
  const truncate = (str, wordCount) => {
    const currentCount = str.split(' ').length;

    if (currentCount >= wordCount) {
      return str.split(' ').splice(0, wordCount).join(' ') + '...';
    }

    return str;
  };

  const copy = text && typeof text === 'string' && truncate(text, 30);

  const main = (
    <Transition>
      <div className={s.item__layout}>
        {image && (
          <div className={s.item__image}>
            <div className={s.item__aspect} />
            {featured && (
              <Image
                className={s.item__img}
                src={image.shaped?.url ?? image.url}
                layout="fill"
                alt={image.alt || ''}
              />
            )}
            {!featured && (
              <Image
                className={s.item__img}
                src={image.shaped?.url ?? image.thumbnail?.url ?? image.url}
                layout="fill"
                alt={image.alt || ''}
              />
            )}
          </div>
        )}
        <div className={s.item__text}>
          <div className={s.item__header}>
            {tagline && <div className={s.item__tagline}>{tagline}</div>}
            {date && <div className={s.item__date}>{date}</div>}
          </div>
          {title && (
            <h3 className={s.item__title}>
              {display === 'col-1' ? (
                <Link className={s.item__titleLink} {...link}>
                  {title}
                </Link>
              ) : (
                title
              )}
            </h3>
          )}
          {((featured && text && typeof text === 'string') ||
            (text && typeof text === 'string' && display === 'col-1')) && (
            <div className={s.item__copy}>
              <p>{copy}</p>
            </div>
          )}
          {display === 'col-1' && <TextButton {...link}>Read more</TextButton>}
        </div>
      </div>
    </Transition>
  );

  if (display === 'col-1') {
    return <li className={classnames(s.item, s[display], { [s.featured]: featured })}>{main}</li>;
  }

  return (
    <li className={classnames(s.item, s[display], { [s.featured]: featured })}>
      <Link className={s.item__link} {...link}>
        {main}
      </Link>
    </li>
  );
};
