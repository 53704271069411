import React from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'components/link/Link';
import { ILink } from 'utils/types';

import s from './Quarters.module.scss';

type Props = {
  title?: React.ReactNode;
  consolidated?: ILink;
  press?: ILink;
  earnings?: ILink;
  webcast?: ILink;
};

export const Quarter = ({ title, consolidated, press, earnings, webcast }: Props) => {
  return (
    <div className={s.quarter}>
      <h3 className={s.quarter__title}>{title}</h3>
      <ul className={s.quarter__list}>
        {consolidated && (
          <li className={s.quarter__item}>
            <Link {...consolidated} className={s.quarter__link}>
              <Trans>consolidated</Trans>
            </Link>
          </li>
        )}
        {press && (
          <li className={s.quarter__item}>
            <Link {...press} className={s.quarter__link}>
              <Trans>press</Trans>
            </Link>
          </li>
        )}
        {earnings && (
          <li className={s.quarter__item}>
            <Link {...earnings} className={s.quarter__link}>
              <Trans>earnings</Trans>
            </Link>
          </li>
        )}
        {webcast && (
          <li className={s.quarter__item}>
            <Link {...webcast} className={s.quarter__link}>
              <Trans>webcast</Trans>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};
