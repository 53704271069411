import gql from 'graphql-tag';
import { print } from 'graphql';
import { config } from 'utils/config';
import { fetchAPI } from '..';

import * as Hero from './hero';
import * as Articles from './articles';
import * as StaggeredBlocks from './staggeredBlocks';
import * as Gallery from './gallery';
import * as GalleryBlock from './galleryBlock';
import * as ImageBlock from './imageBlock';
import * as ItemList from './itemList';
import * as Cta from './cta';
import * as Text from './text';
import * as TextBlock from './textBlock';
import * as People from './people';
import * as Documents from './documents';
import * as SplitPageSection from './splitPageSection';
import * as Timeline from './timeline';
import * as ColumnSection from './columnSection';
import * as Infographic from './infographic';
import * as Infographics from './infographics';
import * as Quote from './quote';
import * as Buttons from './buttons';
import * as Offices from './offices';
import * as Form from './form';
import * as ContactBlock from './contactBlock';
import * as NewsletterSignup from './newsletterSignup';
import * as MediaList from './mediaList';
import * as Divider from './divider';
import * as CookiePolicy from './cookiePolicy';
import * as Variant from './variant';
import * as Stories from './stories';
import * as Faq from './faq';
import * as Video from './video';
import * as VideoList from './videoList';
import * as JobListing from './jobListing';
import * as GalleryGrid from './galleryGrid';
import * as Stats from './stats';
import * as Table from './table';
import * as Events from './events';
// import * as Data from './data';
import * as IndexGrid from './indexGrid';
import * as Catalog from './catalog';
import * as CatalogAlmaject from './catalogAlmaject';
import * as CatalogBranded from './catalogBranded';
import * as CatalogAlmatica from './catalogAlmatica';
import * as CatalogIceland from './catalogIceland';
import * as CatalogSimple from './catalogSimple';
import * as CatalogBasic from './catalogBasic';
import * as Packages from './packages';
import * as Banner from './banner';
import * as Retailers from './retailers';
import * as Sales from './sales';
import * as Quarters from './quarters';
import * as Shareholder from './shareholder';
import * as ArticleIndex from './articleIndex';
import * as Pipeline from './pipeline';
import * as Related from './related';
import * as Accordion from './accordion';
import * as Portal from './portal';
import * as QuoteBlock from './quoteBlock';
import * as HeroButtons from './heroButtons';
import * as SmallPrint from './smallPrint';

export enum PageTypes {
  Home = 'Home',
  Page = 'Page',
  SplitPage = 'Split_page',
  Article = 'Article',
  Newsroom = 'Newsroom',
  Product = 'Product',
  ProductIceland = 'Product_iceland',
  SimpleProduct = 'Simple_product',
  BasicProduct = 'Basic_product',
  Story = 'Story',
  Event = 'Event',
}

export type PageTypesWithSlices =
  | PageTypes.Home
  | PageTypes.Page
  | PageTypes.SplitPage
  | PageTypes.Article
  | PageTypes.Newsroom
  | PageTypes.Product
  | PageTypes.ProductIceland
  | PageTypes.BasicProduct
  | PageTypes.Story
  | PageTypes.Event;

export enum SliceTypes {
  Articles = 'articles',
  Buttons = 'buttons',
  ColumnSection = 'column_section',
  ContactBlock = 'contact_block',
  CookiePolicy = 'cookie_policy',
  Cta = 'cta',
  Divider = 'divider',
  Documents = 'documents',
  Faq = 'faq',
  Form = 'form',
  Gallery = 'gallery',
  GalleryBlock = 'gallery_block',
  GalleryGrid = 'gallery_grid',
  Hero = 'hero',
  ImageBlock = 'image_block',
  Infographic = 'infographic',
  Infographics = 'infographics',
  ItemList = 'item_list',
  JobListing = 'job_listing',
  MediaList = 'media_list',
  NewsletterSignup = 'newsletter_signup',
  Offices = 'offices',
  People = 'people',
  Quote = 'quote',
  SplitPageSection = 'split_page_section',
  StaggeredBlocks = 'content',
  Stories = 'stories',
  Text = 'text',
  TextBlock = 'text_block',
  Timeline = 'timeline',
  Variant = 'variant',
  Video = 'video',
  VideoList = 'video_list',
  Stats = 'stats',
  Table = 'table',
  Data = 'data',
  IndexGrid = 'product_index',
  Catalog = 'catalog',
  CatalogAlmaject = 'catalog_almaject',
  CatalogBranded = 'catalog_branded',
  CatalogAlmatica = 'catalog_almatica',
  CatalogIceland = 'catalog_iceland',
  CatalogSimple = 'catalog_simple',
  CatalogBasic = 'catalog_basic',
  Packages = 'packages',
  Banner = 'banner',
  Retailers = 'retailers',
  Sales = 'sales',
  Quarters = 'quarterly_reports',
  Shareholder = 'shareholder_information',
  ArticleIndex = 'article_index',
  Events = 'events',
  Pipeline = 'pipeline',
  Related = 'related',
  Accordion = 'accordion',
  Portal = 'portal',
  QuoteBlock = 'quote_block',
  HeroButtons = 'hero_buttons',
  SmallPrint = 'small_print',
}

export interface IBaseSlice {
  type: SliceTypes;
  title?: string;
}

const sliceDefinitions = {
  [SliceTypes.Articles]: Articles,
  [SliceTypes.Buttons]: Buttons,
  [SliceTypes.ColumnSection]: ColumnSection,
  [SliceTypes.ContactBlock]: ContactBlock,
  [SliceTypes.CookiePolicy]: CookiePolicy,
  [SliceTypes.Cta]: Cta,
  [SliceTypes.Divider]: Divider,
  [SliceTypes.Documents]: Documents,
  [SliceTypes.Faq]: Faq,
  [SliceTypes.Form]: Form,
  [SliceTypes.Gallery]: Gallery,
  [SliceTypes.GalleryBlock]: GalleryBlock,
  [SliceTypes.GalleryGrid]: GalleryGrid,
  [SliceTypes.Hero]: Hero,
  [SliceTypes.ImageBlock]: ImageBlock,
  [SliceTypes.Infographic]: Infographic,
  [SliceTypes.Infographics]: Infographics,
  [SliceTypes.ItemList]: ItemList,
  [SliceTypes.JobListing]: JobListing,
  [SliceTypes.MediaList]: MediaList,
  [SliceTypes.NewsletterSignup]: NewsletterSignup,
  [SliceTypes.Offices]: Offices,
  [SliceTypes.People]: People,
  [SliceTypes.Quote]: Quote,
  [SliceTypes.SplitPageSection]: SplitPageSection,
  [SliceTypes.StaggeredBlocks]: StaggeredBlocks,
  [SliceTypes.Stories]: Stories,
  [SliceTypes.Text]: Text,
  [SliceTypes.TextBlock]: TextBlock,
  [SliceTypes.Timeline]: Timeline,
  [SliceTypes.Variant]: Variant,
  [SliceTypes.Video]: Video,
  [SliceTypes.VideoList]: VideoList,
  [SliceTypes.Stats]: Stats,
  [SliceTypes.Table]: Table,
  // [SliceTypes.Data]: Data,
  [SliceTypes.Events]: Events,
  [SliceTypes.IndexGrid]: IndexGrid,
  [SliceTypes.Catalog]: Catalog,
  [SliceTypes.CatalogAlmaject]: CatalogAlmaject,
  [SliceTypes.CatalogAlmatica]: CatalogAlmatica,
  [SliceTypes.CatalogBranded]: CatalogBranded,
  [SliceTypes.CatalogIceland]: CatalogIceland,
  [SliceTypes.CatalogSimple]: CatalogSimple,
  [SliceTypes.CatalogBasic]: CatalogBasic,
  [SliceTypes.Packages]: Packages,
  [SliceTypes.Banner]: Banner,
  [SliceTypes.Retailers]: Retailers,
  [SliceTypes.Sales]: Sales,
  [SliceTypes.Quarters]: Quarters,
  [SliceTypes.Shareholder]: Shareholder,
  [SliceTypes.ArticleIndex]: ArticleIndex,
  [SliceTypes.Pipeline]: Pipeline,
  [SliceTypes.Related]: Related,
  [SliceTypes.Accordion]: Accordion,
  [SliceTypes.Portal]: Portal,
  [SliceTypes.QuoteBlock]: QuoteBlock,
  [SliceTypes.HeroButtons]: HeroButtons,
  [SliceTypes.SmallPrint]: SmallPrint,
};

const capitalizeFirstLetter = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const createFragments = ({ fragmentName, typeWithBody, availableSlices, type }) => {
  return gql`
    fragment ${fragmentName} on ${typeWithBody} {
      ... on ${typeWithBody} {
        ${availableSlices.map((slice: SliceTypes) => `...${capitalizeFirstLetter(slice) + type}`)}
      }
    }

    ${availableSlices
      .filter((slice: SliceTypes) => {
        return sliceDefinitions[slice] && sliceDefinitions[slice].fragment;
      })
      .map((slice: SliceTypes) => {
        let fragmentFunction = sliceDefinitions[slice]?.fragment;

        // Custom fragments
        if (PageTypes.Article == type && slice === SliceTypes.Video) {
          fragmentFunction = sliceDefinitions[slice]?.customArticleFragment;
        }

        if (PageTypes.Article == type && slice === SliceTypes.Documents) {
          fragmentFunction = sliceDefinitions[slice]?.customSplitPageFragment;
        }

        if (PageTypes.Page == type && slice === SliceTypes.Text) {
          fragmentFunction = sliceDefinitions[slice]?.customPageFragment;
        }

        if (PageTypes.SplitPage == type && slice === SliceTypes.Text) {
          fragmentFunction = sliceDefinitions[slice]?.customSplitPageFragment;
        }

        if (PageTypes.SplitPage == type && slice === SliceTypes.Documents) {
          fragmentFunction = sliceDefinitions[slice]?.customSplitPageFragment;
        }

        if (PageTypes.SplitPage == type && slice === SliceTypes.Form) {
          fragmentFunction = sliceDefinitions[slice]?.customSplitPageFragment;
        }

        return print(
          fragmentFunction(
            capitalizeFirstLetter(slice),
            typeWithBody,
            capitalizeFirstLetter(slice) + type
          )
        );
      })
      .join('')}
  `;
};

export const generateSliceFragments = async (
  type: PageTypesWithSlices,
  fragmentName: string = 'Slices'
) => {
  try {
    // Often the object containing the slices is named 'Body1' for the Home document type.
    // That's because the site’s navigation is usually set up with slices in the same document type.
    const body = 'Body';
    // const body = type === PageTypes.Home ? 'Body1' : 'Body';

    const typeWithBody = `${type}${body}`;
    const query = gql`
      {
        ${Object.keys(sliceDefinitions).map(
          (sliceType: SliceTypes) => `
          ${sliceType}: __type(name: "${type}${body}${capitalizeFirstLetter(sliceType)}") {
            name
          }
        `
        )}
      }
    `;
    const res = await fetchAPI(print(query));
    const availableSlices = Object.keys(res).filter((x) => res[x] !== null);

    // console.log('availableSlices', availableSlices);

    const out = createFragments({
      fragmentName,
      type,
      typeWithBody,
      availableSlices,
    });

    // console.log('out', print(out));

    return out;
  } catch (err) {
    console.log('err', err);
    return null;
  }
};

// TODO: always use generateSliceFragmentsPages, just need to cleanup the code a little bit
export const generateSliceFragmentsPages = async (
  type: PageTypesWithSlices,
  fragmentName: string = 'Slices'
) => {
  try {
    // Often the object containing the slices is named 'Body1' for the Home document type.
    // That's because the site’s navigation is usually set up with slices in the same document type.
    const body = 'Body';
    // const body = type === PageTypes.Home ? 'Body1' : 'Body';

    const typeWithBody = `${type}${body}`;
    const query = gql`
      {
        ${Object.keys(sliceDefinitions).map(
          (sliceType: SliceTypes) => `
          ${sliceType}: __type(name: "${type}${body}${capitalizeFirstLetter(sliceType)}") {
            name
          }
        `
        )}
      }
    `;
    const res = await fetchAPI(print(query));
    const availableSlices = Object.keys(res).filter((x) => res[x] !== null);

    const PAGE_SIZE = 20;

    const pages = new Array(Math.ceil(availableSlices.length / PAGE_SIZE))
      .fill(null)
      .map((_, i) => {
        const fragments = createFragments({
          fragmentName,
          type,
          typeWithBody,
          availableSlices: availableSlices.slice(i * PAGE_SIZE, i * PAGE_SIZE + PAGE_SIZE),
        });

        // console.log(slices);
        // console.log(print(fragments));

        return fragments;
      });

    return pages;
  } catch (err) {
    console.log('err', err);
    return null;
  }
};

const fetchAndParseNestedData = async (slice: any, lang, previewData, redirects) => {
  const defaultOptions = { lang, previewData, redirects, limit: null };

  switch (slice.type) {
    case SliceTypes.JobListing:
      return JobListing.fetchJobsAndParse(defaultOptions);
    case SliceTypes.Stories:
      return Stories.fetchStoriesAndParse(defaultOptions);
    case SliceTypes.CatalogBasic:
      return CatalogBasic.fetchBasicProductsAndParse(defaultOptions);
    case SliceTypes.CatalogIceland:
      return CatalogIceland.fetchProductsIcelandAndParse(defaultOptions);
    case SliceTypes.CatalogSimple:
      return CatalogSimple.fetchSimpleProductsAndParse(defaultOptions);
    case SliceTypes.Form:
      return Form.fetchForm(slice);
    case SliceTypes.Catalog:
      return Catalog.fetchProductsAndParse(defaultOptions);
    case SliceTypes.CatalogBranded:
      return Catalog.fetchProductsAndParse(defaultOptions);
    case SliceTypes.CatalogAlmaject:
      return CatalogAlmaject.fetchProductsAndParse(defaultOptions);
    case SliceTypes.IndexGrid:
      return IndexGrid.fetchBasicProductsAndParse({
        ...defaultOptions,
        limit: 500,
        tags: slice.categories || undefined,
      });
    case SliceTypes.Sales:
      return Sales.fetchInvestorInformationsAndParse(defaultOptions);
    case SliceTypes.Quarters:
      return Quarters.fetchInvestorInformationsAndParse(defaultOptions);
    case SliceTypes.Shareholder:
      return Shareholder.fetchInvestorInformationsAndParse(defaultOptions);
    case SliceTypes.Articles:
      return Articles.fetchArticlesAndParse({
        ...defaultOptions,
        limit: 3,
        tags: slice.queryAll ? slice.tags : undefined,
        tagsIn: !slice.queryAll ? slice.tags : undefined,
      });
    case SliceTypes.ArticleIndex:
      return ArticleIndex.fetchArticlesAndParse({
        ...defaultOptions,
        tags: slice.queryAll ? slice.tags : undefined,
        tagsIn: !slice.queryAll ? slice.tags : undefined,
        lang,
      });
    case SliceTypes.Events:
      return Events.fetchEventsAndParse(defaultOptions);
    default:
      return null;
  }
};

export const parseSlices = async (
  slices: Array<{
    type: string;
    primary?: any;
    fields?: any[];
  }>,
  lang: string,
  previewData: any,
  redirects?: Array<{ from: string; to: string }>
): Promise<IBaseSlice[]> => {
  const slicesData = slices
    .filter((slice) => slice.type) // prismic graphql somtimes returns empty slice objects
    .map((slice) => {
      const sliceType = sliceDefinitions[slice.type];
      if (!sliceType) {
        console.warn(`No definition found for slice '${slice.type}'`);
        return { type: slice.type };
      }
      return sliceType.parse(slice, redirects || [], lang);
    });

  // Check if we need to fetch nested data
  return await Promise.all(
    slicesData.map(async (slice) => {
      const items = await fetchAndParseNestedData(slice, lang, previewData, redirects);

      return {
        ...slice,
        ...(items && { items }),
      };
    })
  );
};
