import React from 'react';
import Image from 'next/image';
import { Trans, t } from '@lingui/macro';
import { setQueryParameters } from 'utils/setQueryParameters';
import { IImage, ILink } from 'utils/types';
import { Link } from 'components/link/Link';
import { ChevronRight } from 'components/assets';

import s from './IndexGrid.module.scss';

type Props = {
  link?: ILink;
  image?: IImage;
  title?: React.ReactNode;
  text?: React.ReactNode;
  note?: React.ReactNode;
  label?: React.ReactNode;
};

export const Item = ({ link, image, title, text, note, label }: Props) => {
  if (!link || !link.href) {
    return null;
  }

  return (
    <article className={s.item}>
      <Link {...link} className={s.item__link}>
        <div className={s.item__layout}>
          <div className={s.item__image}>
            <div className={s.item__aspect}>
              {image && image.url && (
                <Image src={image.url} layout="fill" objectFit="contain" sizes="640px" />
              )}
            </div>
          </div>
          <div className={s.item__content}>
            <h3 className={s.item__title}>{title}</h3>
            <div className={s.item__copy}>{text}</div>
            {note && <div className={s.item__note}>{note}</div>}
          </div>
          <div className={s.item__button}>
            {label || <Trans>More</Trans>} <ChevronRight />
          </div>
        </div>
      </Link>
    </article>
  );
};
