import React from 'react';
import classnames from 'classnames';
import { t, Trans } from '@lingui/macro';
import {
  Cell,
  Column,
  TableOptions,
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
} from 'react-table';
import { Button } from 'components/button/Button';
import { TextButton } from 'components/button/TextButton';
import { ChevronRight } from 'components/assets';

import s from './TableList.module.scss';

type Props = {
  columns?: Column<{}>[];
  data?: {}[];
};

export const TableList: React.FC<Props> = ({ columns, data }) => {
  const [alert, setAlert] = React.useState(false);
  const [active, setActive] = React.useState('');
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  if (data?.length === 0) {
    return (
      <div className={s.tableList}>
        <div className={s.tableList__empty}>
          <Trans>No matching products found</Trans>
        </div>
      </div>
    );
  }

  return (
    <div className={s.tableList}>
      <table className={s.tableList__table} {...getTableProps()}>
        <thead className={s.tableList__thead}>
          {headerGroups.map((headerGroup) => (
            <tr className={s.tableList__header} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={classnames(s.tableList__label, {
                    [s.sorted]: column.isSorted,
                    [s.descending]: column.isSortedDesc,
                  })}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span className={s.tableList__indicator}>
                    <ChevronRight />
                  </span>
                </th>
              ))}
              <th></th>
            </tr>
          ))}
        </thead>
        <tbody className={s.tableList__tbody} {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                className={classnames(s.item, { [s.open]: active === row.cells[0].value })}
                {...row.getRowProps()}
              >
                <td className={s.item__wrap}>
                  <div
                    className={s.item__header}
                    onClick={() =>
                      setActive(active === row.cells[0].value ? '' : row.cells[0].value)
                    }
                  >
                    {row.cells.map((cell, ii) => {
                      return (
                        <div
                          className={classnames(s.item__value, { [s.name]: ii === 0 })}
                          data-label={cell.column.Header}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </div>
                      );
                    })}
                    <div className={s.item__value}>
                      <span className={s.item__status}>
                        <ChevronRight />
                      </span>
                    </div>
                  </div>
                  <div className={s.item__body}>
                    {row.original.variants && (
                      <table className={s.variants}>
                        <thead>
                          <tr>
                            <th className={s.variants__header}>
                              <Trans>Serial number</Trans>
                            </th>
                            <th className={s.variants__header}>
                              <Trans>Strength</Trans>
                            </th>
                            <th className={s.variants__header}>
                              <Trans>Size</Trans>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {row.original.variants?.map((variant) => (
                            <tr key={variant.vnr} className={s.variants__item}>
                              <td className={s.variants__value}>{variant.vnr}</td>
                              <td className={s.variants__value}>{variant.strength}</td>
                              <td className={s.variants__value}>{variant.size}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    <div className={s.item__footer}>
                      <div className={s.item__external}>
                        {row.original.manual && (
                          <TextButton
                            href={row.original.manual.href}
                            target={row.original.manual.target}
                            tabIndex={active ? 0 : -1}
                          >
                            <Trans>Manual</Trans>
                          </TextButton>
                        )}
                        {row.original.smpc && (
                          <TextButton
                            href={row.original.smpc.href}
                            target={row.original.smpc.target}
                            tabIndex={active ? 0 : -1}
                          >
                            <Trans>SMPC</Trans>
                          </TextButton>
                        )}
                      </div>
                      {row.original.detailsLink && (
                        <div className={s.item__details}>
                          <Button
                            color="dark"
                            small
                            link={row.original.detailsLink}
                            tabIndex={active ? 0 : -1}
                            aria-label="Product details"
                          >
                            <Trans>Details</Trans>
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
