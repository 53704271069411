import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IEmbed, IImage } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { PrismicEmbed, PrismicImage } from '../utils/PrismicTypes';
import { parseEmbed, parseImage, parseRichText, parseString } from '../utils/parsers';

export interface IVideoList extends IBaseSlice {
  tagline: string;
  title: string;
  text: RichTextBlock[];
  items: Array<{
    title: string;
    image: IImage;
    embed: IEmbed;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
      }
      fields {
        item_title
        item_image
        item_embed
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
  };
  fields: Array<{
    item_title: RichTextBlock[];
    item_image: PrismicImage;
    item_embed: PrismicEmbed;
  }>;
};

export const parse = (slice: Props): IVideoList => ({
  type: SliceTypes.VideoList,
  tagline: parseString(slice.primary.slice_tagline),
  title: parseString(slice.primary.slice_title),
  text: parseRichText(slice.primary.slice_text),
  items: slice.fields.map((field) => ({
    title: parseString(field.item_title),
    image: parseImage(field.item_image),
    embed: parseEmbed(field.item_embed),
  })),
});
