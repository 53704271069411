import React from 'react';
import { Formik, Form, FormikActions } from 'formik';
import * as Yup from 'yup';
import { Trans, t } from '@lingui/macro';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { Button } from 'components/button/Button';
import { Text } from 'components/text/Text';
import { buildValidationSchema, getInitialValues } from 'utils/wufoo';
import { FieldContainer } from './Field';
import { config } from 'utils/config';
import Recaptcha from 'react-google-recaptcha';

import { Checkbox } from 'components/form/Checkbox';
import { useUI } from 'utils/ui';
import { IField } from 'providers/wufoo/types';
import { submit } from 'providers/wufoo/submit';

type Props = {
  form: any;
  messageTitle: string;
  message: React.ReactNode;
  ageLimit?: boolean;
  showShareholderCheck?: boolean;
};

export const GenericForm = ({
  form,
  ageLimit = true,
  showShareholderCheck = false,
  message,
  messageTitle,
}: Props) => {
  const ui = useUI();
  const schema = buildValidationSchema(form.fields).shape({
    gdpr1: Yup.boolean().oneOf([true], 'Required'),
    gdpr2: ageLimit ? Yup.boolean().oneOf([true], 'Required') : Yup.boolean(),
  });
  const values = { ...getInitialValues(form.fields), gdpr1: false, gdpr2: false };
  const [state, setState] = React.useState('');
  const [captchaValue, setCaptchaValue] = React.useState<string | null>(null);

  const onSubmit = async (values: any, formikActions: FormikActions<any>) => {
    const fields = form.fields
      .map((f: IField) => {
        if (f.type === 'checkbox') {
          return (f as any).choices.map((c) => ({
            id: c.id,
            value: values[c.id] ? c.label : '',
          }));
        }

        return {
          id: f.id,
          value: values[f.id],
        };
      })
      .flat()
      .filter((f) => f.value?.trim() !== '')
      .reduce((acc, f) => ({ ...acc, [f.id]: f.value }), {});

    if (captchaValue || ui.isTest) {
      try {
        // await form.submit({ ...values, 'g-recaptcha-response': captchaValue }, formikActions);
        await submit(form.id, { ...fields, 'g-recaptcha-response': captchaValue });

        setState('sent');
      } catch (err) {
        setState('error');
        console.error(err);
        throw err;
      }
    }
  };
  const hasTitle = messageTitle && messageTitle !== '';

  return (
    <BackgroundSection>
      {state === '' && (
        <Formik initialValues={values} validationSchema={schema} onSubmit={onSubmit}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <Form>
                {form.fields.map((f: IField) => (
                  <FieldContainer
                    key={f.id}
                    field={f}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    required={f.isRequired}
                    errors={errors}
                    touched={touched}
                  />
                ))}

                <Checkbox
                  id="gdpr1"
                  onChange={handleChange}
                  // required
                  touched={touched.gdpr1}
                  error={errors.gdpr1}
                >
                  <Trans>By submitting this form, I agree to</Trans>{' '}
                  <a href="/privacy-policy" target="_blank">
                    {config.theme === 'alvotech' && <Trans>Alvotech's Privacy notice</Trans>}
                    {config.theme === 'alvogen' && <Trans>Alvogen's Privacy notice</Trans>}
                    {config.theme === 'lotus' && <Trans>Lotus' Privacy notice</Trans>}
                    {config.theme === 'adalvo' && <Trans>Adalvo' Privacy notice</Trans>}
                    {config.theme === 'almatica' && <Trans>Almatica' Privacy notice</Trans>}
                    {config.theme === 'almaject' && <Trans>Almaject' Privacy notice</Trans>}
                  </a>
                </Checkbox>

                {ageLimit && (
                  <Checkbox
                    id="gdpr2"
                    onChange={handleChange}
                    // required
                    touched={touched.gdpr2}
                    error={errors.gdpr2}
                  >
                    <Trans>
                      Please check here to confirm that you are 16-years of age or older
                    </Trans>
                  </Checkbox>
                )}
                {showShareholderCheck && (
                  <Checkbox id="shareholder" onChange={handleChange}>
                    <Trans></Trans>
                  </Checkbox>
                )}

                {!ui.isTest && (
                  <Recaptcha
                    style={{ margin: '6rem 0' }}
                    sitekey={config.captchaSiteKey}
                    onChange={setCaptchaValue}
                    required
                  />
                )}

                <Button type="submit" disabled={isSubmitting}>
                  <Trans>Send</Trans>
                </Button>
              </Form>
            );
          }}
        </Formik>
      )}

      {state === 'sent' && (
        <Text
          isSplitPage
          // tagline={tagline}
          title={hasTitle ? messageTitle : undefined}
          text={<>{message}</>}
        />
      )}

      {state === 'error' && (
        <Text
          isSplitPage
          // tagline={tagline}
          title="Whoops"
          text={
            <div>
              <p>
                <Trans>Something went wrong.</Trans>
              </p>
            </div>
          }
        />
      )}
    </BackgroundSection>
  );
};
