import React from 'react';
import classnames from 'classnames';
import { t, Trans } from '@lingui/macro';
import {
  Cell,
  Column,
  TableOptions,
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
} from 'react-table';
import { ChevronRight } from 'components/assets';

import s from './SimpleList.module.scss';

type Props = {
  columns?: Column<{}>[];
  data?: {}[];
};

export const SimpleList: React.FC<Props> = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
          // {
          //   id: 'ingredients',
          //   desc: false,
          // },
        ],
      },
    },
    useSortBy
  );

  React.useEffect(() => {
    setHiddenColumns(allColumns.filter((col) => col.hidden).map((col) => col.id));
  }, [allColumns]);

  if (data?.length === 0) {
    return (
      <div className={s.simpleList}>
        <div className={s.simpleList__empty}>
          <Trans>No matching products found</Trans>
        </div>
      </div>
    );
  }

  return (
    <div className={s.simpleList}>
      <table className={s.simpleList__table} {...getTableProps()}>
        <thead className={s.simpleList__thead}>
          {headerGroups.map((headerGroup) => (
            <tr className={s.simpleList__header} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                if (column.id === 'downloads') {
                  return;
                }
                return (
                  <th
                    className={classnames(s.simpleList__label, {
                      [s.sorted]: column.isSorted,
                      [s.descending]: column.isSortedDesc,
                    })}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <span className={s.simpleList__labelLayout}>
                      {column.render('Header')}
                      <span className={s.simpleList__indicator}>
                        <ChevronRight />
                      </span>
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className={s.simpleList__tbody} {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr className={s.simpleList__item} {...row.getRowProps()}>
                  {row.cells.map((cell, ii) => {
                    if (cell.column.id === 'downloads') {
                      return;
                    }
                    return (
                      <td
                        className={classnames(s.simpleList__value, { [s.name]: ii === 0 })}
                        data-label={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.cells.map((cell, ii) => {
                  // console.log(cell.column.id === 'downloads' && cell.value[0]?.link);
                  if (cell.column.id === 'downloads' && cell.value[0]?.link) {
                    return (
                      <tr className={s.simpleList__links}>
                        <td
                          className={classnames(s.simpleList__value, { [s.name]: ii === 0 })}
                          data-label={cell.column.Header}
                          {...cell.getCellProps()}
                          colSpan={row.cells.length - 1}
                        >
                          {cell.render('Cell')}
                        </td>
                      </tr>
                    );
                  }
                })}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
