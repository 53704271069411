import React from 'react';

import s from './Copy.module.scss';

type Props = {
  children: React.ReactNode;
};

export const Copy = ({ children }: Props) => {
  return <div className={s.copy}>{children}</div>;
};
