import React from 'react';

import { config } from 'utils/config';
import { ILink } from 'utils/types';
import { TextButton } from 'components/button/TextButton';

import s from './JobListing.module.scss';
import { useRouter } from 'next/router';

type Props = {
  tagline?: string;
  title: string;
  summary: React.ReactNode;
  link: ILink;
};

export const JobListingItem = ({ tagline, title, summary, link }: Props) => {
  const router = useRouter();

  return (
    <div className={s.jobListingItem}>
      <div className={s.jobListingItem__content}>
        {tagline && <div className={s.jobListingItem__tagline}>{tagline}</div>}
        <h3 className={s.jobListingItem__title}>{title}</h3>
        <div className={s.jobListingItem__copy}>{summary}</div>
      </div>

      <TextButton {...link}>
        {router.locale === 'is' ? 'Skoða starf' : 'Discover position'}
      </TextButton>
    </div>
  );
};
