import gql from 'graphql-tag';
import { RichTextBlock } from 'prismic-reactjs';
import { IImage, ILink } from 'utils/types';
import { PrismicLink, PrismicImage } from '../utils/PrismicTypes';
import { IBaseSlice, SliceTypes } from '.';
import { fetchProducts, parseProducts } from '../product';
import {
  parseString,
  parseRichText,
  parseLink,
  parseImage,
  parseLinkFromMeta,
} from '../utils/parsers';

export interface ICatalogAlmatica extends IBaseSlice {
  tagline: string;
  title: string;
  text: RichTextBlock[];
  items: Array<{
    title?: string;
    warning?: boolean;
    image?: IImage;
    label?: string;
    link?: ILink;
    product?: {
      title: string;
      image: IImage;
      warning: boolean;
      link: ILink;
    };
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
      }
      fields {
        item_title
        item_warning
        item_image
        item_label
        item_link {
          ...Link
        }
        item_product {
          ... on Product {
            _meta {
              uid
              type
              lang
            }
            title
            brand_image
            contains_boxed_warning
          }
        }
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
  };
  fields: Array<{
    item_title: RichTextBlock[];
    item_warning: boolean;
    item_image: PrismicImage;
    item_label: RichTextBlock[];
    item_link?: PrismicLink;
    item_product: {
      _meta: {
        uid: string;
        type: string;
        lang: string;
      };
      title: RichTextBlock[];
      brand_image: PrismicImage;
      contains_boxed_warning: string;
    };
  }>;
};

export const parse = (slice: Props, redirects): ICatalogAlmatica => ({
  type: SliceTypes.CatalogAlmatica,
  tagline: parseString(slice.primary.slice_tagline),
  text: parseRichText(slice.primary.slice_text, redirects),
  title: parseString(slice.primary.slice_title),
  items: slice.fields.map((field) => ({
    title: parseString(field.item_title, redirects),
    warning: field.item_warning || false,
    image: parseImage(field.item_image),
    label: parseString(field.item_label, redirects),
    link: parseLink(field.item_link),
    product: field.item_product && {
      title: parseString(field.item_product.title),
      image: parseImage(field.item_product.brand_image),
      warning: field.item_product.contains_boxed_warning === 'Yes',
      link: parseLinkFromMeta(field.item_product._meta, redirects),
    },
  })),
});
