import React from 'react';
import classnames from 'classnames';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import VisibilitySensor from 'react-visibility-sensor';
import { Fade } from 'components/animations/Fade';

import { Tagline } from 'components/tagline/Tagline';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { SearchNoResult } from 'components/search/SearchNoResult';
import { ArticlesFilters } from './ArticlesFilters';

import s from './Articles.module.scss';

type Props = {
  index?: number;
  pageIndex?: number;
  tagline?: React.ReactNode;
  title?: string;
  isSlice?: boolean;
  children: React.ReactNode;
  background?: boolean;
  flush?: boolean;
  footer?: JSX.Element | JSX.Element[];

  filterTag?: string;
  filterTags?: string[];
  onFilterChange?: (tag?: string) => void;
  display?: string;
  noFeatured?: boolean;
  related?: React.ReactNode;
};

export const Articles = ({
  index,
  title,
  tagline,
  pageIndex = 0,
  isSlice,
  children,
  flush,
  footer,
  filterTag,
  filterTags,
  onFilterChange,
  display = 'col-3-image',
  noFeatured = false,
  related,
}: Props) => {
  const { i18n } = useLingui();
  const baseDelay = (title ? 0.15 : 0) + (tagline ? 0.15 : 0);
  const listRef = React.useRef<HTMLUListElement>(null);
  const isEmpty = React.Children.toArray(children).length === 0;
  const [currentCategory, setCurrentCategory] = React.useState(i18n._(t`All categories`));

  const optionsSelect = (
    <div className={s.articles__dropdown}>
      <select
        className={s.articles__select}
        value={filterTag}
        onChange={(e) => {
          const { value } = e.target;
          setCurrentCategory(value);
          onFilterChange && onFilterChange(value === i18n._(t`All categories`) ? undefined : value);
        }}
      >
        <option>{i18n._(t`All categories`)}</option>
        {filterTags?.map((tag) => (
          <option key={tag} value={tag}>
            {tag}
          </option>
        ))}
      </select>
    </div>
  );

  const content = (
    <ul className={s.articles__list} ref={listRef}>
      {!noFeatured && pageIndex === 1 && related}
      {React.Children.toArray(children).map((component: JSX.Element, i) => {
        return React.cloneElement(component, {
          featured: i === 0 && pageIndex === 1 && !noFeatured ? true : false,
          display,
        });
      })}
    </ul>
  );

  const container = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div className={s.articles__segment}>
          <div className={s.articles__container}>
            <div className={s.articles__header}>
              <div className={s.articles__text}>
                {tagline && (
                  <Fade isVisible={isVisible}>
                    <Tagline text={tagline} />
                  </Fade>
                )}
                {title && (
                  <Fade isVisible={isVisible} delay={tagline ? 0.15 : 0}>
                    <h1 className={s.articles__title}>{title}</h1>
                  </Fade>
                )}
              </div>

              {display === 'col-3-image' && (
                <div className={s.articles__opFade}>
                  <Fade isVisible={isVisible} delay={baseDelay}>
                    <div className={s.articles__options}>
                      {filterTags?.length > 0 && optionsSelect}
                    </div>
                  </Fade>
                </div>
              )}
            </div>

            {display === 'col-1' && <ArticlesFilters filterTags={filterTags} />}

            {isEmpty && (
              <SearchNoResult>
                <p>
                  <Trans>There are no articles that match the applied filters.</Trans>
                </p>
              </SearchNoResult>
            )}

            {!isEmpty && (
              <div className={s.articles__row}>
                <div className={s.articles__col}>{content}</div>
              </div>
            )}

            {footer && <div className={s.articles__footer}>{footer}</div>}
          </div>
        </div>
      )}
    </VisibilitySensor>
  );

  return (
    <BackgroundSection>
      <div className={classnames(s.articles, s[display], { [s.flush]: flush })}>
        {isSlice ? container : content}
      </div>
    </BackgroundSection>
  );
};
