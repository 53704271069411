import React from 'react';
import { Trans } from '@lingui/macro';
import Link from 'next/link';
import classnames from 'classnames';
import { Tagline } from 'components/tagline/Tagline';
import { SortableTable } from 'components/table/SortableTable';

import s from './Packages.module.scss';

type Props = {
  title?: React.ReactNode;
  tagline?: React.ReactNode;
  isSlice?: boolean;
  data: Array<{
    vnr: string;
    strength: string;
    size: string;
  }>;
};

export const Packages = ({ title, tagline, data }: Props) => {
  const columns = React.useMemo(
    () => [
      {
        id: 'id',
        Header: <Trans>Product number</Trans>,
        accessor: 'id',
        Cell: ({ row }) => {
          return row.original.vnr;
        },
      },
      {
        id: 'strength',
        Header: <Trans>Strength</Trans>,
        accessor: 'strength',
        Cell: ({ row }) => {
          return row.original.strength;
        },
      },
      {
        id: 'size',
        Header: <Trans>Size</Trans>,
        accessor: 'size',
        Cell: ({ row }) => {
          return row.original.size;
        },
      },
    ],
    []
  );

  return (
    <section className={s.packages}>
      <div className={s.packages__container}>
        <header className={s.packages__header}>
          {tagline && <Tagline text={tagline} />}
          {title && <h2 className={s.packages__title}>{title}</h2>}
        </header>
        <SortableTable columns={columns} data={data} />
      </div>
    </section>
  );
};
