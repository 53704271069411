import React from 'react';
import classnames from 'classnames';

import s from './Text.module.scss';

type Props = {
  narrow?: boolean;
  children: React.ReactNode;
};

export const TextWrap = ({ narrow, children }: Props) => {
  return (
    <div className={classnames(s.text, { [s.narrow]: narrow })}>
      <div className={s.text__segment}>
        <div className={s.text__container}>
          {React.Children.map(children, (component, index) => (
            <div key={index} className={s.text__row}>
              <div className={s.text__col}>{component}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
