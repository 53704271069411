import React from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

import s from './Divider.module.scss';

export const Divider = () => {
  const [hasRun, setHasRun] = React.useState(false);

  const runCheck = (isVisible) => {
    if (!hasRun) {
      setHasRun(isVisible);
    }
  };

  return (
    <VisibilitySensor partialVisibility={true} onChange={runCheck}>
      {({ isVisible }) => (
        <div className={s.divider}>
          <div className={s.divider__container}>
            <div
              className={classnames(s.divider__wrapper, {
                [s.visible]: isVisible || hasRun,
              })}
            >
              <img
                className={s.divider__image}
                src="/static/images/divider.svg"
                role="presentation"
                alt=""
              />
            </div>
          </div>
        </div>
      )}
    </VisibilitySensor>
  );
};
