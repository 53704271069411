import React from 'react';
import Image from 'next/image';
import { t } from '@lingui/macro';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import { useContainerQuery } from 'react-container-query';

import { Tagline } from 'components/tagline/Tagline';
import { MegaButton } from 'components/button/MegaButton';
import { Fade } from 'components/animations/Fade';

import { IImage, ILink } from 'utils/types';

import s from './Banner.module.scss';

type Props = {
  tagline?: string;
  title?: string;
  text?: React.ReactNode;
  link?: ILink;
  label?: string;
  side?: boolean;
  buttonText?: string;
  buttonCaption?: string;
  buttonSubline?: string;
  buttonNumber?: string;
  image?: IImage;
};

const query = {
  mobile: {
    maxWidth: 839,
  },
  desktop: {
    minWidth: 840,
  },
};

export const Banner = ({
  tagline,
  title,
  text,
  image,
  link,
  label,
  side,
  buttonText,
  buttonCaption,
  buttonSubline,
  buttonNumber,
}: Props) => {
  const [params, containerRef] = useContainerQuery(query, { width: 0, height: 0 });

  return (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div
          className={classnames(s.banner, { [s.right]: side, [s.left]: !side })}
          ref={containerRef}
        >
          <Fade isVisible={isVisible}>
            <div className={s.banner__wrap}>
              <div className={s.banner__background}>
                {image?.url && (
                  <Image
                    className={s.banner__image}
                    src={params.mobile ? image.mobile.url : image.desktop.url}
                    layout="fill"
                    objectFit="cover"
                  />
                )}
              </div>
              <div className={s.banner__container}>
                <div className={s.banner__layout}>
                  <header className={s.banner__header}>
                    {tagline && (
                      <Fade isVisible={isVisible}>
                        <Tagline text={tagline} />
                      </Fade>
                    )}
                    {title && (
                      <Fade isVisible={isVisible} delay={tagline ? 0.15 : 0}>
                        <h3 className={s.banner__title}>{title}</h3>
                      </Fade>
                    )}
                  </header>
                  <div className={s.banner__content}>
                    {text && (
                      <Fade isVisible={isVisible} delay={tagline ? 0.15 : 0.3}>
                        <div className={s.banner__row}>
                          <div className={s.banner__text}>
                            <div className={s.banner__copy}>{text}</div>
                          </div>
                        </div>
                      </Fade>
                    )}
                  </div>
                  {link && (
                    <footer className={s.banner__footer}>
                      <Fade isVisible={isVisible} delay={text ? 0.45 : 0.3}>
                        <div className={s.banner__link}>
                          <MegaButton
                            label={buttonText}
                            caption={buttonCaption}
                            subline={buttonSubline}
                            number={buttonNumber}
                            link={link}
                            ariaLabel={buttonText || label}
                          >
                            {label || t`Read more`}
                          </MegaButton>
                        </div>
                      </Fade>
                    </footer>
                  )}
                </div>
              </div>
            </div>
          </Fade>
        </div>
      )}
    </VisibilitySensor>
  );
};
