import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage, ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { PrismicImage, PrismicLink } from '../utils/PrismicTypes';
import { parseImage, parseLink, parseRichText, parseString } from '../utils/parsers';

export interface IColumnSection extends IBaseSlice {
  tagline: string;
  title: string;
  image: IImage;
  numbered: boolean;
  color: string;
  items: Array<{
    title: string;
    text: RichTextBlock[];
    link: ILink;
    label: string;
    icon: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_image
        slice_numbered
        slice_color
      }
      fields {
        item_title
        item_text
        item_link { ...Link }
        item_label
        item_icon
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_image: PrismicImage;
    slice_numbered: boolean;
    slice_color: string;
  };
  fields: Array<{
    item_title: RichTextBlock[];
    item_text: RichTextBlock[];
    item_link: PrismicLink;
    item_label: RichTextBlock[];
    item_icon: string;
  }>;
};

export const parse = (slice: Props, redirects?): IColumnSection => ({
  type: SliceTypes.ColumnSection,
  tagline: parseString(slice.primary.slice_tagline),
  title: parseString(slice.primary.slice_title),
  image: parseImage(slice.primary.slice_image),
  numbered: slice.primary.slice_numbered,
  color: parseString(slice.primary.slice_color),
  items: slice.fields.map((field) => ({
    title: parseString(field.item_title),
    text: parseRichText(field.item_text, redirects),
    link: parseLink(field.item_link, redirects),
    label: parseString(field.item_label),
    icon: parseString(field.item_icon),
  })),
});
