import React from 'react';

import s from './Shareholder.module.scss';

type Props = {
  title?: React.ReactNode;
  children?: React.ReactNode;
};

export const Year = ({ title, children }: Props) => {
  return (
    <div className={s.year}>
      <div className={s.year__layout}>
        <div className={s.year__header}>
          <h2 className={s.year__title}>{title}</h2>
        </div>
        <div className={s.year__grid}>{children}</div>
      </div>
    </div>
  );
};
