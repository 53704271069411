import React from 'react';
import xor from 'lodash/xor';
import { IImage, ILink } from 'utils/types';
import { IndexGrid } from 'components/index-grid/IndexGrid';
import { RichTextBlock } from 'prismic-reactjs';

type Props = {
  tagline?: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  image?: IImage;
  link?: ILink;
  label?: React.ReactNode;
  listItems?: Array<{
    tagline: string;
    title: string;
    intro: string;
    tags: string[];
    images: Array<{
      image: IImage;
    }>;
    detailsLink?: ILink;
  }>;
  children?: React.ReactNode;
  options: string[];
  showAll: string;
};

export const IndexGridContainer = ({
  tagline,
  title,
  text,
  image,
  link,
  label,
  options,
  showAll,
  listItems,
  children,
}: Props) => {
  const [currentOption, setOption] = React.useState(showAll || 'Show all');

  const allOptions = showAll || 'Show all';

  const filteredList = listItems.filter(
    (product) =>
      currentOption === (showAll || 'Show all') ||
      product.tags.some((t) => currentOption.includes(t))
  );

  return (
    <IndexGrid
      tagline={tagline}
      title={title}
      text={text}
      image={image}
      link={link}
      label={label}
      listItems={filteredList}
      onOptionChange={setOption}
      filters={options?.length > 0 ? [allOptions, ...options] : []}
    >
      {children}
    </IndexGrid>
  );
};
