import React from 'react';
import { t, Trans } from '@lingui/macro';

import { Articles as ArticlesComponent } from 'components/articles/Articles';
import { ArticlesItem } from 'components/articles/ArticlesItem';
import { fetchArticlesAndParse, IArticleIndex } from 'providers/prismic/slices/articleIndex';
import { fetchArticles } from 'providers/prismic/articles';
import { Button } from 'components/button/Button';
import { Buttons } from 'components/buttons/Buttons';
import { IconButton } from 'components/button/IconButton';

export const ArticleIndex = ({
  title,
  tagline,
  items,
  tags,
  matchAll,
  featured,
  filter,
  lang,
}: IArticleIndex) => {
  // const totalPages = Math.ceil(items.total / 10);
  const [filterTag, setFilterTag] = React.useState<string>();
  const [articles, setArticles] = React.useState(items?.items);
  const [cursor, setCursor] = React.useState<string>(
    items?.hasNextPage ? items.endCursor : undefined
  );
  const [loading, setLoading] = React.useState(false);

  const fetch = async ({ tag = filterTag, cursor }: { tag?: string; cursor?: string }) => {
    const res = await fetchArticlesAndParse({
      lang: lang,
      previewData: undefined,
      redirects: [],
      after: cursor,
      tags: tag ? [tag] : undefined,
      tagsIn: tag ? undefined : tags,
    });

    setArticles(cursor ? [...articles, ...res.items] : res.items);
    setCursor(res.hasNextPage ? res.endCursor : undefined);
  };

  const onChange = async (tag?: string) => {
    if (!tag) {
      setArticles(items?.items);
      setFilterTag(undefined);
      setCursor(items?.hasNextPage ? items.endCursor : undefined);
      return;
    }

    setLoading(true);

    await fetch({ tag });

    setFilterTag(tag);
    setLoading(false);
  };

  const loadMore = async () => {
    return fetch({
      cursor,
    });
  };

  React.useEffect(() => {
    setArticles([]);
    // console.log(tags);
  }, [tags])

  React.useEffect(() => {
    if(articles.length === 0) {
      fetch({});
    }
    // console.log(articles);
  }, [articles])

  return (
    <>
      <ArticlesComponent
        title={title}
        tagline={tagline}
        pageIndex={filterTag ? undefined : 1}
        isSlice
        filterTag={filterTag}
        filterTags={filter}
        onFilterChange={onChange}
        footer={
          cursor && (
            <Buttons alignment="center">
              <IconButton icon="plus" onClick={loadMore}>
                <Trans>Load more</Trans>
              </IconButton>
            </Buttons>
          )
        }
      >
        {featured && filterTag === undefined && (
          <ArticlesItem
            key={featured.link.href}
            title={featured.title}
            text={featured.summary}
            tagline={featured.tagline}
            date={featured.date}
            image={featured.image}
            link={featured.link}
          />
        )}

        {articles
          .filter((a) => (filterTag ? true : a.link.href !== featured?.link.href))
          .map((article) => {
            return (
              <ArticlesItem
                key={article.link.href}
                title={article.title}
                text={article.summary}
                tagline={article.tagline}
                date={article.date}
                image={article.image}
                link={article.link}
              />
            );
          })}
      </ArticlesComponent>
    </>
  );
};
