import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { PrismicImage } from '../utils/PrismicTypes';
import { parseImage, parseString } from '../utils/parsers';

export interface IGallery extends IBaseSlice {
  title: string;
  items: Array<{
    image: IImage;
    title: string;
    description: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
      }
      fields {
        item_image
        item_title
        item_description
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
  };
  fields: Array<{
    item_image: PrismicImage;
    item_title: RichTextBlock[];
    item_description: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IGallery => ({
  type: SliceTypes.Gallery,
  title: parseString(slice.primary.slice_title),
  items: slice.fields.map((field) => ({
    image: parseImage(field.item_image),
    title: parseString(field.item_title),
    description: parseString(field.item_description),
  })),
});
