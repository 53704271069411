import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '.';
import { parseRichText, parseString, parseLink } from '../utils/parsers';
import { ILink } from 'utils/types';
import { PrismicLink } from '../utils/PrismicTypes';

export interface IRelated extends IBaseSlice {
  mainLinkText: string;
  mainLink: ILink;
  items: Array<{
    title: string;
    text: RichTextBlock[];
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_main_link { ...Link }
        slice_main_link_text
      }
      fields {
        item_title
        item_text
      }
    }
  }
`;

type Props = {
  primary: {
    slice_main_link_text: RichTextBlock[];
    slice_main_link: PrismicLink;
  };
  fields: Array<{
    item_title: RichTextBlock[];
    item_text: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IRelated => ({
  type: SliceTypes.Related,
  mainLinkText: parseString(slice.primary.slice_main_link_text),
  mainLink: parseLink(slice.primary.slice_main_link, redirects),
  items: slice.fields.map((field) => ({
    title: parseString(field.item_title),
    text: parseRichText(field.item_text, redirects),
  })),
});
