import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { PrismicLink } from '../utils/PrismicTypes';
import { parseLink, parseRichText, parseString } from '../utils/parsers';

export interface ICta extends IBaseSlice {
  title: string;
  tagline: string;
  text: RichTextBlock[];
  label: string;
  link: ILink;
  graphic: string;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_tagline
        slice_text
        slice_label
        slice_link {
          ...Link
        }
        slice_graphic
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_tagline: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_label: RichTextBlock[];
    slice_link: PrismicLink;
    slice_graphic: string;
  };
};

export const parse = (slice: Props, redirects): ICta => ({
  type: SliceTypes.Cta,
  title: parseString(slice.primary.slice_title),
  tagline: parseString(slice.primary.slice_tagline),
  text: parseRichText(slice.primary.slice_text, redirects),
  label: parseString(slice.primary.slice_label),
  link: parseLink(slice.primary.slice_link, redirects),
  graphic: parseString(slice.primary.slice_graphic),
});
