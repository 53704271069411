import React from 'react';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import xor from 'lodash/xor';
import { Catalog, FilterTypes } from 'components/catalog/Catalog';
import { TableList } from 'components/catalog/list/TableList';
import { Verification } from 'containers/verification/Verification';
import { ILink } from 'utils/types';
import { Link } from 'components/link/Link';
import { RichText } from 'components/rich-text/RichText';
import { RichTextBlock } from 'prismic-reactjs';

type Props = {
  title?: React.ReactNode;
  tagline?: React.ReactNode;
  catalog: Array<{
    title: string;
    activeIngredient: string;
    form: string;
    strengths: string;
    sizes: string;
    atc: string;
    manual: ILink;
    smpc: ILink;
    detailsLink?: ILink;
    tags?: string[];
    variants?: Array<{
      vnr: string;
      size: string;
      strength: string;
    }>;
  }>;
  verification?: {
    title?: React.ReactNode;
    tagline?: React.ReactNode;
    text?: RichTextBlock[];
    acceptLabel?: React.ReactNode;
    declineLabel?: React.ReactNode;
    link?: ILink;
  };
  options: string[];
  showAll: string;
};

export const CatalogTableContainer: React.FC<Props> = ({
  title,
  tagline,
  catalog,
  verification,
  options,
  showAll,
  ...rest /* slice props */
}) => {
  const [currentOption, setOption] = React.useState<FilterTypes>(showAll || 'Show all');
  const [symbols, setSymbol] = React.useState<string[]>([]);
  const [term, setSearchTerm] = React.useState<string>('');
  const { i18n } = useLingui();

  const allOptions = showAll || 'Show all';

  const onSymbolToggled = (s) => {
    setSymbol(xor(symbols, [s]) /* Symmetric difference */);
  };

  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        Header: i18n._(t`Product name`),
        accessor: 'name',
        Cell: ({ row }) => {
          return row.original.name;
        },
      },
      {
        id: 'ingredient',
        Header: i18n._(t`Active ingredient`),
        accessor: 'ingredient',
        Cell: ({ row }) => {
          return row.original.ingredient;
        },
      },
      {
        id: 'form',
        Header: i18n._(t`Form`),
        accessor: 'form',
        Cell: ({ row }) => {
          return row.original.form;
        },
      },
      {
        id: 'atc',
        Header: i18n._(t`ATC#`),
        accessor: 'atc',
        Cell: ({ row }) => {
          return row.original.atc;
        },
      },
    ],
    []
  );

  const filteredCatalog = catalog
    .filter(
      (product) =>
        currentOption === (showAll || 'Show all') ||
        product.tags.some((t) => currentOption.includes(t))
    ) // filter type
    .filter(
      // check first letter of title and filter
      (product) =>
        symbols.length === 0 ||
        (product.title && symbols.indexOf(product.title.toLowerCase()[0]) > -1)
    )
    .filter(
      // filter by search term
      (product) => product.title && product.title.toLowerCase().indexOf(term.toLowerCase()) > -1
    );

  const data = React.useMemo(
    () =>
      filteredCatalog.map((product) => ({
        name: product.title,
        ingredient: product.activeIngredient,
        form: product.form,
        atc: product.atc,
        manual: product.manual,
        smpc: product.smpc,
        detailsLink: product.detailsLink,
        variants: product.variants,
      })) || [],
    [filteredCatalog]
  );

  return (
    <Catalog
      tagline={tagline}
      title={title}
      option={currentOption}
      symbols={symbols}
      term={term}
      onOptionChange={setOption}
      onSymbolToggled={onSymbolToggled}
      onTermChanged={setSearchTerm}
      filters={options?.length > 1 ? [allOptions, ...options] : []}
      {...rest}
    >
      <TableList columns={columns} data={data} />
      <Verification
        visible={true}
        title={verification.title}
        tagline={verification.tagline}
        text={<RichText render={verification.text}></RichText>}
        acceptLabel={verification.acceptLabel}
        declineLabel={verification.declineLabel}
        link={verification.link}
      />
    </Catalog>
  );
};
