import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { PrismicImage } from '../utils/PrismicTypes';
import { parseImage, parseString } from '../utils/parsers';

export interface IVariant extends IBaseSlice {
  image: IImage;
  ndc: string;
  strength: string;
  form: string;
  size: string;
  gcn: string;
  gcnSeq: string;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_image
        slice_ndc
        slice_strength
        slice_form
        slice_package_size
        slice_gcn
        slice_gcn_seq
      }
    }
  }
`;

type Props = {
  primary: {
    slice_image: PrismicImage;
    slice_ndc: RichTextBlock[];
    slice_strength: RichTextBlock[];
    slice_form: RichTextBlock[];
    slice_package_size: RichTextBlock[];
    slice_gcn: RichTextBlock[];
    slice_gcn_seq: RichTextBlock[];
  };
};

export const parse = (slice: Props): IVariant => ({
  type: SliceTypes.Variant,
  image: parseImage(slice.primary.slice_image),
  ndc: parseString(slice.primary.slice_ndc),
  strength: parseString(slice.primary.slice_strength),
  form: parseString(slice.primary.slice_form),
  size: parseString(slice.primary.slice_package_size),
  gcn: parseString(slice.primary.slice_gcn),
  gcnSeq: parseString(slice.primary.slice_gcn_seq),
});
