import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage, ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import {
  fetchProductsIceland,
  fetchAllProductsIceland,
  parseProductsIceland,
} from '../productIceland';
import { parseString } from '../utils/parsers';
import { getVerification } from '../verification';

export interface ICatalogIceland extends IBaseSlice {
  tagline: string;
  title: string;
  categories: string;
  showAll: string;
  items: {
    products: Array<{
      tagline: string;
      title: string;
      description: RichTextBlock[];
      drugType: string;
      activeIngredient: string;
      form: string;
      strengths: string;
      sizes: string;
      atc: string;
      manual: ILink;
      smpc: ILink;
      tags: string[];
      images: Array<{
        image: IImage;
      }>;
      variants?: Array<{
        vnr: string;
        size: string;
        strength: string;
      }>;
      detailsLink?: ILink;
    }>;
    verification: {
      title: string;
      tagline: string;
      text: RichTextBlock[];
      acceptLabel: string;
      declineLabel: string;
      link: ILink;
    };
  };
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_categories
        slice_all
      }
    }
  }
`;

type NestedProps = {
  lang: string;
  limit?: number;
  previewData: boolean;
  redirects: Array<{ from: string; to: string }>;
};

export const fetchProductsIcelandAndParse = async ({
  lang,
  limit,
  previewData,
  redirects = [],
}: NestedProps) => {
  const items = await fetchAllProductsIceland(lang, '', previewData, (limit = 500));
  const verification = await getVerification({ previewData, lang, redirects });
  return { products: parseProductsIceland(items, redirects), verification };
};

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_categories: RichTextBlock[];
    slice_all: RichTextBlock[];
  };
};

export const parse = (slice: Props): ICatalogIceland => ({
  type: SliceTypes.CatalogIceland,
  tagline: parseString(slice.primary.slice_tagline),
  title: parseString(slice.primary.slice_title),
  categories: parseString(slice.primary.slice_categories),
  showAll: parseString(slice.primary.slice_all),
  items: {
    products: [],
    verification: null,
  },
});
