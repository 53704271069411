import React from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useLingui } from '@lingui/react';
import { t } from '@lingui/macro';
import { format, parseISO } from 'date-fns';
import _debounce from 'lodash/debounce';
import useOnClickOutside from 'use-onclickoutside';

import { Link } from 'components/link/Link';
import { DatePicker } from 'components/datepicker/DatePicker';

import IconDatePicker from 'components/assets/datepicker.svg';
import IconSearch from 'components/assets//icon-search.svg';

import s from './Articles.module.scss';

type Props = {
  filterTags: string[];
};

const getString = (value: string | string[]) => {
  return (Array.isArray(value) ? value[0] : value) ?? '';
};

export const ArticlesFilters = ({ filterTags }: Props) => {
  const { i18n } = useLingui();
  const router = useRouter();

  const { slug, ...query } = router.query;
  const { page, ...queryWithoutPage } = query;
  const { from, to, ...queryWithoutRange } = queryWithoutPage;
  const { q: search, ...queryWithoutSearch } = queryWithoutPage;
  const { tag, ...queryWithoutTag } = query;
  const currentTag = query.tag || i18n._(t`All`);

  const dateRef = React.useRef<HTMLDivElement>(null);
  const strFrom = getString(from);
  const strTo = getString(to);
  const strSearch = getString(search);
  const dateFrom = strFrom ? parseISO(strFrom) : undefined;
  const dateTo = strTo ? parseISO(strTo) : undefined;

  const [startDate, setStartDate] = React.useState<Date | undefined>(dateFrom);
  const [endDate, setEndDate] = React.useState<Date | undefined>(dateTo);
  const [dateFilterActive, setDateFilterActive] = React.useState(!!dateFrom || !!dateTo);
  const [searchFilter, setSearchFilter] = React.useState(strSearch);
  // const [searchQuery, setSearchQuery] = React.useState(strSearch);

  React.useEffect(() => {
    if (startDate !== undefined || endDate !== undefined) {
      router.push({
        pathname: router.pathname,
        query: {
          ...queryWithoutRange,
          ...(startDate && { from: format(startDate, 'yyyy-MM-dd') }),
          ...(endDate && { to: format(endDate, 'yyyy-MM-dd') }),
        },
      });
    }
  }, [startDate, endDate]);

  const leSearch = (searchQuery) => {
    router.push({
      pathname: router.pathname,
      query: {
        ...queryWithoutSearch,
        ...(searchQuery && { q: searchQuery }),
      },
    });
  };

  const handleDateFilterClick = () => {
    setDateFilterActive(!dateFilterActive);
  };

  useOnClickOutside(dateRef, () => setDateFilterActive(false));

  return (
    <div className={s.articlesFilters}>
      <ul className={s.articlesFilters__tags}>
        <li>
          <Link
            className={cn(s.articlesFilters__tag, { [s.active]: currentTag === i18n._(t`All`) })}
            href={{ pathname: router.pathname, query: queryWithoutTag }}
            transition={false}
          >
            {i18n._(t`All`)}
          </Link>
        </li>
        {filterTags?.map((tag) => (
          <li key={tag}>
            <Link
              className={cn(s.articlesFilters__tag, { [s.active]: currentTag === tag })}
              href={{ pathname: router.pathname, query: { ...queryWithoutPage, tag } }}
              transition={false}
            >
              {tag}
            </Link>
          </li>
        ))}
      </ul>
      <div className={s.articlesFilters__group} ref={dateRef}>
        <div className={cn(s.articlesFilters__range, { [s.active]: dateFilterActive })}>
          <button className={s.articlesFilters__rangeToggle} onClick={handleDateFilterClick}>
            <IconDatePicker className={s.articlesFilters__rangeToggleIcon} />
            {i18n._(t`Filter by date`)}
          </button>
          <div className={s.articlesFilters__rangeFields}>
            <DatePicker
              label={i18n._(t`From`)}
              id="filter-date-start"
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              maxDate={endDate}
              selectsStart
              isClearable={!!startDate}
              onChange={(date) => setStartDate(date)}
            />
            <DatePicker
              label={i18n._(t`To`)}
              id="filter-date-end"
              selected={endDate}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              selectsEnd
              isClearable={!!endDate}
              onChange={(date) => setEndDate(date)}
            />
          </div>
        </div>
        <div className={s.articlesFilters__search}>
          <form
            className={s.articlesFilters__searchForm}
            action=""
            onSubmit={(event) => {
              event.preventDefault();
              leSearch(searchFilter);
            }}
          >
            <div className={s.articlesFilters__searchInputWrapper}>
              <input
                className={s.articlesFilters__searchInput}
                type="text"
                name="q"
                placeholder="Search"
                aria-label="Search query"
                value={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
              />
              {strSearch && (
                <button
                  type="button"
                  className={s.articlesFilters__searchClear}
                  onClick={() => {
                    setSearchFilter('');
                    leSearch('');
                  }}
                  aria-label="Clear search query"
                />
              )}
            </div>
            <button className={s.articlesFilters__searchButton} type="submit" aria-label="Search">
              <IconSearch />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
