import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Link } from 'components/link/Link';
import { ILink } from 'utils/types';
import { IconDownload } from 'components/assets';

import s from './Shareholder.module.scss';

type Props = {
  title?: React.ReactNode;
  link: ILink;
};

export const Report = ({ title, link }: Props) => {
  if (!link || !link.href) {
    return null;
  }

  return (
    <div className={s.report}>
      <Link className={s.report__link} {...link} target="_blank">
        <span className={s.report__icon}>
          <IconDownload />
        </span>
        <Trans>Annual Report</Trans> {title}
      </Link>
    </div>
  );
};
