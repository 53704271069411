import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IEmbed, IImage, ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { PrismicEmbed, PrismicImage, PrismicLink } from '../utils/PrismicTypes';
import { parseEmbed, parseImage, parseLink, parseRichText, parseString } from '../utils/parsers';

export interface IStaggeredBlocks extends IBaseSlice {
  style: string;
  direction: string;
  items: Array<{
    image: IImage;
    tagline: string;
    title: string;
    text: RichTextBlock[];
    caption: string;
    label: string;
    link: ILink;
    secondLabel: string;
    secondLink: ILink;
    video: ILink;
    embed: IEmbed;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_style
        slice_direction
      }
      fields {
        item_image
        item_tagline
        item_title
        item_text
        item_caption
        item_label
        item_link {
          ...Link
        }
        item_secondlabel
        item_secondlink {
          ...Link
        }
        item_video {
          ...Link
        }
        item_embed
      }
    }
  }
`;

type Props = {
  primary: { slice_style: string; slice_direction: string };
  fields: Array<{
    item_image: PrismicImage;
    item_tagline: RichTextBlock[];
    item_title: RichTextBlock[];
    item_text: RichTextBlock[];
    item_caption: RichTextBlock[];
    item_label: RichTextBlock[];
    item_link: PrismicLink;
    item_second_label: RichTextBlock[];
    item_second_link: PrismicLink;
    item_video: PrismicLink;
    item_embed: PrismicEmbed;
  }>;
};

export const parse = (slice: Props, redirects): IStaggeredBlocks => ({
  type: SliceTypes.StaggeredBlocks,
  style: parseString(slice.primary.slice_style),
  direction: parseString(slice.primary.slice_direction),
  items: slice.fields.map((field) => ({
    image: parseImage(field.item_image),
    tagline: parseString(field.item_tagline),
    title: parseString(field.item_title),
    text: parseRichText(field.item_text),
    caption: parseString(field.item_caption),
    label: parseString(field.item_label),
    link: parseLink(field.item_link, redirects),
    secondLabel: parseString(field.item_second_label),
    secondLink: parseLink(field.item_second_link, redirects),
    video: parseLink(field.item_video),
    embed: parseEmbed(field.item_embed),
  })),
});
