import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Tagline } from 'components/tagline/Tagline';
import { Year } from './Year';
import { Quarter } from './Quarter';

import s from './Quarters.module.scss';

type Props = {
  tagline?: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  listItems?: React.ReactNode;
  noContainer?: boolean;
  children?: React.ReactNode;
};

interface IQuarters extends React.FC<Props> {
  Year: typeof Year;
  Quarter: typeof Quarter;
}

const Quarters: IQuarters = function Quarters({ tagline, title, text, noContainer, children }) {
  const content = (
    <div className={s.quarters__layout}>
      {(title || tagline) && (
        <header className={s.quarters__header}>
          <div className={s.quarters__headlines}>
            <Tagline text={tagline} />
            <h1 className={s.quarters__title}>{title}</h1>
          </div>
          {text && <div className={s.quarters__text}>{text}</div>}
        </header>
      )}
      <div className={s.quarters__grid}>{children}</div>
    </div>
  );

  const container = (
    <div className={s.quarters__segment}>
      <div className={s.quarters__container}>{content}</div>
    </div>
  );

  return <section className={s.quarters}>{noContainer ? content : container}</section>;
};

Quarters.Year = Year;
Quarters.Quarter = Quarter;

export { Quarters };
