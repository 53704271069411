import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '.';
import { parseString } from '../utils/parsers';

export interface INewsletterSignup extends IBaseSlice {
  tagline: string;
  title: string;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
  };
};

export const parse = (slice: Props): INewsletterSignup => ({
  type: SliceTypes.NewsletterSignup,
  tagline: parseString(slice.primary.slice_tagline),
  title: parseString(slice.primary.slice_title),
});
