import React from 'react';
import classnames from 'classnames';

import { Tagline } from 'components/tagline/Tagline';
import { IconSearch } from 'components/assets';

import s from './JobListing.module.scss';

type Props = {
  title?: string;
  tagline?: string;
  isSlice?: boolean;
  groups?: string[];
  onChange?: (group: string) => void;
  search?: string;
  onSearch?: (q: string) => void;
  selectedGroup?: string;
  children: React.ReactNode;
};

export const JobListing = ({
  title,
  tagline,
  groups = [],
  onChange = () => {},
  search = '',
  onSearch = () => {},
  children,
  isSlice,
  selectedGroup,
}: Props) => {
  const content = <div className={s.jobListing__list}>{children}</div>;

  const container = (
    <div className={s.jobListing__segment}>
      <div className={s.jobListing__container}>
        <div className={s.jobListing__header}>
          <div className={s.jobListing__heading}>
            {tagline && <Tagline text={tagline} />}
            <h2 className={s.jobListing__title}>{title}</h2>
          </div>
          <div
            className={classnames(s.jobListing__search, { [s.focus]: search && search.length > 0 })}
          >
            <div className={s.jobListing__options}>
              {groups.length > 1 && (
                <ul className={s.jobListing__optionsList}>
                  <li
                    className={classnames(s.jobListing__optionsItem, {
                      [s.active]: selectedGroup === 'All',
                    })}
                    onClick={() => onChange('All')}
                  >
                    All
                  </li>
                  {groups.map((g) => (
                    <li
                      className={classnames(s.jobListing__optionsItem, {
                        [s.active]: selectedGroup === g,
                      })}
                      key={g}
                      onClick={() => onChange(g)}
                    >
                      {g}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className={s.jobListing__filter}>
              <input
                className={s.jobListing__filterInput}
                defaultValue={search}
                onChange={(e) => onSearch(e.target.value)}
              />
              <div className={s.jobListing__filterIcon}>
                <IconSearch />
              </div>
            </div>
          </div>
        </div>
        <div>{content}</div>
      </div>
    </div>
  );

  return <div className={s.jobListing}>{isSlice ? container : content}</div>;
};
