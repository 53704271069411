import React from 'react';
import classnames from 'classnames';
import {
  Cell,
  Column,
  TableOptions,
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
} from 'react-table';
import { ChevronRight } from 'components/assets';

import s from './SortableTable.module.scss';

type Props = {
  columns?: Column<{}>[];
  data?: {}[];
};

export const SortableTable: React.FC<Props> = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <div className={s.table}>
      <table className={s.table__table} {...getTableProps()}>
        <thead className={s.table__thead}>
          {headerGroups.map((headerGroup) => (
            <tr className={s.table__header} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={classnames(s.table__label, {
                    [s.sorted]: column.isSorted,
                    [s.descending]: column.isSortedDesc,
                  })}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <span className={s.table__labelLayout}>
                    {column.render('Header')}
                    <span className={s.table__indicator}>
                      <ChevronRight />
                    </span>
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={s.table__tbody} {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr className={s.table__item} {...row.getRowProps()}>
                {row.cells.map((cell, ii) => {
                  return (
                    <td className={s.table__value} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
