import React from 'react';

import s from '../Catalog.module.scss';

type Props = {
  children: React.ReactNode;
};

export const AccordionList: React.FC<Props> = ({ children }) => {
  const [active, setActive] = React.useState(-1);

  const click = (i: number) => {
    i === active ? setActive(-1) : setActive(i);
  };

  return (
    <div className={s.catalog__list}>
      <div className={s.catalog__listHeader}>
        <div className={s.catalog__listLabel}>All Products</div>
        <div className={s.catalog__listLabel}>Warnings</div>
      </div>
      {React.Children.map(children, (component: JSX.Element, i) =>
        React.cloneElement(component, {
          onClick: () => click(i),
          active: active === i,
        })
      )}
    </div>
  );
};
