import React from 'react';
import xor from 'lodash/xor';

import { Catalog, FilterTypes } from 'components/catalog/Catalog';
import { Product } from 'components/catalog/Product';
import { Variant } from 'components/catalog/Variant';
import { AccordionList } from 'components/catalog/list/AccordionList';
import { IVariant } from 'providers/prismic/slices/variant';
import { IImage, ILink } from 'utils/types';

type Props = {
  title?: React.ReactNode;
  tagline?: React.ReactNode;
  catalog: Array<{
    type?: string;
    title?: string;
    link?: ILink;
    warning?: boolean;
    infoLink?: ILink;
    hasRems?: boolean;
    remsLink?: ILink;
    pdfLink: ILink;
    variants?: Array<{
      type?: string;
      ndc?: string;
      gcn?: string;
      form?: string;
      image?: IImage;
      gcnSeq?: string;
      strength?: string;
      size?: string;
    }>;
    detailsLink?: ILink;
  }>;
};

export const CatalogContainer = ({ title, tagline, catalog, ...rest /* slice props */ }: Props) => {
  const [currentOption, setOption] = React.useState<FilterTypes>('All');
  const [symbols, setSymbol] = React.useState<string[]>([]);
  const [term, setSearchTerm] = React.useState<string>('');

  const onSymbolToggled = (s) => {
    setSymbol(xor(symbols, [s]) /* Symmetric difference */);
  };

  return (
    <Catalog
      tagline={tagline}
      title={title}
      option={currentOption}
      symbols={symbols}
      term={term}
      onOptionChange={setOption}
      onSymbolToggled={onSymbolToggled}
      onTermChanged={setSearchTerm}
      {...rest}
    >
      <AccordionList>
        {catalog
          .filter((product) => currentOption === 'All' || product.type === currentOption) // filter type
          .filter(
            // check first letter of title and filter
            (product) =>
              symbols.length === 0 ||
              (product.title && symbols.indexOf(product.title.toLowerCase()[0]) > -1)
          )
          .filter(
            // filter by search term
            (product) =>
              product.title && product.title.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
          .map((product, i) => (
            <Product
              key={i}
              title={product.title}
              link={product.detailsLink}
              warning={product.warning}
              infoLink={product.link}
              hasRems={product.hasRems}
              remsLink={product.remsLink}
            >
              {(product.variants as IVariant[]).map((variant, i) => (
                <Variant
                  key={i}
                  image={variant.image}
                  ndc={variant.ndc}
                  strength={variant.strength}
                  size={variant.size}
                  cgn={variant.gcn}
                  seq={variant.gcnSeq}
                />
              ))}
            </Product>
          ))}
      </AccordionList>
    </Catalog>
  );
};
