import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '.';
import { fetchArticles, IAllArticles, IArticle, parseArticles } from '../articles';
import { RichTextBlock } from 'prismic-reactjs';
import { parseDateTime, parseImage, parseLinkFromMeta, parseString } from '../utils/parsers';
import btoa from 'btoa';
import { PrismicImage } from '../utils/PrismicTypes';

export interface IArticleIndex extends IBaseSlice {
  title: string;
  lang?: string;
  tagline?: string;
  tags: string[];
  matchAll?: boolean;
  featured: IArticle;
  filter: string[];
  items: IAllArticles;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type

      primary {
        slice_title
        slice_tagline
        slice_tags
        slice_logic
        slice_filter
        slice_featured {
          ... on Article {
            date
            title
            tagline
            image
            caption
            summary

            _meta {
              uid
              type
              lang
              tags
            }
          }
        }
      }
    }
  }
`;

type NestedProps = {
  lang: string;
  limit?: number;
  previewData: boolean;
  redirects: Array<{ from: string; to: string }>;
  tags?: string[];
  tagsIn?: string[];
  filter?: string[];
  after?: any;
  page?: any;
};

export const fetchArticlesAndParse = async ({
  lang,
  limit = 9,
  previewData,
  redirects = [],
  tags,
  tagsIn,
  page = undefined,
  after = undefined,
}: NestedProps) => {
  const endCursor =
    page && page !== 1 && !after ? btoa(`arrayconnection:${(page - 1) * limit - 1}`) : after;

  const items = await fetchArticles(lang, endCursor, previewData, 9, tags, tagsIn);

  return parseArticles(items, redirects, lang);
};

type Props = {
  primary?: {
    slice_title: RichTextBlock[];
    slice_tagline: RichTextBlock[];
    slice_tags: string;
    slice_logic: boolean;
    slice_filter: string;
    slice_featured: {
      date: string;
      title: RichTextBlock[];
      tagline: string;
      image: PrismicImage;
      caption: RichTextBlock[];
      summary: RichTextBlock[];
      _meta: {
        uid: string;
        type: string;
        lang: string;
        tags: string[];
      };
    };
  };
};

export const parse = (slice: Props, redirects, lang): IArticleIndex => ({
  type: SliceTypes.ArticleIndex,
  lang,
  tagline: parseString(slice.primary?.slice_tagline),
  title: parseString(slice.primary?.slice_title),
  tags: (parseString(slice.primary?.slice_tags) || '')
    .split(',')
    .map((str) => str.trim())
    .filter((x) => !!x),
  matchAll: slice.primary?.slice_logic || false,
  filter: (parseString(slice.primary?.slice_filter) || '')
    .split(',')
    .map((str) => str.trim())
    .filter((x) => !!x),
  featured: slice.primary?.slice_featured
    ? {
        uid: slice.primary?.slice_featured?._meta.uid || null,
        date: parseDateTime(slice.primary?.slice_featured?.date, undefined, lang),
        title: parseString(slice.primary?.slice_featured?.title),
        tagline: parseString(slice.primary?.slice_featured?.tagline),
        image: parseImage(slice.primary?.slice_featured?.image),
        caption: parseString(slice.primary?.slice_featured?.caption),
        summary: parseString(slice.primary?.slice_featured?.summary),
        link: parseLinkFromMeta(slice.primary?.slice_featured?._meta, redirects),
        tags: slice.primary?.slice_featured?._meta?.tags ?? [],
      }
    : null,
  items: { total: 0, items: [] },
});
