import * as Yup from 'yup';
import { ICheckboxField, IField } from 'providers/wufoo/types';

const schemaType = (type: string) => {
  switch (type) {
    case 'email':
      return Yup.string().email();
    case 'number':
      return Yup.number();
    case 'checkbox':
      return Yup.boolean();
    default:
      return Yup.string();
  }
};

const schemaLabel = (label: string, yup) => yup.label(label);

const schemaRequired = (f: IField, yup) => {
  if (f.type === 'checkbox' && f.isRequired) {
    return yup.oneOf([true], 'Required');
  }

  if (f.isRequired) {
    return yup.required();
  }

  return yup;
};

const buildFieldSchema = (field: IField) =>
  schemaRequired(field, schemaLabel(field.title, schemaType(field.type)));

export const buildValidationSchema = (fields: Array<IField>) => {
  const val = fields.reduce((schema, f) => {
    if (f.type === 'checkbox') {
      return {
        ...schema,
        ...(f as ICheckboxField).choices.reduce((subSchema, field) => {
          return {
            ...subSchema,
            [field.id]: buildFieldSchema({
              ...f,
              ...field,
              type: 'checkbox',
            } as IField),
          };
        }, {}),
      };
    }

    const fieldSchema = buildFieldSchema(f);

    return {
      ...schema,
      [f.id]: fieldSchema,
    };
  }, {});

  return Yup.object().shape(val, {});
};

export const getInitialValues = (fields): { [key: string]: any } =>
  fields.reduce((vals, f) => {
    let val = f.defaultValue;

    if (f.type === 'select') {
      if (!val) {
        val = (f as ICheckboxField).choices.find((c) => c && !!c.label);
      }

      return { ...vals, [f.id]: val ? val.label : '' };
    }

    if (f.type === 'checkbox') {
      return {
        ...vals,
        ...(f as ICheckboxField).choices.reduce(
          (choices, c) => ({ ...choices, [c.id]: c.checked }),
          {}
        ),
      };
    }

    return { ...vals, [f.id]: val };
  }, {});
