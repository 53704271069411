import React from 'react';
import { setQueryParameters } from 'utils/setQueryParameters';

import { IImage } from 'utils/types';

import s from './Variant.module.scss';

type Props = {
  image?: IImage;
  ndc?: string;
  strength?: string;
  size?: string;
  cgn?: string;
  seq?: string;
};

export const Variant = ({ image, ndc, strength, size, cgn, seq }: Props) => {
  const mainSrc = image?.url;

  return (
    <div className={s.variant}>
      <table className={s.variant__table}>
        <tbody>
          <tr className={s.variant__row}>
            <th className={s.variant__header}>NDC#</th>
            <td className={s.variant__cell} data-label="NDC#">
              {ndc}
            </td>
          </tr>
          <tr className={s.variant__row}>
            <th className={s.variant__header}>Strength</th>
            <td className={s.variant__cell} data-label="Strength">
              {strength}
            </td>
          </tr>
          <tr className={s.variant__row}>
            <th className={s.variant__header}>PKG size</th>
            <td className={s.variant__cell} data-label="PKG size">
              {size}
            </td>
          </tr>
          <tr className={s.variant__row}>
            <th className={s.variant__header}>GCN</th>
            <td className={s.variant__cell} data-label="GCN">
              {cgn}
            </td>
          </tr>
          <tr className={s.variant__row}>
            <th className={s.variant__header}>GCN SEQ#</th>
            <td className={s.variant__cell} data-label="GCN SEQ#">
              {seq}
            </td>
          </tr>
        </tbody>
      </table>
      <div className={s.variant__image}>
        {mainSrc && (
          <picture>
            <source
              srcSet={setQueryParameters(
                mainSrc as string,
                'trim=color&trim-color=transparent&fit=max&w=150&h=150'
              )}
            />
            <img src={mainSrc} className={s.variant__img} alt="" />
          </picture>
        )}
      </div>
    </div>
  );
};
