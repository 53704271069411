import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '.';
import { RichTextBlock } from 'prismic-reactjs';
import { parseImage, parseLink, parseRichText, parseString } from '../utils/parsers';
import { PrismicImage, PrismicLink } from '../utils/PrismicTypes';
import { IImage, ILink } from 'utils/types';

export interface IQuoteBlock extends IBaseSlice {
  sliceTitle: RichTextBlock[];
  text: RichTextBlock[];
  author: string;
  role: string;
  image?: IImage;
  offset?: string;
  side?: 'left' | 'right';
  items: Array<{
    link?: ILink;
    label?: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_text
        slice_author
        slice_role
        slice_image
        slice_offset
        slice_side
      }
      fields {
        item_label
        item_link {
          ...Link
        }
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_author: RichTextBlock[];
    slice_role: RichTextBlock[];
    slice_image?: PrismicImage;
    slice_offset: string;
    slice_side: boolean;
  };
  fields: Array<{
    item_link?: PrismicLink;
    item_label?: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): IQuoteBlock => ({
  type: SliceTypes.QuoteBlock,
  sliceTitle: parseRichText(slice.primary.slice_title),
  text: parseRichText(slice.primary.slice_text),
  author: parseString(slice.primary.slice_author),
  role: parseString(slice.primary.slice_role),
  image: parseImage(slice.primary.slice_image),
  offset: slice.primary.slice_offset,
  side: !!slice.primary.slice_side ? 'right' : 'left',
  items: slice.fields.map((field) => ({
    link: parseLink(field.item_link),
    label: parseString(field.item_label),
  })),
});
