import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IImage } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { PrismicImage } from '../utils/PrismicTypes';
import { parseImage, parseRichText, parseString } from '../utils/parsers';

export interface ITimeline extends IBaseSlice {
  tagline: string;
  title: string;
  text: RichTextBlock[];
  items: Array<{
    image: IImage;
    category: string;
    title: string;
    text: RichTextBlock[];
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_tagline
        slice_title
        slice_text
      }
      fields {
        item_image
        item_category
        item_title
        item_text
      }
    }
  }
`;

type Props = {
  primary: {
    slice_tagline: RichTextBlock[];
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
  };
  fields: Array<{
    item_image: PrismicImage;
    item_category: RichTextBlock[];
    item_title: RichTextBlock[];
    item_text: RichTextBlock[];
  }>;
};

export const parse = (slice: Props, redirects): ITimeline => ({
  type: SliceTypes.Timeline,
  tagline: parseString(slice.primary.slice_tagline),
  title: parseString(slice.primary.slice_title),
  text: parseRichText(slice.primary.slice_text, redirects),
  items: slice.fields.map((field) => ({
    image: parseImage(field.item_image),
    category: parseString(field.item_category),
    title: parseString(field.item_title),
    text: parseRichText(field.item_text, redirects),
  })),
});
