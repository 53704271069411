import React from 'react';
import { useRouter } from 'next/router';
import { Modal } from 'components/modal/Modal';
import { Verification as ModalVerification } from 'components/modal/Verification';
import { Buttons } from 'components/buttons/Buttons';
import { Button } from 'components/button/Button';
import { ILink } from 'utils/types';

type Props = {
  visible: boolean;
  tagline?: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  acceptLabel?: React.ReactNode;
  declineLabel?: React.ReactNode;
  link?: ILink;
};

export const Verification = ({
  visible,
  tagline,
  title,
  text,
  acceptLabel,
  declineLabel,
  link = { href: '/' },
}: Props) => {
  const router = useRouter();
  const [showVerification, setShowVerification] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (window.localStorage && !window.localStorage.getItem('SHOW_VERIFICATION')) {
      setShowVerification(visible);
      return window.localStorage.setItem('SHOW_VERIFICATION', visible ? '1' : '0');
    }

    setShowVerification(() => {
      if (window.localStorage && window.localStorage.getItem('SHOW_VERIFICATION')) {
        return window.localStorage.getItem('SHOW_VERIFICATION') === '1';
      }
    });
  }, []);

  const acceptVerification = () => {
    setShowVerification(false);
    window.localStorage.setItem('SHOW_VERIFICATION', '0');
  };

  const declineVerification = () => {
    router.push(link?.href || '/');
    setShowVerification(true);
    window.localStorage.setItem('SHOW_VERIFICATION', '1');
  };

  return (
    <Modal
      blur
      open={showVerification}
      onClose={declineVerification}
      shouldCloseOnOverlayClick={false}
      footer={
        <Buttons alignment="space-between">
          <Button small onClick={declineVerification} color="dark">
            {declineLabel || 'Nei'}
          </Button>
          <Button onClick={acceptVerification} color="brand">
            {acceptLabel || 'Já'}
          </Button>
        </Buttons>
      }
    >
      <ModalVerification tagline={tagline} title={title} text={text} />
    </Modal>
  );
};
