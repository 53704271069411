import React from 'react';
import classnames from 'classnames';
import { t, Trans } from '@lingui/macro';
import {
  Cell,
  Column,
  TableOptions,
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
} from 'react-table';
import { Button } from 'components/button/Button';
import { TextButton } from 'components/button/TextButton';
import { ChevronRight } from 'components/assets';

import s from './Sales.module.scss';

type Props = {
  columns?: Column<{}>[];
  data?: {}[];
  caption?: React.ReactNode;
};

export const Sales: React.FC<Props> = ({ columns, data, caption }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <div className={s.sales}>
      <table className={s.sales__table} {...getTableProps()}>
        {caption && <caption className={s.sales__caption}>{caption}</caption>}
        <thead className={s.sales__thead}>
          {headerGroups.map((headerGroup) => (
            <tr className={s.sales__headerRow} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={classnames(s.sales__header, {
                    [s.sorted]: column.isSorted,
                    [s.descending]: column.isSortedDesc,
                  })}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  {/* <span className={s.sales__indicator}>
                    <ChevronRight />
                  </span> */}
                </th>
              ))}
              <th></th>
            </tr>
          ))}
        </thead>
        <tbody className={s.sales__tbody} {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr className={s.sales__row} {...row.getRowProps()}>
                {row.cells.map((cell, ii) => {
                  return (
                    <td
                      className={classnames(s.sales__cell, { [s.name]: ii === 0 })}
                      data-label={cell.column.Header}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
