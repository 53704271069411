import React from 'react';
import { t, Trans } from '@lingui/macro';
import xor from 'lodash/xor';
import Hangul from 'hangul-js';
import { Catalog, FilterTypes } from 'components/catalog/Catalog';
import { SimpleList } from 'components/catalog/simple/SimpleList';
import { ILink } from 'utils/types';
import { Link } from 'components/link/Link';
import { TextButton } from 'components/button/TextButton';
import { Buttons } from 'components/buttons/Buttons';
import { ChevronRight } from 'components/assets';

type Props = {
  title?: React.ReactNode;
  tagline?: React.ReactNode;
  catalog: Array<{
    category?: string;
    title?: string;
    ingredients?: string;
    form?: string;
    strength?: string;
    therapeuticArea?: string;
    timeline?: string;
    scope?: string;
    tags?: string[];
    downloads: Array<{
      link?: ILink;
      label?: React.ReactNode;
    }>;
  }>;
  filters?: string[];
  lang?: string;
};

export const CatalogSimpleContainer: React.FC<Props> = ({
  title,
  tagline,
  catalog,
  filters,
  lang,
  ...rest /* slice props */
}) => {
  const [currentOption, setOption] = React.useState<FilterTypes>('All');
  const [symbols, setSymbol] = React.useState<string[]>([]);
  const [term, setSearchTerm] = React.useState<string>('');

  const onSymbolToggled = (s) => {
    // setSymbol(xor(symbols, [s]) /* Symmetric difference */);
    if (symbols[0] === s) {
      setSymbol([]);
    } else {
      setSymbol([s]);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        id: 'category',
        Header: t`Category`,
        accessor: 'category',
        hidden: catalog.filter((item) => item.category).length === 0,
        Cell: ({ row }) => {
          return row.original.category;
        },
      },
      {
        id: 'name',
        Header: t`Product name`,
        accessor: 'name',
        hidden: catalog.filter((item) => item.title).length === 0,
        Cell: ({ row }) => {
          return row.original.name;
        },
      },
      {
        id: 'ingredients',
        Header: t`Ingredients`,
        accessor: 'ingredients',
        hidden: catalog.filter((item) => item.ingredients).length === 0,
        Cell: ({ row }) => {
          return row.original.ingredients;
        },
      },
      {
        id: 'form',
        Header: t`Form`,
        accessor: 'form',
        hidden: catalog.filter((item) => item.form).length === 0,
        Cell: ({ row }) => {
          return row.original.form;
        },
      },
      {
        id: 'strength',
        Header: t`Strength`,
        accessor: 'strength',
        hidden: catalog.filter((item) => item.strength).length === 0,
        Cell: ({ row }) => {
          return row.original.strength;
        },
      },
      {
        id: 'therapeuticArea',
        Header: t`Therapeutic Area`,
        accessor: 'therapeuticArea',
        hidden: catalog.filter((item) => item.therapeuticArea).length === 0,
        Cell: ({ row }) => {
          return row.original?.therapeuticArea || null;
        },
      },
      {
        id: 'timeline',
        Header: t`timeline`,
        accessor: 'timeline',
        hidden: catalog.filter((item) => item.timeline).length === 0,
        Cell: ({ row }) => {
          return row.original?.timeline || null;
        },
      },
      {
        id: 'scope',
        Header: t`Scope`,
        accessor: 'scope',
        hidden: catalog.filter((item) => item.scope).length === 0,
        Cell: ({ row }) => {
          return row.original?.scope || null;
        },
      },
      {
        id: 'downloads',
        Header: t`Downloads`,
        accessor: 'downloads',
        hidden: catalog.filter((item) => item.downloads[0]?.link?.href).length === 0,
        Cell: ({ row }) => {
          return (
            <Buttons>
              {row.original.downloads.map((download, i) => (
                <Link key={i} {...download.link}>
                  <ChevronRight /> {download.label}
                </Link>
              ))}
            </Buttons>
          );
        },
      },
    ],
    [catalog]
  );

  // console.log(
  //   currentOption,
  //   catalog,
  //   catalog.filter(
  //     (product) => currentOption === 'All' || product.tags.some((t) => currentOption.includes(t))
  //   )
  // );

  const filteredCatalog = catalog
    .filter(
      (product) => currentOption === 'All' || product.tags.some((t) => currentOption.includes(t))
    ) // filter type
    .filter(
      // check first letter of title or ingredients and filter
      (product) =>
        symbols.length === 0 ||
        (product.title &&
          symbols.some((s) => Hangul.d(product.title[0].toLowerCase())[0].indexOf(s) > -1)) ||
        (!product.title &&
          product.ingredients &&
          symbols.some((s) => Hangul.d(product.ingredients[0].toLowerCase())[0].indexOf(s) > -1))
    )
    .filter(
      // filter by search term with support for korean phonetic library
      (product) =>
        (product.title &&
          Hangul.disassemble(product.title.toLowerCase())
            .join('')
            .indexOf(Hangul.disassemble(term.toLowerCase()).join('')) > -1) ||
        (!product.title &&
          product.ingredients &&
          Hangul.disassemble(product.ingredients.toLowerCase())
            .join('')
            .indexOf(Hangul.disassemble(term.toLowerCase()).join('')) > -1)
      //   )
      // .filter(
      //   // filter by search term
      //   (product) =>
      //     (product.title && product.title.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
      //     (product.ingredients && product.ingredients.toLowerCase().indexOf(term.toLowerCase()) > -1)
    );

  // const testTitle = catalog[8].title[0].toLowerCase();

  // console.log(
  //   testTitle,
  //   Hangul.d(testTitle),
  //   term,
  //   Hangul.d(term),
  //   symbols.some((s) => Hangul.d(testTitle)[0].indexOf(s) >= 0)
  // );

  const data = React.useMemo(
    () =>
      filteredCatalog.map((product) => ({
        category: product.category,
        name: product.title,
        ingredients: product.ingredients,
        form: product.form,
        strength: product.strength,
        downloads: product.downloads,
        therapeuticArea: product.therapeuticArea,
        timeline: product.timeline,
        scope: product.scope,
      })) || [],
    [filteredCatalog]
  );

  return (
    <Catalog
      tagline={tagline}
      title={title}
      option={currentOption}
      symbols={symbols}
      term={term}
      onOptionChange={setOption}
      onSymbolToggled={onSymbolToggled}
      onTermChanged={setSearchTerm}
      filters={filters?.length > 1 ? ['All', ...filters] : []}
      lang={lang}
      {...rest}
    >
      <SimpleList columns={columns} data={data} />
    </Catalog>
  );
};
