import React from 'react';

import { IImage, ILink } from 'utils/types';
import { Articles } from 'components/articles/Articles';
import { ArticlesItem } from 'components/articles/ArticlesItem';
import { Buttons } from 'components/buttons/Buttons';
import { IconButton } from 'components/button/IconButton';

type Props = {
  stories: Array<{
    title: string;
    location: string;
    image: IImage;
    link?: ILink;
  }>;
  hasMorePages?: boolean;
};

export const Stories = ({ stories, hasMorePages = false }: Props) => {
  const loadMore = (
    <Buttons alignment="center">
      <IconButton
        icon="plus"
        onClick={() => {
          // slice.fetchPage(slice.currentPage + 1);
        }}
      >
        Load More
      </IconButton>
    </Buttons>
  );

  return (
    <>
      <Articles footer={hasMorePages ? loadMore : undefined} isSlice flush>
        {stories?.map((story, index) => (
          <ArticlesItem
            key={index}
            link={story.link}
            title={story.title}
            tagline={story.location}
            image={story.image}
          />
        ))}
      </Articles>
    </>
  );
};
