import gql from 'graphql-tag';
import Axios from 'axios';
import queryString from 'query-string';

import { ILink } from 'utils/types';
import { IBaseSlice, SliceTypes } from '.';
import { fetchArticles, IAllArticles, parseArticles } from '../articles';
import { RichTextBlock } from 'prismic-reactjs';
import { PrismicLink } from '../utils/PrismicTypes';
import { parseDateTime, parseLink, parseRichText, parseString } from '../utils/parsers';

export interface ILatestArticles extends IBaseSlice {
  title: string;
  tagline?: string;
  link?: ILink;
  text?: RichTextBlock[];
  buttonText?: string;
  tags: string[];
  matchAll?: boolean;
  items: IAllArticles;
  simple?: boolean;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_tagline
        slice_text
        slice_buttontext
        slice_link {
          ...Link
        }
        slice_simple
        slice_tags
        slice_logic
      }
    }
  }
`;

type NestedProps = {
  lang: string;
  limit?: number;
  previewData: boolean;
  redirects: Array<{ from: string; to: string }>;
  tags?: string[];
  tagsIn?: string[];
};

export const fetchArticlesAndParse = async ({
  lang,
  limit,
  previewData,
  redirects = [],
  tags,
  tagsIn,
}: NestedProps) => {
  if (process.env.NEWSROOM_DATA_STREAM_URL && process.env.NEWSROOM_DATA_STREAM_URL !== '') {
    try {
      const url = queryString.stringifyUrl({
        url: process.env.NEWSROOM_DATA_STREAM_URL,
        query: {
          size: 3,
        },
      });
      const res = await Axios.get(url);

      return {
        total: res.data.meta?.count,

        items: res.data.data.map((item) => ({
          uid: item.id,
          date: item.releaseDate?.dateUTC && parseDateTime(item.releaseDate.dateUTC),
          title: item.title,
          tagline: 'Investors',
          // tagline: item.type?.title,
          link: { href: item.link?.hostedUrl },
          extract: item.teaser,
        })),
      };
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  const items = await fetchArticles(lang, undefined, previewData, limit, tags, tagsIn);

  return parseArticles(items, redirects, lang);
};

type Props = {
  primary?: {
    slice_title: RichTextBlock[];
    slice_tagline: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_buttontext: RichTextBlock[];
    slice_link: PrismicLink;
    slice_simple: boolean;
    slice_tags: string;
    slice_logic: boolean;
  };
};

export const parse = (slice: Props, redirects): ILatestArticles => ({
  type: SliceTypes.Articles,
  tagline: parseString(slice.primary?.slice_tagline),
  title: parseString(slice.primary?.slice_title),
  text: parseRichText(slice.primary?.slice_text, redirects),
  buttonText: parseString(slice.primary?.slice_buttontext, redirects),
  link: parseLink(slice.primary?.slice_link, redirects),
  simple: slice.primary?.slice_simple || false,
  tags: (parseString(slice.primary?.slice_tags) || '')
    .split(',')
    .map((str) => str.trim())
    .filter((x) => !!x),
  matchAll: slice.primary?.slice_logic || false,
  items: { total: 0, items: [] },
});
