import React from 'react';
import Image from 'next/image';
import { t, Trans } from '@lingui/macro';
import xor from 'lodash/xor';
import { Catalog, FilterTypes } from 'components/catalog/Catalog';
import { TableList } from 'components/catalog/list/TableList';
import { Verification } from 'containers/verification/Verification';
import { IImage, ILink } from 'utils/types';
import { Link } from 'components/link/Link';
import { RichText } from 'components/rich-text/RichText';
import { RichTextBlock } from 'prismic-reactjs';
import { IndexGrid } from 'components/index-grid/IndexGrid';

type Props = {
  title?: React.ReactNode;
  tagline?: React.ReactNode;
  catalog: Array<{
    title: string;
    intro: string;
    images: {
      image: IImage;
    }[];
    detailsLink?: ILink;
    tags?: string[];
  }>;
  options: string[];
  showAll: string;
};

export const CatalogBasicContainer: React.FC<Props> = ({
  title,
  tagline,
  catalog,
  options,
  showAll,
  ...rest /* slice props */
}) => {
  const [currentOption, setOption] = React.useState<FilterTypes>(showAll || 'Show all');
  const [symbols, setSymbol] = React.useState<string[]>([]);
  const [term, setSearchTerm] = React.useState<string>('');

  const allOptions = showAll || 'Show all';

  const onSymbolToggled = (s) => {
    setSymbol(xor(symbols, [s]) /* Symmetric difference */);
  };

  const sortedCatalog = React.useMemo(() => {
    // sort catalog alphabetically
    return catalog.sort((a, b) => a.title.localeCompare(b.title));
  }, [catalog]);

  const filteredCatalog = sortedCatalog
    .filter(
      (product) =>
        currentOption === (showAll || 'Show all') ||
        product.tags.some((t) => currentOption.includes(t))
    ) // filter type
    .filter(
      // check first letter of title and filter
      (product) =>
        symbols.length === 0 ||
        (product.title && symbols.indexOf(product.title.toLowerCase()[0]) > -1)
    )
    .filter(
      // filter by search term
      (product) => product.title && product.title.toLowerCase().indexOf(term.toLowerCase()) > -1
    );

  return (
    <Catalog
      tagline={tagline}
      title={title}
      option={currentOption}
      symbols={symbols}
      term={term}
      onOptionChange={setOption}
      onSymbolToggled={onSymbolToggled}
      onTermChanged={setSearchTerm}
      filters={options?.length > 1 ? [allOptions, ...options] : []}
      {...rest}
    >
      <IndexGrid noContainer>
        {filteredCatalog.map((product, i) => (
          <IndexGrid.Item
            key={product.detailsLink.href}
            title={product.title}
            text={product.intro}
            image={product.images[0].image}
            link={product.detailsLink}
          />
        ))}
      </IndexGrid>
    </Catalog>
  );
};
