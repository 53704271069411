import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '.';
import { parseRichText, parseString } from '../utils/parsers';
import { fetch } from 'providers/wufoo/fields';

export enum FormTypes {
  'Alvogen - Application Form' = 'mcjtjv40wzpjgb',
  'Alvogen - Contact Us' = 'q1hi0i2o0cmyb2q',
  'Alvogen - Become a supplier' = 'r376o10gwl9rz',
  'Alvogen - Speak up' = 'z1bpj28d1ngj0qv',

  'Alvogen.is - Hafa Samband' = 'z1e57uto10clxt8',

  'Alvotech - Contact Us' = 'w184daa41gr1pf9',
  'Alvotech - CPhI 2019 - Meet our Management' = 'xi7dhpa12i73nc',

  'Lotus - Contact Us' = 'z1b1wn5h0ey98tw',
  'Lotus - Contact Us (zh)' = 'z18qughp0das43m',

  'Adalvo - Contact Us' = 'zzbj1w71c01pvu',

  'Almatica - Contact Us' = 'z13oo5eg11l19ca',
}

export interface IForm extends IBaseSlice {
  title: string;
  text: RichTextBlock[];
  formId: string;
  messageTitle: string;
  message: RichTextBlock[];
  items?: any;
  ageCheck: boolean;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_description
        slice_form_id
        slice_form_message_title
        slice_form_message
        slice_form_agecheck
      }
    }
  }
`;

export const customSplitPageFragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_description
        slice_form_id
        # slice_form_message_title
        slice_form_message
        slice_form_agecheck
      }
    }
  }
`;

export const fetchForm = async (slice) => {
  try {
    const id = FormTypes[slice.formId] || slice.formId;
    const res = await fetch(id);

    return {
      id,
      ...res,
    };
  } catch (err) {
    // console.log(err);
    return null;
  }
};

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_description: RichTextBlock[];
    slice_form_id: string | RichTextBlock[];
    slice_form_message_title: RichTextBlock[];
    slice_form_message: RichTextBlock[];
    slice_form_agecheck: boolean;
  };
};

export const parse = (slice: Props, redirects): IForm => ({
  type: SliceTypes.Form,
  title: parseString(slice.primary.slice_title),
  text: parseRichText(slice.primary.slice_description, redirects),
  formId: parseString(slice.primary.slice_form_id),
  messageTitle: parseString(slice.primary.slice_form_message_title, true),
  message: parseRichText(slice.primary.slice_form_message, redirects),
  ageCheck: slice.primary.slice_form_agecheck ?? true,
});
