import { RichTextBlock } from 'prismic-reactjs';
import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '.';
import { parseRichText, parseString } from '../utils/parsers';

export interface ITextBlock extends IBaseSlice {
  title: string;
  text: RichTextBlock[];
  color: string;
  aside: RichTextBlock[];
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_text
        slice_box
        slice_aside
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_box: string;
    slice_aside: RichTextBlock[];
  };
};

export const parse = (slice: Props, redirects): ITextBlock => ({
  type: SliceTypes.TextBlock,
  title: parseString(slice.primary.slice_title),
  text: parseRichText(slice.primary.slice_text, redirects),
  color: parseString(slice.primary.slice_box),
  aside: parseRichText(slice.primary.slice_aside, redirects),
});
