import React from 'react';
import Image from 'next/image';
import classnames from 'classnames';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { IImage, ILink } from 'utils/types';
import { Text } from 'components/text/Text';
import { Link } from 'components/link/Link';
import { ChevronRight } from 'components/assets';
import { BackgroundSection } from 'components/background/BackgroundSection';

import s from './Portal.module.scss';

type Props = {
  children: React.ReactNode;
  tagline?: string;
  title?: React.ReactNode;
  text?: React.ReactNode;
  image?: IImage;
  isSlice?: boolean;
};

type ItemProps = {
  image?: IImage;
  title?: string;
  text?: React.ReactNode;
  link?: ILink;
  linkLabel?: string;
};

interface IPortal extends React.FC<Props> {
  Item: typeof PortalItem;
}

const Portal: IPortal = function Card({ children, tagline, title, text, image, isSlice }) {
  const content = <div className={s.portal__list}>{children}</div>;

  const container = (
    <section className={s.portal__segment}>
      <div className={s.portal__container}>
        <Text tagline={tagline} title={title} text={text} />
        <div className={s.portal__content}>{content}</div>
      </div>
    </section>
  );

  return (
    <BackgroundSection color="white">
      <div className={classnames(s.portal, !!image && s.hasImage)}>
        {isSlice ? container : content}
      </div>
    </BackgroundSection>
  );
};

export const PortalItem = ({ image, title, text, link, linkLabel }: ItemProps) => {
  const { i18n } = useLingui();

  const content = (
    <>
      {image && (
        <div className={s.item__image}>
          <img
            src={image.desktop?.url || image.url}
            // layout="fill"
            // objectFit="cover"
            width={image.width}
            height={image.height}
            alt={image.alt || ''}
            sizes="(min-width: 960px) 640px, 100vw"
          />
        </div>
      )}
      <div className={s.item__content}>
        {title && <div className={s.item__title}>{title}</div>}
        {text && <div className={s.item__text}>{text}</div>}
      </div>
    </>
  );

  if (!link?.href) {
    return <div className={s.item}>{content}</div>;
  }

  return (
    <Link href={link.href} className={s.item}>
      {content}
      <div className={s.button}>
        <ChevronRight />
        <span className={s.button__text}>{linkLabel || i18n._(t`Learn more`)}</span>
      </div>
    </Link>
  );
};

Portal.Item = PortalItem;

export { Portal };
