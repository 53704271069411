import React from 'react';
import classnames from 'classnames';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { IImage, ILink } from 'utils/types';
import { Button } from 'components/button/Button';
import { Tagline } from 'components/tagline/Tagline';
import { Copy } from 'components/typography/Copy';
import { BackgroundSection } from 'components/background/BackgroundSection';

import s from './Hero.module.scss';
import { SliceHero } from 'components/hero/SliceHero';
import { TextButton } from 'components/button/TextButton';

type Props = {
  children?: React.ReactNode;
  tagline?: string;
  title?: React.ReactNode;
  text?: React.ReactNode;
  image?: IImage;
  caption?: React.ReactNode;
  infoLinks?: React.ReactNode;
  dataLinks?: React.ReactNode;
  isSlice?: boolean;
};

type ItemProps = {
  link?: ILink;
  label?: string;
  button?: boolean;
};

interface IHero extends React.FC<Props> {
  Item: typeof HeroButton;
}

const Hero: IHero = function Card({
  children,
  tagline,
  title,
  image,
  caption,
  text,
  infoLinks,
  dataLinks,
  isSlice,
}) {
  return (
    <BackgroundSection>
      <div className={classnames(s.hero)}>
        <div className={s.hero__container}>
          <div className={s.hero__layout}>
            <div className={s.hero__main}>
              {title && (
                <div className={s.accordion__header}>
                  <SliceHero
                    title={title}
                    tagline={tagline}
                    underline={false}
                    image={image}
                    caption={caption}
                    color="white"
                    alt
                  />
                </div>
              )}
            </div>
            <div className={s.hero__aside}>
              {text && (
                <div className={s.hero__text}>
                  <Copy>{text}</Copy>
                </div>
              )}
              {dataLinks && (
                <div className={s.hero__data}>
                  <Tagline text="Downloads" />
                  <div className={s.hero__buttons}>{dataLinks}</div>
                </div>
              )}
            </div>

            {infoLinks && (
              <div className={s.hero__links}>
                <Tagline text="More Info" />
                <div className={s.hero__buttons}>{infoLinks}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </BackgroundSection>
  );
};

export const HeroButton = ({ link, label, button }: ItemProps) => {
  const { i18n } = useLingui();

  if (!link) {
    return null;
  }

  if (!!button) {
    return <Button link={link}>{label || i18n._(t`Learn more`)}</Button>;
  }

  return (
    <TextButton href={link.href} size={link.size}>
      {label || i18n._(t`Learn more`)}
    </TextButton>
  );
};

Hero.Item = HeroButton;

export { Hero };
