import ReactDatePicker from 'react-datepicker';

import s from './DatePicker.module.scss';

type Props = {
  id: string;
  label: string;
  selected: Date;
  startDate: Date;
  endDate: Date;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  isClearable?: boolean;
  onChange: (date: Date) => void;
};

export const DatePicker = ({
  id,
  label,
  selected,
  startDate,
  endDate,
  minDate,
  maxDate,
  dateFormat = 'yyyy-MM-dd',
  selectsStart = false,
  selectsEnd = false,
  isClearable = true,
  onChange,
}: Props) => {
  return (
    <div className={s.datePicker}>
      <label className={s.datePicker__label} htmlFor={id}>
        {label}
      </label>
      <ReactDatePicker
        id={id}
        selected={selected}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={dateFormat}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        isClearable={isClearable}
        onChange={onChange}
      />
    </div>
  );
};
