import React from 'react';
import classnames from 'classnames';
import { TweenMax } from 'gsap';
import { IImage } from 'utils/types';
import { SliceHero } from 'components/hero/SliceHero';
import {
  EsgPine,
  EsgBulb,
  EsgDrop,
  EsgBin,
  EsgPeople,
  EsgHeart,
  EsgRibbon,
  EsgExclamation,
  EsgLock,
  EsgMicroscope,
  EsgList,
  ChevronRight,
} from 'components/assets';

import s from './Accordion.module.scss';

type Props = {
  children: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  image?: IImage;
  caption?: React.ReactNode;
  isSlice?: boolean;
};

type ItemProps = {
  id?: number;
  title?: React.ReactNode;
  text?: React.ReactNode;
  icon?: string;
  openItem?: number;
  currentItem?: (e: any) => void;
};

interface IAccordion extends React.FC<Props> {
  Item: typeof AccordionItem;
}

const Accordion: IAccordion = function Card({ children, title, text, image, caption, isSlice }) {
  const [currentItem, setCurrentItem] = React.useState<number | null>(null);

  const content = (
    <dl className={s.accordion__list}>
      {React.Children.map(children, (component: JSX.Element, i) =>
        React.cloneElement(component, {
          openItem: currentItem,
          currentItem: (n: number) => setCurrentItem(n),
        })
      )}
    </dl>
  );

  const container = (
    <div className={s.accordion__segment}>
      <div className={s.accordion__container}>
        {title && (
          <div className={s.accordion__header}>
            <SliceHero
              title={title}
              // tagline={tagline}
              underline={true}
              image={image}
              caption={caption}
              color="white"
              alt
            />
          </div>
        )}
        <div className={s.accordion__row}>
          <div className={s.accordion__col}>
            {text && <div className={s.accordion__text}>{text}</div>}
          </div>
        </div>
        {content}
      </div>
    </div>
  );

  return (
    <div className={classnames(s.accordion, !!image && s.hasImage)}>
      {isSlice ? container : content}
    </div>
  );
};

export const AccordionItem = ({ id, title, text, icon, openItem, currentItem }: ItemProps) => {
  const bodyRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (openItem === id) {
      TweenMax.set(bodyRef.current, { height: 'auto' });
      TweenMax.from(bodyRef.current, 0.3, { height: 0 });
    } else {
      TweenMax.to(bodyRef.current, 0.3, { height: 0 });
    }
  }, [openItem]);

  const setIcon = (key) => {
    switch (key) {
      case 'Pine':
        return <EsgPine />;
      case 'Bulb':
        return <EsgBulb />;
      case 'Drop':
        return <EsgDrop />;
      case 'Bin':
        return <EsgBin />;
      case 'People':
        return <EsgPeople />;
      case 'Heart':
        return <EsgHeart />;
      case 'Ribbon':
        return <EsgRibbon />;
      case 'Exclamation':
        return <EsgExclamation />;
      case 'Lock':
        return <EsgLock />;
      case 'Microscope':
        return <EsgMicroscope />;
      case 'List':
        return <EsgList />;
      default:
        break;
    }
  };

  return (
    <div className={classnames(s.item, openItem === id && s.open)}>
      <button className={s.item__head} onClick={() => currentItem(openItem === id ? null : id)}>
        <dt className={s.item__title}>
          <span className={s.item__icon}>{setIcon(icon)}</span>
          {title}
        </dt>
        <span className={s.item__arrow}>
          <ChevronRight />
        </span>
      </button>
      <div ref={bodyRef} className={s.item__body}>
        <dd className={s.item__copy}>{text}</dd>
      </div>
    </div>
  );
};

Accordion.Item = AccordionItem;

export { Accordion };
