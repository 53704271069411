import gql from 'graphql-tag';

import { IBaseSlice, SliceTypes } from '.';

export interface IDivider extends IBaseSlice {
  style: string;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_style
      }
    }
  }
`;

type Props = {
  primary: {
    slice_style: string;
  };
};

export const parse = (slice: Props): IDivider => ({
  type: SliceTypes.Divider,
  style: slice.primary.slice_style,
});
