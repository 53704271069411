import React from 'react';
import { GenericForm } from './GenericForm';
import { Form } from 'components/form/Form';

type Props = {
  title: string;
  tagline?: string;
  text: React.ReactNode;
  messageTitle: string;
  message: React.ReactNode;
  formId?: string;
  isSlice?: boolean;
  isSplitPage?: boolean;
  ageCheck?: boolean;

  formData?: any;
};

export const FormContainer = ({
  title,
  tagline,
  text,
  messageTitle,
  message,
  formId,
  ageCheck,
  ...rest
}: Props) => {
  if (!formId) {
    return null;
  }

  const form = rest?.formData;

  if (!form) {
    // loading
    return null;
  }

  return (
    <Form tagline={tagline} title={title} text={text} {...rest}>
      <GenericForm
        form={form}
        messageTitle={messageTitle}
        message={message}
        // remove age check on Alvogen.is contact form
        ageLimit={formId === 'Alvogen.is - Hafa Samband' || !ageCheck ? false : true}
      />
    </Form>
  );
};
