import gql from 'graphql-tag';
import { IBaseSlice, SliceTypes } from '.';
import { RichTextBlock } from 'prismic-reactjs';
import { parseImage, parseRichText, parseString } from '../utils/parsers';
import { PrismicImage } from '../utils/PrismicTypes';
import { IImage, ILink } from 'utils/types';

export interface IAccordion extends IBaseSlice {
  sliceTitle: RichTextBlock[];
  text: RichTextBlock[];
  image?: IImage;
  caption?: RichTextBlock[];
  items: Array<{
    title: RichTextBlock[];
    text: RichTextBlock[];
    icon: string;
  }>;
}

export const fragment = (name: string, type: string, fragmentName: string) => gql`
  fragment ${fragmentName || name} on ${type} {
    ... on ${type}${name} {
      type
      primary {
        slice_title
        slice_text
        slice_image
        slice_caption
      }
      fields {
        item_title
        item_text
        item_icon
      }
    }
  }
`;

type Props = {
  primary: {
    slice_title: RichTextBlock[];
    slice_text: RichTextBlock[];
    slice_image?: PrismicImage;
    slice_caption: RichTextBlock[];
  };
  fields: Array<{
    item_title: RichTextBlock[];
    item_text: RichTextBlock[];
    item_icon: string;
  }>;
};

export const parse = (slice: Props, redirects): IAccordion => ({
  type: SliceTypes.Accordion,
  sliceTitle: parseRichText(slice.primary.slice_title),
  text: parseRichText(slice.primary.slice_text),
  image: parseImage(slice.primary.slice_image),
  caption: parseRichText(slice.primary.slice_caption),
  items: slice.fields.map((field) => ({
    title: parseRichText(field.item_title, redirects),
    text: parseRichText(field.item_text, redirects),
    icon: field.item_icon || '',
  })),
});
